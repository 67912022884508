import {IconButton} from "@mui/material";
import {DeleteForever} from "@mui/icons-material";

const DeleteIconButton = ({onDelete}) => {
    return (
        <IconButton onClick={onDelete} size={"small"} color="error">
            <DeleteForever/>
        </IconButton>
    )
}
export default DeleteIconButton;