import Dialog from "@mui/material/Dialog";
import {Box, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import DialogContentComponent from "../../../common/components/Shared/DialogContentComponent";
import useApi from "../../../api/useApi";
import {utcToString} from "../../../common/formatters/format"
const LogOrdenCompra = ({open, id, onClose, procesoId}) => {
    const {t} = useTranslation();
    const api = useApi('catalogos/log');
    const [data, setData] = useState([]);

    useEffect(() => {
        if(open){
            load({id:id, procesoId: procesoId}).then()}
        // eslint-disable-next-line
    }, [id, open, procesoId]);

    const load = async (filter) => {
        const response = await api.filter(filter)
        setData(response);
    }

    return(
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'md'}>
            <DialogTitle marginInline={4} marginTop={2} fontWeight={'bold'}>
                Log
                <IconButton sx={{position: 'absolute', right: 8, top: 8}} onClick={onClose}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{marginInline: 4}}>
                <DialogContentComponent>
                    <Box>
                        {data?.length > 0 ?
                            data?.map((log, index) =>
                                <Box display={'flex'} gap={'10px'} id={index} key={index}>
                                    <label ><b>{log.actividad}:</b> &nbsp;
                                        {utcToString(log.fecha,'DD/MM/YYYY HH:mm') } por {log.usuario}
                                    </label>
                                    <label style={{color: 'darkgray'}}>
                                        {log.folioFactura !=="" &&
                                            <>
                                                <b> Factura:</b> {log.folioFactura}
                                            </>
                                        }
                                    </label>
                                    <label style={{color: 'darkgray'}}>
                                        {
                                            log.comentarios !=="" &&
                                            <>
                                                <b> Comentarios: </b> {log.comentarios}>
                                            </>
                                        }
                                    </label>

                                </Box>
                            )
                            :
                            <Box textAlign={'center'} color={'darkgray'}>
                                {t('No existen movimientos.')}
                            </Box>
                        }
                    </Box>
                </DialogContentComponent>
            </DialogContent>
        </Dialog>
    )
}

export default LogOrdenCompra;
