import dayjs from "dayjs";

export const getYesterday =()=>{
    const startOfDay = dayjs().startOf('day');
    return  startOfDay.subtract(1, 'day')
}

export const getToday =()=>{
    return dayjs().startOf('day');
}


export const getTodayIsoString =()=>{
    return dayjs().startOf('day').format('YYYY-MM-DD');
}
export const addDaysIsoString=(days)=>{
    return dayjs().startOf('day').add(days,'day').format('YYYY-MM-DD');
}