import PropTypes from "prop-types";
import ExGrid from "./ExGrid";
import {useContext} from "react";
import {FormContext} from "../../FormContext";

/**
 * Wrapper de Ex grid, para poderlo utilizar como elemento hijo de forms, ya que necesitamos pasalarle a ex-grid los elementos de formcontext
 */
const FieldExGrid = ({field,
                      disabled,
                      visibleHeader,
                      columns,
                      onNewRow,
                      getEntityId,
                      children
                  }) => {

    const {value, parentValue, onChange, submitted, onValidate} = useContext(FormContext);

    return (<ExGrid value={value[field]}
        field={field}
        visibleHeader={visibleHeader}
        parentValue={parentValue}
        columns={columns}
        getEntityId={getEntityId}
        onValidate={onValidate}
        submitted={submitted}
        children={children}
        onChange={onChange}
        disabled={disabled}
        onNewRow={onNewRow}
    />)
}

FieldExGrid.propTypes = {
    field: PropTypes.string.isRequired,
    disabled: PropTypes.any,
    visibleHeader: PropTypes.bool,
    columns: PropTypes.array,
    onNewRow: PropTypes.func,
    getEntityId: PropTypes.func
}

export default FieldExGrid;