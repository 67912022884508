import {Box} from "@mui/material";
import useFieldsContainer from "../../hooks/useFieldsContainer";
import {useCallback, useContext} from "react";
import {FormContext} from "../FormContext";
import FormFields from "../FormFields";

const FormBox = ({children, fields, ...props}) => {

    console.log(props)

    const {value, parentValue, onChange, submitted, onValidate} = useContext(FormContext);

    const {onChangeField, propagateValidation} = useFieldsContainer(value, onChange, onValidate)

    //todo temporal por debug, quitarlo una vez que esté mas estable
    const handleChange = useCallback((newValue, field) => {
        onChangeField(newValue, field)
    }, [onChangeField])

    return (
        <Box {...props}>
            <FormFields
                value={value}
                onChange={handleChange}
                onValidate={propagateValidation}
                submitted={submitted}
                parentValue={parentValue}
                fields={fields}
                children={children}
            />
        </Box>
    )
}
export default FormBox;