import Page from "../../common/layout/Page";
import {useEffect, useState} from "react";
import ContenedorContainer from "./ContenedorContainer";
import Contador from "./Contador";
import {shortCurrencyFormat} from "../../common/formatters/format";
import OrdenesCompra from "./ordenesCompra/OrdenesCompra";
import ProcesoFacturacion from "./procesoFacturacion/ProcesoFacturacion";
import ProcesoPago from "./procesoPago/ProcesoPago";
import useApi from "../../api/useApi";
import useSessionState from "../../common/hooks/useSessionState";
import {defaultFilterOrdenesCompra, defaultFilterProcesoFacturacion, defaultFilterProcesoPagos} from "../Constantes";
import {useAuth} from "../../common/security/AuthProvider";
import useNotification from "../../common/components/hooks/useNotification";
import ComunicadoUsuario from "../catalogos/comunicados/ComunicadoUsuario";

const GestionDocumentos = () => {
    const [mainTab, setMainTab] = useSessionState('tabGestionDocumentos',{id:0});
    const [comunicadoDialog,setComunicadoDialog]=useState(false);
    const [data,setData]=useState([]);
    const [filtroOrdenCompra] = useSessionState('filtroOrdenesCompra',
        {fechaRegistro:defaultFilterOrdenesCompra.fechaRegistro}
    )
    const [filtroProcesoFacturacion] = useSessionState('filtroProcesoFacturacion',
        {fechaProgramadaPago:defaultFilterProcesoFacturacion.fechaProgramadaPago}
    )
    const [filtroProcesoPagos] = useSessionState('filtroProcesoPagos',
        {FechaRealPago: defaultFilterProcesoPagos.FechaRealPago}
    )
    const api = useApi('configuracion/ordenesCompra');
    const apiComunicado = useApi('catalogos/comunicado');
    const [segmentos, setSegmentos] = useState([
        {id:0, texto:'Órdenes de Compra', numero:0},
        {id:1,texto:'Proceso Facturación', numero:0},
        {id:2,texto:'Proceso de Pago', numero:0}
    ])
    const notification = useNotification(); 
    const auth = useAuth();

    useEffect(() => {
            load();
        // eslint-disable-next-line
    }, []);

    const load = async () => {
        if(auth?.user?.esBloqueado){
            notification.alterWarning('El usuario esta bloquedo, algunas funcionalidades del sistema permaneceran deshabilitadas. Favor de Cargar Complementos Atrasados o contactar con Administrador',
                {'title' : 'Usuario Bloqueado',
                'confirmText':'Ok'});
        }
        const nS = segmentos;
        const response = await api.post('obtenerTotales', {
            // 'rangoFecha1':defaultFilterOrdenesCompra.fechaRegistro,
             filtroOrdenCompra: filtroOrdenCompra,
            filtroProcesoFacturacion: filtroProcesoFacturacion,
            filtroProcesoPagos: filtroProcesoPagos
        },{showSuccess:false});
        nS[0].numero = response.totalOrdenesCompra;
        nS[1].numero = response.totalRecepciones;
        nS[2].numero = response.totalFacturas;
        setSegmentos([...nS]);

        const responseComunicado = await apiComunicado.get('usuariocomunicado', {UserId: auth?.user?.id, EmpresaId:  auth?.user?.empresaId})
        if(responseComunicado!==undefined)
            if(responseComunicado[0]!==undefined)
            {
                if(!responseComunicado[0].esComunicadoActivo)
                {
                    setData(responseComunicado[0]);
                    setComunicadoDialog(true);
                }
                else
                    setComunicadoDialog(false);
            }

    }

    const onClose=()=>{
        setComunicadoDialog(false);
    }

    const setNuevoTotal = (segmento, total) =>{
        const nuevoSegmentos = segmentos;
        const index = nuevoSegmentos.findIndex(i => i.id === segmento);
        nuevoSegmentos[index].numero = total;
        setSegmentos([...nuevoSegmentos]);
    }


    const onChangeSegmento = (id) => async () => {
        setMainTab({id})
    }

    const mainTabs = {
        ordenesCompra: 0,
        procesoFacturacion: 1,
        procesoPago: 2
    }

    return(
        <Page>
            <ContenedorContainer>
                {segmentos.map(e =>
                    <Contador
                        key={e.id}
                        total={shortCurrencyFormat(e.numero)}
                        titulo={e.texto}
                        selected={e.id === mainTab.id}
                        onSelect={onChangeSegmento(e.id)}
                    />
                )}
            </ContenedorContainer>
            {mainTab.id === mainTabs.ordenesCompra && <OrdenesCompra nuevoTotal={setNuevoTotal}/>}
            {mainTab.id === mainTabs.procesoFacturacion && <ProcesoFacturacion nuevoTotal={setNuevoTotal}/>}
            {mainTab.id === mainTabs.procesoPago && <ProcesoPago nuevoTotal={setNuevoTotal}/>}
            <ComunicadoUsuario  onClose={onClose} openComunicado={comunicadoDialog} data={data} ></ComunicadoUsuario>
        </Page>
    )
}

export default GestionDocumentos;
