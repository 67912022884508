import DialogTitle from "@mui/material/DialogTitle";
import {Box, Button,  IconButton,} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentComponent from "../../../common/components/Shared/DialogContentComponent";
import Generales from "../../../common/components/Shared/Generales";
import useApi from "../../../api/useApi";
import useNotification from "../../../common/components/hooks/useNotification";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const ProveedorBloqueo = ({open, onClose,dataProveedor}) => {
    const {t} = useTranslation();
    const notification = useNotification();
    const api = useApi('catalogos/proveedores');

    const cerrar = () => {
        onClose();
    }

    const aceptar = async () => {
        await api.post('bloquear',{'proveedorIds': dataProveedor});
        notification.info(t('Proveedores bloqueados de manera éxitosa.'));
        onClose();
    }

    return(
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}>
                <DialogTitle marginInline={4} marginTop={2} fontWeight={'bold'}>
                    <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                        <Box sx={{gridRow: "1", gridColumn: "1/9"}}>
                            { t(`¿Seguro que deseas bloquear?`)}
                        </Box>
                    </Box>

                    <IconButton sx={{position: 'absolute', right: 8, top: 8}} onClick={() => cerrar()}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{marginInline: 4}}>
                    <DialogContentComponent>
                        <Generales >
                            { t(`Al bloquear a un proveedor. Ya no pordrá cargar facturas. Favor de confirmar.`)}
                        </Generales>
                    </DialogContentComponent>
                    <DialogActions>
                        <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                            <Box textAlign={'right'} color={'black'} fontWeight={'bold'}
                                 sx={{gridRow: "1", gridColumn: "7/8"}}>
                                <Button  variant="outlined" color={'inherit'} onClick={() => cerrar()}>
                                    {t('Cancelar')}
                                </Button>
                            </Box>
                            <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "8/9"}} >
                                <Button  variant="outlined" color={'success'} startIcon={<CheckIcon/>} onClick={() => aceptar()}
                                >
                                    {t('Confirmo.')}
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ProveedorBloqueo;