import FormEditDialog, {
    withEditDialogContext
} from "../../../common/components/forms/formEditDialog/FormEditDialog";
import Field from "../../../common/components/forms/Field";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import useApi from "../../../api/useApi";
import FieldCheckBox from "../../../common/components/forms/fields/FieldCheckBox";

const FaqDetalle = ({id,onCancel,onSaved}) => {

    const api = useApi('catalogos/faq')

    return (
        <FormEditDialog api={api} title={'FAQ'}  hideDelete={true} id={id} onCancel={onCancel} onSaved={onSaved} >
            <Field field="pregunta" label={'Pregunta'} render={FieldTextBox} required={true} />
            <Field field="respuesta" label={'Respuesta'} render={FieldTextBox} required={true}   />
            <Field field="activo" label={'Activo'} render={FieldCheckBox}/>
        </FormEditDialog>
    )
}
export default withEditDialogContext(FaqDetalle)