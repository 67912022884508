import dayjs from "dayjs";
import * as numeral from "numeral";

/**formatea una fecha a un format en específico si no utiliza el formato estandar por default
 * @param fecha
 * @param format formato en la que se mostrará la fecha referenia: https://day.js.org/docs/en/display/format
 * @returns {string|string} la fecha formateada
 */
export const dateFormat = (fecha, format) =>{
    return fecha != null ? dayjs(fecha).format(  format??'L') : ''
}
export const utcToString=(date, format)=> {
    const fecha = dayjs.utc(date).local();
    return fecha.format(format ?? 'DD-MMM-YYYY HH:mm');
}
export const numericFormat = (value, precision) => {
    precision = precision ?? 2
    const format = precision === 0 ? '' : ('.' + ''.padStart(precision))
    return value == null ? '' : numeral(value).format(`0,0${format}`)
}

export const currencyFormat = (value, precision, currencySymbol) => {
    return `${currencySymbol??'$'} ${numericFormat(value,precision)}`
}

export const shortNumericFormat = (value, precision) => {
    precision = precision ?? 0
    const format = precision === 0 ? '' : ('.' + ''.padStart(precision))
    return value == null ? '' : numeral(value).format(`0,0${format}`)
}

export const shortCurrencyFormat = (value, precision, currencySymbol) => {
    return `${currencySymbol??'$'} ${shortNumericFormat(value,precision)}`
}
