import FormEditDialog, {

    withEditDialogContext
} from "../../../common/components/forms/formEditDialog/FormEditDialog";
import Field from "../../../common/components/forms/Field";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import useApi from "../../../api/useApi";
import FieldDropDown from "../../../common/components/forms/fields/FieldDropDown";
import {emailValidator} from "../../../common/components/forms/validations/validators";
import FieldCheckBox from "../../../common/components/forms/fields/FieldCheckBox";
import {estatusProveedor} from "../../Constantes";

const ProveedorDetalle = ({editForm, disabled}) => {
    const api = useApi('catalogos/proveedores')

    return (
        <FormEditDialog api={api} title={'Proveedor'} {...editForm} disabled={disabled} hideDelete={true} >
            <Field field="externalSystemId" label={'ID Proveedor'} render={FieldTextBox} disabled={true} />
            <Field field="razonSocial" label={'Proveedor'} render={FieldTextBox}  disabled={true}/>
            <Field field="rfc" label={'RFC'} render={FieldTextBox} maxLength={13} disabled={true}  />
            <Field field="email" label={'Email'} render={FieldTextBox} maxLength={255}  componentProps={{validators:[emailValidator]}} required={true}  />
            <Field field="bancoId" label={'Banco'} render={FieldDropDown} required={true} source={'bancos'}/>
            <Field field="cuentaTransferencia" label={'Cuenta transferencia'}  required={true} render={FieldTextBox} maxLength={23} />
            <Field field="claveId" label={'Clave ID'} render={FieldTextBox} required={true} />
            <Field field="activo" label={'Activo'} render={FieldCheckBox} visible={(parentValue)=> parentValue.estatus !== estatusProveedor.EnProceso}/>
        </FormEditDialog>
    )
}
export default withEditDialogContext(ProveedorDetalle)
