import React from 'react'
export default function usePersistedState (key, defaultValue) {
    const [state, setState] = React.useState(() => {
        const persistedState = localStorage.getItem(key)
        if (typeof defaultValue === 'object') {
            return { ...defaultValue, ...(persistedState ? JSON.parse(persistedState) : {}) }
        }
        return persistedState ?? defaultValue
    })
    React.useEffect(() => {

        if(typeof state === 'object' || !Array.isArray(state))
            window.localStorage.setItem(key, JSON.stringify(state))
        else
            window.localStorage.setItem(key, state)

    }, [state, key])
    return [state, setState]
}
