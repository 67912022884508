
import {
    estatusErrorLayoutRender,
    estatusInfoLayoutRender
} from "../../Constantes";
import {enumFormatter} from "../../../common/formatters/useLocaleFormatters";
import PropTypes from "prop-types";
import EstatusContenedor from "../../../common/components/Shared/EstatusContenedor";

const EstatusLayoutRender = ({ data, enumerador, tipo}) => {

        if(estatusInfoLayoutRender.includes(data.estatusId))
        {
            tipo = 'info'
        }else if(estatusErrorLayoutRender.includes(data.estatusId)){
            tipo = 'alert'
        }
        else{
            tipo = ''
        }

    return (
        <EstatusContenedor>
                <label className={tipo}>
                    {enumFormatter({'value':data.estatusId, 'colDef':{'enum':enumerador}})}
                </label>
        </EstatusContenedor>
    )
}

EstatusLayoutRender.propTypes = {
    enumerador : PropTypes.any,
    tipo : PropTypes.string
}

export default EstatusLayoutRender