import {useTranslation} from "react-i18next";
import {Button, IconButton} from "@mui/material";
import {CloudUploadOutlined, SimCardDownloadOutlined} from "@mui/icons-material";
import PropTypes from "prop-types";
import useNotification from "../hooks/useNotification";

const DownloadOrUploadButton = ({
                                        value,
                                        field,
                                        id,
                                        tieneUrl,
                                        soloDescarga,
                                        onChange,
                                        text,
                                        accepted,
                                        allowedFileTypes
                                    }) => {
    tieneUrl = false;
    if (value){
        tieneUrl = value.trim() !== '';
    }

    const { t } = useTranslation();
    const notification = useNotification();

    const uploadFile = (event) => {
        const file = event.target.files[0];
        if(!allowedFileTypes.includes(file.type)){
            notification.warning(t(`Favor de elegir un archivo con extension ${accepted}`))
            return;
        }

        onChange(file,field)
    }

    return(
        <>
            {soloDescarga ?
                <>
                    {tieneUrl &&
                        <IconButton title={t('Descargar archivo')} variant={'grid'}>
                            <a href={process.env.REACT_APP_API_FILE_URI+value} download={''} target={'_blank'} rel={'noreferrer noopener'}>
                                <SimCardDownloadOutlined color={'info'}/>
                            </a>
                            <label className={'label'}>{t(text)}</label>
                        </IconButton>}
                </>
                :
                <>
                    {tieneUrl ?
                        <IconButton title={t('Descargar archivo')} variant={'grid'}>
                            <a href={process.env.REACT_APP_API_FILE_URI + value} download={''} target={'_blank'}
                               rel={'noreferrer noopener'}>
                                <SimCardDownloadOutlined color={'info'}/>
                            </a>
                            <label className={'label'}>{t(text)}</label>
                        </IconButton>
                        :
                        <label htmlFor={id}>
                            <input type="file"  id={id} name={id} accept={accepted}
                                   style={{display: 'none'}} onInput={uploadFile}/>
                            <Button variant="outlined" color={'info'} component="span" startIcon={<CloudUploadOutlined />}>
                                {t(text)}

                            </Button>
                        </label>
                    }
                </>

            }
        </>
    )
}

DownloadOrUploadButton.propTypes = {
    value : PropTypes.string,
    id: PropTypes.string,
    tieneUrl: PropTypes.bool,
    soloDescarga : PropTypes.bool,
    text: PropTypes.string,
    accepted: PropTypes.string,
    allowedFileTypes: PropTypes.any
}

export default DownloadOrUploadButton;