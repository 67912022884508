/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useState} from 'react'
import PropTypes from 'prop-types'
import usePersistedState from '../hooks/usePersistedState'
import {jwtDecode} from "jwt-decode";
import useApi from "../../api/useApi";
import useApiSource from "../../api/useApiSource";

const authContext = React.createContext(undefined)
const delayRefreshToken = 600000
const storageKey = 'user'
const useProvideAuth = () => {
    const [user, setUser] = usePersistedState(storageKey, null)
    const [esProveedor, setEsProveedor] = useState(user?.esProveedor ?? false)
    const [loggedOut, setLoggedOut] = React.useState(false)
    const apiSource =  useApiSource()
    const api = useApi('session/tokens')

    let intervalToken = null

    const refreshToken = useCallback(() => {
        if (intervalToken != null) {
            clearTimeout(intervalToken)
        }
        intervalToken = setTimeout(async function () {
            if (isAuthenticated()) {
                const token = await api.filter(null,{token:user.token})
                login({...user,token})
            }
            intervalToken = null
        }, delayRefreshToken)
    }, [user])

    const login = user => {
        const decoded = jwtDecode(user.token)
        setUser({...user, tokenExpiration: decoded.exp * 1000, empresaId: parseInt(decoded.empresaId)})
        setEsProveedor(user?.esProveedor ?? false)
    }

    const isAuthenticated = () => {
        return user?.token != null && Date.now() <= user.tokenExpiration
    }

    const logout = () => {
        setUser(null)
        apiSource.clear()
        setLoggedOut(true)
    }

    const clearSession = () => {
        setUser(null)
        setLoggedOut(true)
        setTimeout(() => {
            window.localStorage.removeItem('user')
        }, 500)
    }

    const updateToken = (token)=>{
        setUser({...user,token})
    }

    const getTokenFromStorage =()=>{
        const persistedState = localStorage.getItem(storageKey)
        if (persistedState == null || persistedState ==='')
            return null

        return JSON.parse(persistedState)?.token

    }

    return {
        clearSession,
        user,
        loggedOut,
        login,
        logout,
        isAuthenticated,
        refreshToken,
        esProveedor,
        updateToken,
        getTokenFromStorage
    }
}

const AuthProvider = ({children}) => {
    const auth = useProvideAuth()
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.any
}

export default AuthProvider

export const useAuth = () => {
    return React.useContext(authContext)
}
