import {getIsDisabled, getIsRequired, getIsVisible, getRender} from "./utility/formUtility";
import PropTypes from "prop-types";
import {useCallback, useContext} from "react";
import {FormContext} from "./FormContext";
import {useTranslation} from "react-i18next";


const Field = ({render, field, required, disabled, visible, componentProps, fields, label, columns, tabs, source, panels}) => {
    const renderElement = getRender(render);
    const {value, parentValue, onChange, submitted, onValidate} = useContext(FormContext);
    const {t} = useTranslation()

    const handleChange = useCallback((value, field)=>{
        onChange(value, field)
    },[onChange])

    if (!getIsVisible(visible, parentValue))
        return;



    return (<>
        {renderElement({
            value: value != null ? value[field] : null,
            parentValue,
            field,
            label: t(label),
            onChange:handleChange,
            onValidate,
            submitted,
            required: getIsRequired(required, parentValue),
            disabled: getIsDisabled(disabled, parentValue),
            fields,
            columns,
            tabs,
            panels,
            source,
            ...componentProps
        })}
    </>)
}

Field.propTypes = {
    render: PropTypes.any.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.any,
    visible: PropTypes.any,
    componentProps: PropTypes.object,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number
}

export default Field;