import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, IconButton } from '@mui/material'
import {MoreHorizSharp} from '@mui/icons-material'

export const DetailRowIconRender = ({ data, context}) => {
    const { t } = useTranslation()

    return (
        <Box display={'flex'} justifyContent={'center'} width={'100%'} gap={'10px'}>
            <IconButton title={t('Ver')} onClick={() => context.onEdit(data.id)} variant={'grid'}>
                <MoreHorizSharp color={'primary'}/>
            </IconButton>
        </Box>
    )
}

DetailRowIconRender.propTypes = {
    data: PropTypes.any,
    context: PropTypes.any
}