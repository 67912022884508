import {useTranslation} from "react-i18next";
import useApi from "../../../api/useApi";
import useGridFilter from "../../../common/hooks/useGridFilter";
import {useState} from "react";
import {currencyFormatter, dateFormatter, enumFormatter} from "../../../common/formatters/useLocaleFormatters";
import FormFilter from "../../../common/components/forms/formFilter/FormFilter";
import Field from "../../../common/components/forms/Field";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import FieldDateRangePicker from "../../../common/components/forms/fields/dateRangePicker/FieldDateRangePicker";
import AgGridContainer from "../../../common/layout/AgGridContainer";
import {AgGridReact} from "ag-grid-react";
import FieldAutoComplete from "../../../common/components/forms/fields/FieldAutocomplete";
import {DetailRowIconRender} from "../../../common/components/agGrid/DetailRowIconRender";
import FieldMultiSelect from "../../../common/components/forms/fields/multiselect/FieldMultiSelect";
import ProcesoPagoDetalle from "./ProcesoPagoDetalle";
import {getAgGridDefault} from "../../../common/components/agGrid/agGridUtility";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import {CheckCircleOutlined} from "@mui/icons-material";
import ComplementoPago from "./ComplementoPago";
import EstatusLayoutRender from "../ordenesCompra/EstatusLayoutRender";
import {defaultFilterProcesoPagos} from "../../Constantes";
import _ from "lodash";
import {useAuth} from "../../../common/security/AuthProvider";

const ProcesoPago = ({nuevoTotal}) => {
    const {t} = useTranslation();
    const editable = true// isEditable(menuOptions.usuarios);
    const [logDialogOpen, setLogDialogOpen] = useState(false);
    const api = useApi('configuracion/Facturas/pagos');
    const {esProveedor} = useAuth();
    const onDataLoaded = (data) => {
        nuevoTotal(2,_.sumBy(data, 'monto'));
    }
    const {
        data,
        onFilter,
        addEditGridContext,
        editForm
    } = useGridFilter({api, onDataLoaded});

    const onSubirComplemento = () => {
        setLogDialogOpen(true);
    }
    const closeLogModal = () => {
        setLogDialogOpen(false);
        addEditGridContext.refresh();
    }

    const [columnDefs] = useState([

        {field: 'pagoId', headerName: t('ID Pago'), resizable: false, minWidth: 150},
        {field: 'comprobantePago', headerName: t('Comprobante de pago'),resizable: false, minWidth: 200},
        {field: 'complementoPago', headerName: t('Folio Factura'), resizable: false, minWidth: 200},
        {field: 'proveedor', headerName: t('Proveedor'),resizable: false, minWidth: 300},
        {field: 'rfc', headerName: t('RFC'), resizable: false, minWidth: 150},
        {field: 'fechaProgramadaPago', headerName: t('Fecha Prog. Pago'), valueFormatter: dateFormatter, resizable: false, minWidth: 200},
        {field: 'fechaRealPago', headerName: t('Fecha Pago'), valueFormatter: dateFormatter, resizable: false, minWidth: 200},
        {field: 'monto', headerName: t('Monto'), valueFormatter:currencyFormatter, resizable: false, minWidth: 150, },
        {field: 'moneda', headerName: t('Moneda'), valueFormatter: enumFormatter, enum:'moneda', minWidth: 110, maxWidth: 110, resizable: false},
        {
            cellRenderer: EstatusLayoutRender,
            headerName: t('Estatus'),
            cellRendererParams: {editable, 'enumerador' : 'estatusProcesoPago'},
            headerComponentParams: {editable},
            resizable: false,
            minWidth: 220, maxWidth: 220,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                if (nodeA.data.estatus === nodeB.data.estatus) return 0;
                return (nodeA.data.estatus > nodeB.data.estatus) ? 1 : -1;
            }
        },
        {
            cellRenderer: DetailRowIconRender,
            cellRendererParams: {editable},
            headerComponentParams: {editable},
            resizable: false,
            minWidth: 75, maxWidth:75
        },
    ]);

    const onExport=(filtro)=>{
        addEditGridContext.onExport(filtro,'Proceso de Pago');
    }
    return(
        <>
            <Box display="flex" justifyContent="flex-end" position={'absolute'} top={18} right={0}>
                <Button sx={{width: 250}} variant="outlined" color={'info'} startIcon={<CheckCircleOutlined/>}
                        onClick={ () => onSubirComplemento() }>
                    {t('Carga complemento de pago')}
                </Button>
            </Box>

            <FormFilter onFilter={onFilter} filterOnInit={true} defaultValue={defaultFilterProcesoPagos} onExport={onExport} sessionSorageKey={'filtroProcesoPagos'}>
                <Field label={'ID Pago'} field={'PagoId'} render={FieldTextBox} md={1.5}/>
                <Field label={'Comprobante de pago'} field={'ComprobantePago'} render={FieldTextBox} md={1.5}/>
                <Field label={'Complemento de pago'} field={'ComplementoPago'} render={FieldTextBox} md={1.5}/>
                <Field label={'Proveedor'} field={'ProveedorId'} render={FieldAutoComplete} source={'proveedores'} md={1.5} visible={() => !esProveedor}/>
                <Field label={'RFC'} field={'RFC'} render={FieldTextBox} md={1.5} visible={() => !esProveedor}/>
                <Field label={'Fecha Pago'} field={'fechaRealPago'} render={FieldDateRangePicker}  md={3} xl={2.6}/>
                <Field label={'Estatus'} field={'EstatusIds'} render={FieldMultiSelect} source={'estatusProcesoDePago'} md={1.8}/>
            </FormFilter>

            <AgGridContainer className="ag-theme-material" width={'100%'}>
                <AgGridReact
                    {...getAgGridDefault(false)}
                    rowData={data}
                    context={addEditGridContext}
                    getRowId={(params) => params.data.id}
                    columnDefs={esProveedor ? columnDefs.filter((columna) => columna.field !== 'proveedor' && columna.field !== 'rfc') : columnDefs}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector = {[10, 20, 50, 100]}
                />
            </AgGridContainer>
            <ProcesoPagoDetalle editFormPP={editForm} dataFactura={data}/>
            <ComplementoPago complementoOpen={logDialogOpen} data={data}  onClose={() => closeLogModal()}/>
        </>
    )
}

export default ProcesoPago;
