import FormEditDialog, {

    withEditDialogContext
} from "../../../common/components/forms/formEditDialog/FormEditDialog";
import Field from "../../../common/components/forms/Field";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import useApi from "../../../api/useApi";
import fieldCalendar from "../../../common/components/forms/fields/FieldCalendar";
import FieldCheckBox from "../../../common/components/forms/fields/FieldCheckBox";
import {useTranslation} from "react-i18next";
import FieldMultiSelect from "../../../common/components/forms/fields/multiselect/FieldMultiSelect";
import useNotification from "../../../common/components/hooks/useNotification";

const ComunicadoDetalle = ({onSaved,disabled,editForm}) => {

    const {t} = useTranslation()
    const api = useApi('catalogos/comunicado')
    const notification = useNotification();
    const handleSave = (comunicado) => {

        if(isNaN(Date.parse(comunicado.fechaDe)))
        {
            notification.warning(t('La fecha inicio no tiene el formato correcto.'));
            return;
        }
        if(isNaN(Date.parse(comunicado.fechaHasta)))
        {
            notification.warning(t('La fecha fin no tiene el formato correcto.'));
            return;
        }

        if(comunicado.fechaDe > comunicado.fechaHasta)
        {
            notification.warning(t('La fecha inicio no puede ser superior a fecha fin.'));
            return;
        }

        else
        {
            return {...comunicado};
        }
    }

    return (
        <FormEditDialog api={api} title={t('Comunicado')} hideDelete={true} onSaved={onSaved} disabled={disabled} {...editForm} onSaving={handleSave} >
            <Field field="titulo" label={t('Titulo')} render={FieldTextBox} required={true} />
            <Field field="mensaje" label={t('Comunicado')} render={FieldTextBox} maxLength={500}  required={true} />
            <Field field="fechaDe" label={t('Fecha Inicio')} render={fieldCalendar} required={true}   />
            <Field field="fechaHasta" label={t('Fecha Fin')} render={fieldCalendar} required={true}   />
            <Field field="empresasIds" label={t('Empresa')} source={'empresasAll'} render={FieldMultiSelect}  required={true}/>
            <Field field="activo" label={t('Activo')} render={FieldCheckBox}/>
            <label>{t('IMPORTANTE: Este comunicado se enviará por correo en el día de fecha de INICIO a todos los usuarios de las empresas que hayas seleccionado')}</label>
        </FormEditDialog>
    )
}
export default withEditDialogContext(ComunicadoDetalle)