import React from 'react'
import PropTypes from 'prop-types'
import {Autocomplete} from '@mui/material'
import useSourceUtils from "../../../hooks/useSouceUtils";
import useInputValidation from "../../validations/useInputValidation";
import {sourceLabelName} from "../fieldConstants";
import listboxComponent, {CustomInput} from "./ListboxComponent";

export const AutoCompleteContext = React.createContext({})

const FieldMultiSelect = ({
    value, onChange, parent, field, label, options, source, disabled, required, submitted, className, orderBy, validators, params,
                              onValidate, valueById, limitTags, showSelectButtons,
      fitPopperWidth = false
}) => {
    showSelectButtons = showSelectButtons ?? true
    valueById = valueById ?? true
    disabled = disabled ?? false

    const {
        currentOptions,
        setSelectedId,
        getSelected,
        getSelectedId,
        getOptions
    } = useSourceUtils(value, options, source, params, true, valueById, orderBy)

    const [isOpen, setIsOpen] = React.useState(false)
    const [filteredOptions, setFilteredOptions] = React.useState([])

    const { hasValidationError, errorMessage } = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent
    })

    React.useEffect(() => {
        setFilteredOptions(currentOptions)
    }, [currentOptions])

    const onDropChange = (event, value) => {
        const ids = value?.map(e => e.id) ?? []
        setSelectedId(ids)
        onChange(valueById ? ids : value, field, value)
    }

    const multiselectControl = {
        selectAll: () => {
            handleSelected(filteredOptions.map(e => e.id))
        },
        unSelect: () => {
            const currentIds = filteredOptions.map(e => e.id)
            handleSelected(getSelectedId().filter(e => currentIds.indexOf(e) < 0))
        },
        showSelectButtons,
        isOpen
    }

    const handleSelected = (ids) => {
        setSelectedId(ids)
        const options = getOptions(ids)
        onChange(valueById ? ids : options, field ,options)
    }

    const onInputChange = (event, value, reason) => {
        filterOptions(value)
    }

    const filterOptions = (text) => {
        text = text.toLowerCase()
        if ((text ?? '').trim() === '') {
            setFilteredOptions(currentOptions)
        } else {
            setFilteredOptions(currentOptions.filter(e => e[sourceLabelName].toLowerCase().includes(text)))
        }
    }

    return (
        <>
            <AutoCompleteContext.Provider value={multiselectControl}>
                <Autocomplete
                    fullWidth={true}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    value={getSelected()}
                    multiple={true}
                    size="small"
                    disableCloseOnSelect
                    label={label}
                    options={currentOptions}
                    disabled={disabled}
                    getOptionLabel={(option) => (option ?? {})[sourceLabelName] ?? ''}
                    onChange={onDropChange}
                    classes={className}
                    ListboxComponent={listboxComponent}
                    renderInput={(params) => <>{
                                                   CustomInput({
                                                       params,
                                                       label,
                                                       error: submitted && hasValidationError,
                                                       helperText: submitted ? errorMessage() : ''
                                                   })
                                               } </>}
                    onInputChange={onInputChange}
                />
            </AutoCompleteContext.Provider>
        </>
    )
}

FieldMultiSelect.propTypes = {
    className: PropTypes.any,
    disabled: PropTypes.any,
    field: PropTypes.any,
    limitTags: PropTypes.number,
    onChange: PropTypes.func,
    onValidate: PropTypes.any,
    options: PropTypes.any,
    orderBy: PropTypes.any,
    params: PropTypes.any,
    parent: PropTypes.any,
    required: PropTypes.any,
    showSelectButtons: PropTypes.bool,
    source: PropTypes.any,
    submitted: PropTypes.any,
    validators: PropTypes.any,
    value: PropTypes.array,
    valueById: PropTypes.bool,
    label: PropTypes.string
}

const component = (props) => <FieldMultiSelect {...props}/>
export default component
