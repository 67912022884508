import {allComponents} from "../../Components";
import React from "react";

export const getRender = (render) => {
    if (typeof render === 'string' || render instanceof String){
        const renderComponent =  allComponents[render]
        if (renderComponent == null){
            return () => <div>The component {render} has not been created yet.</div>
        }
        return allComponents[render]
    }
    return  render
}

export const getIsRequired = (required, parentValue, params)=>{
    if (required == null)
        return false
    return  (typeof required === 'function') ? required(parentValue, params) : required
}

export const getIsVisible = (visible, parentValue, params) => {
    if (visible == null)
        return true
    return  (typeof visible === 'function') ? visible(parentValue, params) : visible
}

export const getIsDisabled = (disabled, parentValue, params) => {
    if (disabled == null)
        return false
    return  (typeof visible === 'function') ? disabled(parentValue, params) : disabled
}

//si children es un arreglo regresa ese mismo arreglo, si es solo un componente, regresa un arreglo con ese componente
//ya que cuando un elemento solo tiene un hijo, children no viene como arreglo
export const getChildrenAsArray =(children)=>{
    return children.length == null ? [children] : children
}