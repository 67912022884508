import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    pageColor:'lightblue',
    borderColor: 'darkgray',
    typography: {
        button: {
            textTransform: 'none'
        }
    },
    palette: {

    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white', // Change this to the desired background color
                },
            }
        },
        MuiCheckbox: {
            variants: [
                {
                    props: { variant: 'grid' },
                    style: {
                        padding: '0'
                    }
                }
            ]
        },
        MuiIconButton: {
            variants: [
                {
                    props: { variant: 'grid' },
                    style: {
                        padding: '0'
                    }
                }
            ]
        },
        MuiButton: {
            style: {
                textTransform: 'none'
            }
        }
    },
    labelTitle:{
        color: 'darkgray'
    },
    label:{
        fontWeight:'bold'
    },
    labelEstatusInfo:{
        backgroundColor:'#A1C9F9',
        color: '#0C49BC',
        borderRadius:'25px',
        paddingInline:'5px',
        fontWeight:'bold'
    },
    labelEstatusSuccess:{
        backgroundColor:'#C7F6DD',
        color: '#54BD8A',
        borderRadius:'25px',
        paddingInline:'5px',
        fontWeight:'bold'
    },
    labelEstatusAlert:{
        backgroundColor:'#FFB7B7',
        color: '#FD0909',
        borderRadius:'25px',
        paddingInline:'5px',
        fontWeight:'bold'
    },
    labelEstatusWarning:{
        backgroundColor:'#ffffff',
        color: '#f6f16f',
        borderRadius:'25px',
        paddingInline:'5px',
        fontWeight:'bold'
    },

})

export const defaultBorder = '1px solid #bdc3c7'

export default theme;

