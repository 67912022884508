import {Box} from "@mui/material";
import ExColumn from "../../common/components/forms/fields/grid/ExColumn";
import { gridData} from "../../data";
import ExGrid from "../../common/components/forms/fields/grid/ExGrid";
import {currencyFormatter} from "../../common/formatters/useLocaleFormatters";
import {rightAligned} from "../../common/components/agGrid/agGridUtility";
import Field from "../../common/components/forms/Field";
import FieldTextBox from "../../common/components/forms/fields/FieldTextBox";
import FormGrid from "../../common/components/forms/formGrid/FormGrid";
import React from "react";
import useForm from "../../common/components/hooks/useForm";
import {useTranslation} from "react-i18next";
import FieldDateRangePicker from "../../common/components/forms/fields/dateRangePicker/FieldDateRangePicker";
import FieldAutoComplete from "../../common/components/forms/fields/FieldAutocomplete";


const FormDemo = () => {
    const {t} = useTranslation();

    const {value, onChange, submitted, onValidate} = useForm()

    return (<>

        <FormGrid submitted={submitted} onChange={onChange} onValidate={onValidate} value={value}>
            <Field field={'fechas'} label={t('Fechas')} render={FieldDateRangePicker} required={true}/>
            <Field field={'password'} label={t('password')} render={FieldTextBox} required={true}/>
            <Field field={'proveedorId'} label={t('Proveedor')} source={'proveedores'} render={FieldAutoComplete}/>
        </FormGrid>

        <Box display={'grid'} gridTemplateColumns={'1fr'} margin={'10px'} gap={'1em'}>
            <ExGrid value={gridData}>
                <ExColumn field={'nombre'} title={'Nombre'}  ></ExColumn>
                <ExColumn field={'apellido'} title={'Apellido'}></ExColumn>
                <ExColumn field={'edad'} title={'Edad'} width={'100px'} ></ExColumn>
                <ExColumn field={'sueldo'} title={'Sueldo'} width={'150px'} valueFormatter={currencyFormatter} {...rightAligned}></ExColumn>
            </ExGrid>
        </Box>
    </>)
}
export default FormDemo;