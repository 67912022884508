export const data = [{
    render: 'accordion',
    field: 'tabs1',
    componentProps: {margin: '5px'},
    panels: [
        {
            field: 'tab1', label: 'tab1',
            fields: [
                {
                    render: 'box',
                    field: 'box1',
                    componentProps: {display: 'flex', margin: '10px', gap: '5px'},
                    fields: [{render: 'textBox', field: 'field1', label: 'field1', required: true}]
                },
                {render: 'textBox', field: 'tab1Field1', label: 'field2', required: true}
            ]
        },
        {
            field: 'tab2',
            label: 'tab2',
            fields: [{render: 'textBox', field: 'tab2Field2', label: 'field2', required: true}]
        }]
}]

// export const data = [
//     {
//         render: 'box', field: 'box1', componentsProps: {display: 'flex', margin: '10px', gap: '5px'},
//         fields: [
//             {render: 'textBox', field: 'fieldB1', label: 'field1', visible: (p) => p.field2 === 1},
//             {render: 'textBox', field: 'fieldB2', label: 'field2', required: true},
//             {
//                 render: 'box',
//                 field: 'box2',
//                 fields: [{render: 'textBox', field: 'field4', label: 'field4', required: true}]
//             },
//         ]
//     },
//     {render: 'textBox', field: 'field1', label: 'field3', required: (p) => p.field2 === 1},
//     {
//         render: 'dropDown',
//         field: 'field2',
//         label: 'field3',
//         required: true,
//         componentProps: {options: [{id: 1, name: 'Opcion 1'}, {id: 2, name: 'Opcion 2'}]}
//     }
// ]
//
// export const data = [
//     // {render: 'dateRangePicker', field: 'field1', label: 'field1', required: true},
//     // {
//     //     render: 'calendar',
//     //     field: 'field2',
//     //     label: 'field2',
//     //     required: true,
//     //     isVisible: (p) => p.field1 !== "2"
//     // },
//     {
//         render: 'grid', field: 'grid1', columns: [
//             {render:'textBox',  field: 'col1', title: 'col 1', required:true},
//             {
//                 render: 'textBox',
//                 field: 'col2',
//                 required: ({rowValue}) => rowValue.col1 === 'abc' ,
//                 title: 'Col 2',
//                 props: {options: [{id: 1, name: 'Opcion 1'}, {id: 2, name: 'Opcion 2'}]}
//             },
//             {render:'deleteIconButton', field: 'col3', title: 'Col3', headerComponent:{render:'addIconButton'}}
//         ]
//
//     }
// ]
//


export const gridData = [{
    nombre:'Juan',
    apellido:'Perez',
    edad:30,
    sueldo:'1500'
}]