import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useCallback, useContext, useState} from "react";
import FormAccordionPanel from "./FormAccordionPanel";
import Error from "@mui/icons-material/Error";
import {getChildrenAsArray} from "../../utility/formUtility";
import {FormContext} from "../../FormContext";

export default function FormAccordions({
                                           panels,
                                           children
                                       }) {
    const [expandedIndex, setExpandedIndex] = useState(-1)
    const [validationErrors, setValidationErrors] = useState({})

    const {value, parentValue, onChange, submitted, onValidate} = useContext(FormContext);

    panels = panels ?? getChildrenAsArray(children).map(e=>e.props)

    const handleChange = (index) => (event, isExpanded) => {
        setExpandedIndex(isExpanded ? index : -1);
    };

    const handleValidate = useCallback((error, field) => {
        onValidate(error, field)
        setValidationErrors(prevState => {
            return {...prevState, [field]: error}
        })
    }, [onValidate]);


    return (
        <>
            {panels.map((item, index) =>
                <Accordion key={item.field} expanded={expandedIndex === index} onChange={handleChange(index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{width: '33%', flexShrink: 0}}>
                            {item.label}
                        </Typography>
                        {validationErrors[item.field] && submitted && <Error color="error" fontSize="small"/>}
                    </AccordionSummary>
                    <FormAccordionPanel
                                          field={item.field}
                                          fields={item.fields}
                                          children={item.children}
                                          index={index}
                                          value={value[item.field]}
                                          parentValue={parentValue}
                                          onChange={onChange}
                                          onValidate={handleValidate}
                                          submitted={submitted}
                    />
                </Accordion>
            )}
        </>
    );
}