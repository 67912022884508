import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

const EstatusContenedor = styled(Box)(({theme}) => ({

    '& .info': {
        ...theme.labelEstatusInfo
    },
    '& .success': {
        ...theme.labelEstatusSuccess
    },
    '& .alert': {
    ...theme.labelEstatusAlert
    },
    '& .warning': {
        ...theme.labelEstatusWarning
    },
}))

export default EstatusContenedor;