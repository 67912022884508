import {useState} from "react";
import {Button, TextField} from "@mui/material";
import {usePrueba} from "./PruebaProvider";

const PruebaState = () => {
    const [text, setText] = useState('')
    const {prueba,update} = usePrueba();
    return (<>
        <TextField label={'TextField1'} value={text} onChange={(e)=>setText(e.target.value)} />
        <Button onClick={()=>update('cambio')}>{prueba}</Button>
    </>)
}
export default PruebaState;