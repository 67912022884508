import useApi from "../../../api/useApi";
import FormEditDialog, {withEditDialogContext} from "../../../common/components/forms/formEditDialog/FormEditDialog";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import Field from "../../../common/components/forms/Field";
import FormMenu from "./FormMenu";
import PropTypes from "prop-types";
import {toTree} from "../../../common/utility/treeUtility";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {checkboxState} from "../../Constantes";
import useNotification from "../../../common/components/hooks/useNotification";

const PerfilesDetalle =({editForm, disabled})=>{
    const {t} = useTranslation()
    const api = useApi('security/perfiles')
    const [menus, setMenus] = useState([])
    const notification = useNotification();
    useEffect(() => {
        loadMenu().then()
        // eslint-disable-next-line
    }, [])

    const loadMenu = async () => {
        const menus = await api.menus()
        setMenus(toTree(menus))
    }

    const handleLoad = (perfil) => {
        return {
            ...perfil,
            menus: new Map(
                perfil.menus.map(object => {
                    return [object.id, {
                        access: checkboxState.checked,
                        editable: object.editable ? checkboxState.checked : null
                    }]
                })
            )
        }
    }

    const handleSave = (profile) => {
        const menus = []
        // convierte el map a arreglo
        if(profile.menus === undefined || profile.menus.length===0 )
        {
            notification.warning(t('Favor de seleccionar al menos un menú'));
            return;
        }

        for (const menuId of profile.menus.keys()) {
            const menu = profile.menus.get(menuId)
            if (menu?.access != null) {
                const editable = menu.editable != null
                menus.push({id: menuId, editable})
            }
        }
        return {...profile, menus};
        //await api.save({...profile, menus})
    }

    return (
        <FormEditDialog api={api} title={'Perfil'} {...editForm} disabled={disabled} hideDelete={true} onLoading={handleLoad} onSaving={handleSave}>
            <Field field="name" label={'Perfil'} render={FieldTextBox}  disabled={disabled} required={true}/>
            <Field field="menus" label={t('Roles')}
                   render={(props) => <FormMenu {...props} menu={menus} disabled={disabled}/>}/>
        </FormEditDialog>
    )

}


PerfilesDetalle.propTypes = {
    editForm: PropTypes.shape({
        id: PropTypes.number,
        onCancel: PropTypes.any,
        onSaved: PropTypes.any
    })
}

export default withEditDialogContext(PerfilesDetalle)
