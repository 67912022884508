import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../../../common/security/AuthProvider";
import { useMenu } from "../../../common/layout/MenuProvider";
import menuOptions from "../../../common/security/menuOptions";
import useGridFilter from "../../../common/hooks/useGridFilter";
import useApi from "../../../api/useApi";
import Page from "../../../common/layout/Page";
import FormFilter from "../../../common/components/forms/formFilter/FormFilter";
import Field from "../../../common/components/forms/Field";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import AgGridContainer from "../../../common/layout/AgGridContainer";
import { getAgGridDefault } from "../../../common/components/agGrid/agGridUtility";
import { ViewRowIconRenderer } from "../../../common/components/agGrid/ViewRowIconRenderer";
import GridButtonsComponent from "../../../common/components/agGrid/GridButtonsComponent";
import UsuarioDetalle from "./UsuarioDetalle";
import ProveedorInvitarUsuario from "./ProveedorInvitarUsuario";
import useNotification from "../../../common/components/hooks/useNotification";

const Usuarios = () => {
    const { t } = useTranslation();
    const { esProveedor, user } = useAuth();
    const editable = useMenu().isEditable(menuOptions.usuarios);
    const api = useApi('configuracion/usuarios');
    const notification = useNotification();
    const [defaultFilter] = useState({});
    const [columnDefs, setColumnDefs] = useState([]);
    const { data: allData, onFilter, addEditGridContext, editForm } = useGridFilter({ api });
    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

    const filteredData = useMemo(() => {
        if (esProveedor && user.empresaId) {
            return allData.filter(usuario => usuario.empresaId === user.empresaId);
        }
        return allData;
    }, [allData, esProveedor, user.empresaId]);
    const handleInviteUser = useCallback(() => {
        setInviteDialogOpen(true);
    }, []);

    useEffect(() => {
        setColumnDefs([
            { field: 'username', headerName: t('Usuario') },
            { field: 'email', headerName: t('Email') },
            { field: 'name', headerName: t('Nombre') },
            { field: 'lastName', headerName: t('Apellido') },
            { field: 'profileName', headerName: t('Perfil') },
            { field: 'enabled', headerName: t('Activo'), maxWidth: 100 },
            {
                field: 'edit', headerName: t('Ver'),
                cellRenderer: ViewRowIconRenderer,
                cellRendererParams: { editable: editable || esProveedor },
                headerComponent: GridButtonsComponent,
                headerComponentParams: { editable: editable || esProveedor },
                maxWidth: 100
            },
        ]);
    }, [editable, t, esProveedor]);

    const onExport = (filtro) => {
        addEditGridContext.onExport(filtro, 'Usuarios');
    };

    const handleInviteSuccess = (email) => {
        notification.info(t('Se ha enviado una invitación a ' + email));
        setInviteDialogOpen(false);
        addEditGridContext.refresh();
    };

    const gridContext = {
        ...addEditGridContext,
        onInviteUser: handleInviteUser
    };

    return (
        <Page>
            <FormFilter onFilter={onFilter} filterOnInit={true} defaultValue={defaultFilter} onExport={onExport}>
                <Field label={'Usuario'} field={'name'} render={FieldTextBox} xs={2} />
                <Field label={'Email'} field={'email'} render={FieldTextBox} xs={2} />
            </FormFilter>
            <AgGridContainer className="ag-theme-material">
                <AgGridReact
                    {...getAgGridDefault(false)}
                    rowData={filteredData}
                    context={gridContext}
                    getRowId={(params) => params.data.id}
                    columnDefs={columnDefs}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 20, 50, 100]}
                />
            </AgGridContainer>
            <UsuarioDetalle editForm={editForm} disabled={!editable} />
            <ProveedorInvitarUsuario
                open={inviteDialogOpen}
                onClose={() => setInviteDialogOpen(false)}
                onInvite={handleInviteSuccess}
            />
        </Page>
    );
};

export default Usuarios;
