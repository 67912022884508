import React from 'react'
import PropTypes from 'prop-types'
import {
    CircularProgress,
    Modal
} from '@mui/material'

import { blue } from '@mui/material/colors'

const loadingContext = React.createContext(undefined)

const useLoadingProvider = () => {
    const [loading, setLoading] = React.useState({ visible: false })

    const show = () => {
        setLoading({ visible: true })
    }

    const hide = () => {
        setLoading({ visible: false })
    }

    return {
        visible: loading.visible,
        show,
        hide
    }
}

export const LoadingProvider = function ({ children }) {
    const loading = useLoadingProvider()

    return (
        <>
            <loadingContext.Provider value={loading}>
                {children}
            </loadingContext.Provider>

            <Modal open={loading.visible} style={{
                display: 'flex',
                background: 'rgba(0, 0, 0, 0.2)',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex:99999
            }}>

                <CircularProgress size={80} sx={{ color: blue[600] }}
                    style={{
                        outlineWidth: '0'
                    }}
                />
            </Modal>
        </>
    )
}

LoadingProvider.propTypes = {
    children: PropTypes.any
}

export default LoadingProvider

export const useLoading = () => {
    return React.useContext(loadingContext)
}
