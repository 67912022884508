/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useDebugValue, useEffect, useState} from 'react'
import i18n from 'i18next'
import useNotification from "./useNotification";


const useForm = (initialValue) => {
    const [value, setValue] = useState({})
    const [isDirty, setIsDirty] = useState(false)
    const [isValid, setIsValid] = useState(true)
    const [submitted, setSubmitted] = useState(false)
    const notification = useNotification()

    useDebugValue( {valid :  isValid ? 'valid' : 'invalid'});
    useDebugValue( {isDirty :  isDirty ? 'dirty' : 'clean'});

    useEffect(() => {
        setValue(initialValue)
    }, []);

    const onChange = useCallback((values) => {
        setIsDirty(true)
        setValue(values)
    }, [])

    const initialize = (value)=>{
        setIsDirty(false)
        setSubmitted(false)
        setValue(value)
    }

    const clear = () => {
        setValue({ ...initialValue })
        setIsDirty(false)
        setSubmitted(false)
    }

    const onValidate = useCallback((hasErrors) => {
        setIsValid( prevState => !hasErrors)
    },[])

    const submitValidations = () => {
        setSubmitted(true)
        if (!isValid) {
            notification.error(i18n.t('validation.checkErrors'))
        }
        return isValid
    }


    return {
        value,
        onChange,
        initialize,
        clear,
        onValidate,
        isValid,
        submitted,
        submitValidations
    }
}

export default useForm
