import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";

const Dias = ({titulo, onSelect, selected, width = '140px',
                      height = '100px'}) => {

    const {t} = useTranslation();
    const handleSelect = () => {

        if (onSelect) {
            onSelect();
        }
    }

    return(
        <Box
            display={'flex'}
            flexDirection='column'
            justifyContent={'center'}
            borderRadius={4}
            onClick={handleSelect}
            border={2}
            borderColor={selected ? 'primary.main' : 'white'}
            color={selected ? 'white' : 'black'}
            bgcolor={selected ? 'primary.main' : 'white'}
            //padding={2}
            marginRight={2}
            sx={{cursor : 'pointer'}}
            width={'100px'}

        >
            <Box textAlign={'center'}>
                {t(titulo)}
            </Box>
        </Box>
    )
}

export default Dias;