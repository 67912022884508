import _ from 'lodash'
import useServiceFactory from "./hooks/useServiceFactory";
const promises = new Map()

const useApiSource = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URI_V1 })
    return {
        get: async (sourceName, params, orderBy) => {
            const stringParams = (params == null || typeof params === 'string' || params instanceof String)
                ? params ?? ''
                : JSON.stringify(params)
            const key = sourceName + '_' + stringParams
            if (promises.has(key)) {
                return promises.get(key)
            }

            const promise = new Promise((resolve, reject) => {
                service.get(`/source/${sourceName}`, { params })
                    .then((data) => {
                        orderBy = orderBy == null ? ['name', 'asc'] : orderBy.split(' ')
                        resolve(_.orderBy(data, orderBy[0], orderBy[1]))
                    }
                    ).catch((error) => {
                        reject(error)
                    })
            })
            promises.set(key, promise)
            return promise
        },
        getById: async (sourceName, id) => service.get(`/source/${sourceName}/${id}`),
        search: async (sourceName, searchQuery, params) => service.get(`/source/${sourceName}/search/${searchQuery}`, params),
        clear: () => promises.clear()
    }
}

export default useApiSource
