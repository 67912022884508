/* eslint-disable react-hooks/exhaustive-deps */
import ExCell from "./ExCell";
import React, {useCallback} from "react";
import useFormValidation from "../../../hooks/useFormValidation";

const ExRow = ({
                   columns,
                   rowValue,
                   parentValue,
                   disabled,
                   onRowChanged,
                   onDelete,
                   submitted,
                   onRowChanging,
                   onValidateRow,
                   rowIndex,
                   onChangeCell
               }) => {

    const {onValidateField} = useFormValidation(onValidateRow, rowIndex)

    const handleCellChanged = useCallback(async (value, field) => {
        let newRow = {...rowValue, [field]: value}
        if (onRowChanging != null) {
            newRow = (onRowChanging?.constructor?.name === 'AsyncFunction') ? await onRowChanging(newRow, rowValue) : onRowChanging(newRow, rowValue)
        }

        onRowChanged(newRow, rowIndex)

        if (onChangeCell != null){
            onChangeCell(value, field, newRow, rowIndex)
        }
    }, [rowValue, onRowChanging, onRowChanged, rowIndex])
    
    const handleDelete = useCallback(() =>{
        onDelete(rowValue)
    },[onDelete, rowValue])

    return (<>
        {columns.map(col =>
            <ExCell
                key={col.field}
                column={col}
                rowValue={rowValue}
                parentValue={parentValue}
                disabled={disabled}
                onChange={handleCellChanged}
                onValidate={onValidateField}
                onDelete={handleDelete}
                submitted={submitted}
            />
        )}
    </>)
}
export default ExRow;