import {BarChart} from "@mui/x-charts/BarChart";
import {useEffect, useState} from "react";
import {currencyFormat} from "../../common/formatters/format";

const BarGrafica = ({data}) => {
    const label = useState(['']);
    const valor = useState([555])

    useEffect(() => {
        // eslint-disable-next-line
    }, []);

    return(
            <BarChart
                leftAxis={null}
                xAxis={[{ scaleType: 'band', data: data?.datosGrafica ? data?.datosGrafica?.map(e => e.fecha) : label}]}
                series={[{ data: data?.datosGrafica ? data?.datosGrafica?.map(e => e.monto) : valor, color: '#1C70C2', valueFormatter: currencyFormat }]}
                sx={{height: '150px'}}
            />
    )

}

export default BarGrafica;
