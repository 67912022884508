import useServiceFactory from "./hooks/useServiceFactory";
const api = 'security'
const useApiSecurity = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URI })
    return {
        validate: async (params) => service.post(`/${api}/validate`, params, { showSuccess: false, hideError: true }),
        register: async (params) => service.post(`/${api}/proveedores/registro`, params, { showSuccess: false, hideError: true }),
        recover: async (params) => service.post(`/${api}/solicitudCambiarPassword`, params, { showSuccess: false, hideError: true }),
        changepassword: async (params) => service.post(`/${api}/cambiarPassword`, params, { showSuccess: false, hideError: true }),
        refreshToken: async (params, token) => service.post(`/${api}/OAuth/refreshToken`, params, { showLoading: false, showSuccess: false, token })
    }
}

export default useApiSecurity
