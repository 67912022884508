import FormFilter from "../../../common/components/forms/formFilter/FormFilter";
import Field from "../../../common/components/forms/Field";
import Page from "../../../common/layout/Page";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import {AgGridReact} from "ag-grid-react";
import { useEffect, useRef, useState} from "react";
import {getAgGridDefault} from "../../../common/components/agGrid/agGridUtility";
import {useTranslation} from "react-i18next";
import AgGridContainer from "../../../common/layout/AgGridContainer";
import useGridFilter from "../../../common/hooks/useGridFilter";
import useApi from "../../../api/useApi";
import {useMenu} from "../../../common/layout/MenuProvider";
import menuOptions from "../../../common/security/menuOptions";
import {dateFormatter} from "../../../common/formatters/useLocaleFormatters";
import FieldDateRangePicker from "../../../common/components/forms/fields/dateRangePicker/FieldDateRangePicker";
import ComunicadoDetalle from "./ComunicadoDetalle";
import {ViewRowIconRenderer} from "../../../common/components/agGrid/ViewRowIconRenderer";
import GridButtonsComponent from "../../../common/components/agGrid/GridButtonsComponent";

const Comunicados = () => {
    const {t} = useTranslation()
    const editable = useMenu().isEditable(menuOptions.proveedores);
    const gridRef = useRef();
    const api = useApi('catalogos/comunicado');
    const [defaultFilter] = useState({
    });
    const [columnDefs, setColumnDefs] = useState([])
    const {data, onFilter, addEditGridContext, editForm} = useGridFilter({api});

    useEffect(() => {
        setColumnDefs([

            {field: 'id', headerName: t('ID Comunicado'), minWidth: 120,maxWidth: 150},
            {field: 'titulo', headerName: t('Titulo'),minWidth: 120,maxWidth: 300 },
            {field: 'mensaje', headerName: t('Comunicado'), minWidth: 150,maxWidth: 300},
            {field: 'fechaEnvio', headerName: t('Fecha Envio'), valueFormatter: dateFormatter, resizable: false, minWidth: 150},
            {field: 'fechaDe', headerName: t('Inicio'), valueFormatter: dateFormatter, resizable: false, minWidth: 150},
            {field: 'fechaHasta', headerName: t('Fin'), valueFormatter: dateFormatter, resizable: false, minWidth: 150},
            {field: 'activo',headerName: t('Activo'), maxWidth: 100},
            {
                field: 'edit', headerName: t('Ver'),
                cellRenderer: ViewRowIconRenderer,
                cellRendererParams: {editable},
                headerComponent: GridButtonsComponent,
                headerComponentParams: {editable},
                maxWidth: 100
            },
        ])
    }, [editable, t]);

    const guardadoComunicado=()=>{
        //addEditGridContext.refresh();
    }
    const onExport=(filtro)=>{
        addEditGridContext.onExport(filtro,'comunicado');
    }
    return (
        <Page>
            <FormFilter onFilter={onFilter} filterOnInit={true} defaultValue={defaultFilter} onExport={onExport}>
                <Field label={'Titulo'} field={'titulo'} render={FieldTextBox} xs={3} />
                <Field label={'Del'} field={'FechaComunicado'} render={FieldDateRangePicker}  xs={4}/>
            </FormFilter>
            <AgGridContainer className="ag-theme-material">
                <AgGridReact
                    {...getAgGridDefault(false)}
                    ref={gridRef}
                    rowData={data}
                    context={addEditGridContext}
                    getRowId={(params) => params.data.id}
                    columnDefs={columnDefs}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector = {[10, 20, 50, 100]}
                />
            </AgGridContainer>
            <ComunicadoDetalle  onSaved={guardadoComunicado} disabled={!editable} editForm={editForm} ></ComunicadoDetalle>
        </Page>
    )
}
export default Comunicados;
