import {TableCell, TableRow} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import {currencyFormat, dateFormat} from "../../../common/formatters/format";

const Row = ({ props}) => {

    const [data, setData] = useState(props);

    useEffect(() => {
        setData(props);
    },[props])

    return (
        <Fragment>
            <TableRow >
                <TableCell align="left" sx={{width: 100}}>{data?.ordenCompra}</TableCell>
                <TableCell align="left" sx={{width: 150}}>{data?.recepcionId}</TableCell>
                <TableCell align="left" sx={{width: 150}}>{data?.folioFactura}</TableCell>
                <TableCell align="left" sx={{width: 300}}>{data?.uuid}</TableCell>
                <TableCell align="left" sx={{width: 100}}>{dateFormat(data?.fechaRealPago)}</TableCell>
                <TableCell align="right" sx={{width: 100}}>{currencyFormat(data?.monto)}</TableCell>
                <TableCell align="left"  sx={{width: 50}}>{data?.moneda}</TableCell>
            </TableRow>
        </Fragment>
    )
}

export default Row;