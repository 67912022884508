import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import NumberFormatCustom from './NumberFormatCustom'
import useInputValidation from "../validations/useInputValidation";

const FieldInputNumber = ({
    value, onChange, parent, field, disabled, required,
    thousandSeparator,
    precision, onBlur, width,
    formDisabled, validators, onValidate, max, min, submitted, label
}) => {
    disabled = disabled ?? formDisabled ?? false

    const { hasValidationError, errorMessage, validationErrorCss } = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent
    })

    const handleChange = (event) => {
        if (value?.toString() === event.target.value || disabled === true) { return }

        let newValue = parseFloat(event.target.value)
        if (isNaN(newValue)) { newValue = null }

        onChange(newValue, field)
    }

    return (
        <>
            <TextField variant="outlined"
                autoComplete={'off'}
                value={value ?? ''}
                label={label}
                disabled={disabled}
                onBlur={onBlur}
                className={`${validationErrorCss}`}
                style={{ width: width ?? '100%' }}
                onChange={handleChange}
                name={field ?? 'textField'}
                onFocus={event => {
                    event.target.select()
                }}
                inputProps={{
                    className: 'numeric\'',
                    precision: precision ?? 2,
                    max: max ?? 2147483647,
                    min: min ?? -2147483648,
                    thousandSeparator
                }}
                InputProps={{
                    inputComponent: NumberFormatCustom
                }}
                size="small"
                error={submitted && hasValidationError}
                helperText={submitted ? errorMessage() : ''}
            />
        </>
    )
}

FieldInputNumber.propTypes = {
    debounceTimeOut: PropTypes.any,
    disabled: PropTypes.bool,
    field: PropTypes.string,
    formDisabled: PropTypes.any,
    max: PropTypes.number,
    min: PropTypes.any,
    onBlur: PropTypes.any,
    onChange: PropTypes.any,
    onValidate: PropTypes.any,
    parent: PropTypes.any,
    precision: PropTypes.any,
    required: PropTypes.any,
    submitted: PropTypes.any,
    thousandSeparator: PropTypes.any,
    validators: PropTypes.any,
    value: PropTypes.number,
    width: PropTypes.string
}

export default FieldInputNumber
