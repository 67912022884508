import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Menu, MenuItem, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import {useNavigate} from "react-router-dom";

const SubMenu = styled(Menu)(({theme}) => ({
    '.MuiMenu-paper': {
        backgroundColor: theme.menuColor,
        color: theme.palette.primary.dark,
        minWidth: '200px'
    },
    '& .MuiPaper-root': {
        '& .MuiMenu-list': {
            padding: '15px 0'
        },
        '& .MuiMenuItem-root': {
            '&:hover': {
                backgroundColor: "#F3F3F3"
            }
        }
    }
}))

const SubmenuPopup = ({anchorEl, open, menuItem, handleClose}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const onClickMenu = (menu)=>{
        navigate(menu.url)
        handleClose()
    }

    return (
        <SubMenu
            hideBackdrop={false}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            <Typography paddingLeft={'10px'} fontWeight={'bold'}>
                {menuItem?.name}
            </Typography>
            {menuItem?.children?.map(item =>
                <MenuItem key={item.id} onClick={()=>onClickMenu(item)}>
                    <Typography fontSize={'.9em'}>
                        {t(item.name)}
                    </Typography>
                </MenuItem>
            )}
        </SubMenu>
    )
}

SubmenuPopup.propTypes = {
    anchorEl: PropTypes.any,
    handleClose: PropTypes.any,
    menuItem: PropTypes.shape({
        children: PropTypes.array,
        name: PropTypes.any
    }),
    open: PropTypes.any
}

export default SubmenuPopup
