import React from 'react'
import { useTranslation } from 'react-i18next'
import {Box, Button, TextField, Typography} from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { AutoCompleteContext } from './FieldMultiSelect'

export const CustomInput = ({ params, error, label, helperText}) => {

    const length = params?.InputProps?.startAdornment?.length ?? 0;

    const startAdornments = [ length > 0 ? `${length} selected` : '']

    const customParams = {
        ...params,
        InputProps: {
            ...params.InputProps,
            startAdornment: startAdornments
        }
    }
    return (<TextField {...customParams} error={error} label={label} helperText={helperText} size="small" InputLabelProps={{
        shrink: length > 0
    }}/>)
}

const ListboxComponent = React.forwardRef(function ListboxComponent (props, ref) {
    // eslint-disable-next-line react/prop-types
    const { children, componentsProps, ...other } = props
    const { t } = useTranslation()
    const {
        selectAll,
        unSelect,
        showSelectButtons
    } = React.useContext(AutoCompleteContext)

    return (
        <div ref={ref} {...other} >
            <Box marginBottom={'7px'}>
                {showSelectButtons &&
                <>
                    <Button startIcon={<Check/>} variant="contained"
                        onClick={selectAll}>{t('formFields.fieldMultiselect.all')}</Button>
                    <Button startIcon={<Clear/>} variant="contained"
                        onClick={unSelect}>{t('formFields.fieldMultiselect.none')}</Button>
                </>
                }
            </Box>
            {/* eslint-disable-next-line react/prop-types */}
            {children.map((item, index) => {
                return (
                    <Typography key={index} component="li" {...item.props} noWrap>
                        {item.props.children}
                    </Typography>
                )
            })}
        </div>
    )
})

export default ListboxComponent
