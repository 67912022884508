const getBreadCrumbs =  (menus, parentUrl, path) =>{
    if (path.length <= 0 || menus.length <= 0)
        return []

    const menu = menus.find(e=>e.url === parentUrl + path[0]);

    if (menu == null)
        return []

    path.shift()

    return [menu.name, ...getBreadCrumbs(menu.children??[], menu.url + '/',  path)]
}

export default getBreadCrumbs