import {useTranslation} from "react-i18next";
import useApi from "../../api/useApi";
import {useCallback, useRef, useState} from "react";
import Page from "../../common/layout/Page";
import ContenedorContainer from "../gestionDocumentos/ContenedorContainer";
import {currencyFormat} from "../../common/formatters/format";
import {Box, Button} from "@mui/material";
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Dias from "./Dias";
import useGridFilter from "../../common/hooks/useGridFilter";
import {currencyFormatter, dateFormatter, enumFormatter} from "../../common/formatters/useLocaleFormatters";
import {getAgGridDefault, rightAligned} from "../../common/components/agGrid/agGridUtility";
import EstatusLayoutRender from "../gestionDocumentos/ordenesCompra/EstatusLayoutRender";
import {DetailRowIconRender} from "../../common/components/agGrid/DetailRowIconRender";
import Field from "../../common/components/forms/Field";
import FieldTextBox from "../../common/components/forms/fields/FieldTextBox";
import FieldAutoComplete from "../../common/components/forms/fields/FieldAutocomplete";
import FieldDateRangePicker from "../../common/components/forms/fields/dateRangePicker/FieldDateRangePicker";
import FieldMultiSelect from "../../common/components/forms/fields/multiselect/FieldMultiSelect";
import FormFilter from "../../common/components/forms/formFilter/FormFilter";
import {AgGridReact} from "ag-grid-react";
import AgGridContainer from "../../common/layout/AgGridContainer";
import DetalleFactura from "../gestionDocumentos/procesoFacturacion/DetalleFactura";
import BarGrafica from "./BarGrafica";
import useNotification from "../../common/components/hooks/useNotification";
import ArchivoDispersionDialog from "./ArchivoDispersionDialog";
import {estatusProcesoFacturacion, procesoLog} from "../Constantes";
import {addDaysIsoString, getTodayIsoString} from "../../common/utility/dateUtility";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const defaultFilter = {cantidadDias:7, fechaPago:[getTodayIsoString(), addDaysIsoString(30)]}

const ProgramacionPagos = () => {
    const {t} = useTranslation();
    const editable = true// isEditable(menuOptions.usuarios);
    const gridRef = useRef();
    const [mainTab, setMainTab] = useState(0);
    const notification = useNotification();
    const api = useApi('configuracion/facturas/programacionPagos');
    const apiFacturas = useApi('configuracion/facturas');
    const [dias] = useState([{id:0, texto:'7 Días', numero:7},
        {id:1,texto:'15 Días', numero:15},
        {id:2,texto:'30 Días', numero:30},
        {id:3,texto:'90 Días', numero:90}
    ]);
    const {data,onFilter, addEditGridContext, editForm, lastFilter} = useGridFilter({api});
    const [otrosFiltros,setOtrosFiltros] = useState({cantidadDias:7})
    const [columnDefs] = useState([
        {field: 'folioOrdenCompra', headerName: t('OC'), maxWidth: 170, headerCheckboxSelection: true, checkboxSelection: true},
        {field: 'numeroRecepcion', headerName: t('ID Recepción'), maxWidth: 120},
        {field: 'folioFactura', headerName: t('Folio Factura'), maxWidth: 100},
        {field: 'proveedor', headerName: t('Proveedor')},
        {field: 'rfc', headerName: t('RFC'), maxWidth: 170},
        {field: 'fechaProgramadaPago', headerName: t('Fecha Prog. Pago'), valueFormatter: dateFormatter, maxWidth: 170},
        {field: 'monto', headerName: t('Monto'), valueFormatter:currencyFormatter, maxWidth:170, ...rightAligned},
        {field: 'moneda', headerName: t('Moneda'), valueFormatter: enumFormatter, enum:'moneda', minWidth: 110, maxWidth: 110, resizable: false},
        {
            cellRenderer: EstatusLayoutRender,
            headerName: t('Estatus'),
            cellRendererParams: {editable, 'enumerador' : 'estatusProcesoFacturacion'},
            headerComponentParams: {editable},
            minWidth:200, maxWidth: 200
        },
        {
            cellRenderer: DetailRowIconRender,
            cellRendererParams: {editable},
            headerComponentParams: {editable},
            maxWidth: 75

        },
    ]);
    const [archivoDispersionDialogOpen, setArchivoDispersionDialogOpen] = useState(false);

    const [facturasAgrupadas, setFacturasAgrupadas] = useState([])

    const isRowSelectable = useCallback((rowNode) => {
        return rowNode.data ? rowNode.data.estatusId === estatusProcesoFacturacion.ProgramadoPago : false;
    }, []);
    const [cargandoFactura, setCargandoFactura] = useState(false);

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: -1,
            top: 18,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));
    const onChangeSegmento = (tabId, dias) => async () => {
        defaultFilter.cantidadDias = dias;
        otrosFiltros.cantidadDias = dias
        setMainTab(tabId)
        setOtrosFiltros({cantidadDias:dias})
        await onFilter({...lastFilter, ...otrosFiltros});
    }

    const generaArchivoDispersion = async() => {
        if(gridRef.current.api.getSelectedRows().length === 0){
            notification.warning(t('Selecciona al menos un registro'));
            return;
        }
        setFacturasAgrupadas(gridRef.current.api.getSelectedRows()?.map(e => e.facturaId));
        setArchivoDispersionDialogOpen(true);
    }

    const closeArchivoDispersionModal = async () => {
        setArchivoDispersionDialogOpen(false);
        await addEditGridContext.refresh();
    }

    const allowedFileTypes = [
        "text/plain"
    ];
    const uploadArchivoPagos = async(event) => {
        const file = event.target.files[0];
        if(!allowedFileTypes.includes(file.type)){
            notification.warning('Por favor seleccione un archivo permitido')
            return;
        }
        const formData = new FormData();
        formData.append('pagos', file);
        setCargandoFactura(true);
        try {
            await apiFacturas.post(`archivoPagos`, formData,{showSuccess:true});
        }catch (e){
            //deja catch vacío porque no se debe detener la ejecucion de la funcion
        }
        setCargandoFactura(false);
        await addEditGridContext.refresh();
    }
    const onExport=(filtro)=>{
        addEditGridContext.onExport(filtro,'Programación de Pagos');
    }

    return(
        <Page>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap:'10px' }}>
                <Box color={'black'} fontWeight={'bold'} >
                    {!cargandoFactura &&<label htmlFor={'xml'}>
                        <input type="file"  id={'xml'} name={'xml'} accept=".txt"
                               style={{display: 'none'}} onInput={uploadArchivoPagos}/>
                        <Button variant="outlined" color={'info'} component="span">
                            {t('Carga Archivo Pagos')}

                        </Button>
                    </label>}
                </Box>
                <Box textAlign={'right'} color={'black'} fontWeight={'bold'}>
                    <Button variant="outlined" color={'info'} onClick={() => generaArchivoDispersion()}>
                        {t('Generar Archivo Dispersión')}
                    </Button>
                </Box>
            </Box>
            <Box sx={{ display: 'grid' }}>
                <Box sx={{gridRow: "1", gridColumn: "50%", justifyContent: 'flex-start'}}>
                    <ContenedorContainer>
                    <Box sx={{color: 'text.primary', display: 'flex', fontWeight: 'bold', fontSize:'1.5em'}} >
                        <StyledBadge badgeContent={'$'} color="primary">
                            <CalendarTodayIcon />
                        </StyledBadge>
                        <label style={{marginLeft:'10px'}}>{currencyFormat(data?.total)}</label>
                    </Box>
                    </ContenedorContainer>
                </Box>
                <Box sx={{gridRow: "1", gridColumn: "50%", display: 'flex', justifyContent: 'flex-end', gap:'10px'}}>
                    <ContenedorContainer>
                        {dias.map(e =>
                            <Dias
                                key={e.id}
                                titulo={e.texto}
                                selected={e.id === mainTab}
                                onSelect={onChangeSegmento(e.id, e.numero)}
                            />
                        )}
                    </ContenedorContainer>
                </Box>


            </Box>
            <Box width={'100%'} height={'150px'}>
                <BarGrafica data={data}/>
            </Box>

            <FormFilter defaultValue={defaultFilter} onFilter={onFilter} filterOnInit={true} onExport={onExport} otherFilters={otrosFiltros}>
                <Field label={'Folio Factura'} field={'folioFactura'} render={FieldTextBox} md={1} value={""}/>
                <Field label={'Proveedor'} field={'proveedorId'} render={FieldAutoComplete} source={'proveedores'} md={2}/>
                <Field label={'RFC'} field={'rfc'} render={FieldTextBox} md={2} value={""}/>
                <Field label={'Fecha Prog. Pago'} field={'fechaPago'} render={FieldDateRangePicker}  md={3} xl={2.5}/>
                <Field label={'Estatus'} field={'estatusIds'} render={FieldMultiSelect} source={'estatusProgramacionPagos'} md={2}/>
            </FormFilter>

            <AgGridContainer className="ag-theme-material" width={'100%'}>
                <AgGridReact
                    {...getAgGridDefault(false)}
                    ref={gridRef}
                    rowData={data?.facturas}
                    rowSelection={"multiple"}
                    context={{...addEditGridContext}}
                    getRowId={(params) => params.data.id}
                    columnDefs={columnDefs}
                    isRowSelectable={isRowSelectable}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector = {[10, 20, 50, 100]}
                />
            </AgGridContainer>
            <DetalleFactura editarForma={editForm} proceso={procesoLog.Recepcion}/>
            <ArchivoDispersionDialog open={archivoDispersionDialogOpen} onClose={closeArchivoDispersionModal} facturas={facturasAgrupadas}/>
        </Page>
    )
}

export default ProgramacionPagos;
