
import EstatusContenedor from "../../../common/components/Shared/EstatusContenedor";
import {
    estatusInfoLayoutExGrid,
    estatusSuccessLayoutExGrid
} from "../../Constantes";
import {enumFormatter} from "../../../common/formatters/useLocaleFormatters";
import PropTypes from "prop-types";

const EstatusOrdenCompraDetalleLayout = ({ value, enumerador, tipo}) => {

    if(estatusInfoLayoutExGrid.includes(value))
    {
        tipo = 'info'
    }
    else if(estatusSuccessLayoutExGrid.includes(value))
    {tipo = 'success'}
    else{
        tipo = ''
    }

    return (
        <EstatusContenedor>
            <label className={tipo}>
                {enumFormatter({'value': value, 'colDef': {'enum': enumerador}})}
            </label>
        </EstatusContenedor>
    )
}

EstatusOrdenCompraDetalleLayout.propTypes = {
    enumerador: PropTypes.any,
    tipo: PropTypes.string
}

export default EstatusOrdenCompraDetalleLayout