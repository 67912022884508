import {useCallback, useEffect, useState} from "react";

const useFormValidation = (onValidate, field) => {

    const [validationErrors, setValidationErrors] = useState(new Set())
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        if (onValidate != null) {
            onValidate(validationErrors.size > 0, field)
        }
        setIsValid(validationErrors.size === 0)
    }, [onValidate, field, validationErrors])

    const onValidateField = useCallback((hasError, fieldName) => {
        setValidationErrors(prevState => getNewValidationErrors(hasError, fieldName, prevState))
    }, [])

    const getNewValidationErrors = (hasError, fieldName, prevState) => {
        const newValue = prevState
        if (hasError) {
            newValue.add(fieldName)
        } else {
            newValue.delete(fieldName)
        }
        return new Set(newValue);
    }

    return {
        isValid,
        onValidateField
    }
}

export default useFormValidation