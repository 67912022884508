import {useTranslation} from "react-i18next";
import { useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Box, Button, IconButton, TextField} from "@mui/material";
import {Close} from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import useNotification from "../../../common/components/hooks/useNotification";

const ComentariosRechazoFactura = ({open, onClose, onRechazar}) => {
    const {t} = useTranslation();
    const [comentarios, setComentarios] = useState('');
    const notification = useNotification();
    const cerrar = () => {
        setComentarios('');
        onClose();
    }

    const rechazar = async () => {
        //if (await notification.confirm(t('¿Está seguro que desea rechazar?'), t('Rechazo'))) {

        if(comentarios==="" || comentarios===null)
        {
            notification.warning(t('Favor de capturar el comentario'))
            return;
        }

        if (await notification.confirm(t('¿Está seguro que desea rechazar?'), t('Rechazo')))
        {
            onRechazar(false, comentarios);
            setComentarios('');
            onClose();
        }



        //}


    }

    return(
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'sm'}>
            <DialogTitle marginInline={4} marginTop={2} fontWeight={'bold'}>
                {t('Motivo de Rechazo')}
                <IconButton sx={{position: 'absolute', right: 8, top: 8}} onClick={() => cerrar()}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{marginInline: 4}}>
                {/*<DialogContentComponent>*/}
                    <Box>
                        <TextField value={comentarios} fullWidth={true} multiline={true} maxRows={5} inputProps={{maxLength: 100}} autoFocus={true} onChange={(e) => setComentarios(e.target.value)}/>
                    </Box>
                {/*</DialogContentComponent>*/}
                <DialogActions>
                    <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                                <Box textAlign={'right'} color={'black'} fontWeight={'bold'}
                                     sx={{gridRow: "1", gridColumn: "7/8"}}>
                                    <Button  variant="outlined" color={'inherit'} onClick={() => cerrar()}>
                                        {t('CANCELAR')}
                                    </Button>
                                </Box>
                                <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "8/9"}} >
                                    <Button  variant="outlined" color={'error'} startIcon={<Close/>} onClick={() => rechazar()}
                                             >
                                        {t('RECHAZAR')}
                                    </Button>
                                </Box>
                    </Box>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default ComentariosRechazoFactura;
