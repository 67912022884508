/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import PropTypes from 'prop-types'
import * as qs from 'qs'
import {Search} from '@mui/icons-material'
import {Autocomplete, CircularProgress, TextField} from '@mui/material'
import useApiSource from '../../../../api/useApiSource'
import {isString} from 'lodash'
import {dropDownIdEmpty, sourceLabelName} from './fieldConstants'
import useInputValidation from "../validations/useInputValidation";

const FieldAutoComplete = ({
                               value, onChange, parent, field, label, source, disabled, required,
                               submitted, className, validators,
                               params, onValidate, width, valueById,
                               minLength,
                               freeSolo
                           }) => {
    valueById = valueById ?? true

    const [loading, setLoading] = React.useState(false)
    const [filteredOptions, setFilteredOptions] = React.useState([])
    const [inputValue, setInputValue] = React.useState('')
    const [selectedOption, setSelectedOption] = React.useState({id: 0, [sourceLabelName]: ''})
    const [timer, setTimer] = React.useState(null);
    const apiSource = useApiSource()

    const { hasValidationError, errorMessage } = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent,
        defaultValue: dropDownIdEmpty
    })


    async function searchOption(event) {
        const value = (event.target.value ?? '').trim()
        if (value.length < (minLength ?? 2)) {
            return
        }

        if (timer != null) {
            clearTimeout(timer);
        }

        const timeout = setTimeout(() => {
            const encodedQuery = encodeURIComponent(value);
            const query = `${encodedQuery}${params == null ? '' : `?${qs.stringify(params)}`}`
            setLoading(true)
            apiSource.search(source, query).then((result) => {
                setLoading(false)
                if (result != null) {
                    setFilteredOptions(result ?? [])
                }
            })
            setTimer(null)
        }, 500)
        setTimer(timeout)
    }

    React.useEffect(() => {
        updateSelectedOption(value).then()
    }, [value])

    function onSelectedItem(event, value) {
        setFilteredOptions([])
        setSelectedOption(value ?? {id: 0, [sourceLabelName]: ''})
        fireChange(valueById ? value?.id : value)
    }

    function fireChange(itemName) {
        // clearTimeout(timer);
        if (onChange != null) {
            onChange(itemName, field)
        }
    }

    const updateSelectedOption = async (value) => {
        const emptyString = isString(value) && value.trim() === ''
        if (value != null && !isString(value) && !isNaN(value) && valueById && selectedOption?.id !== value && !emptyString) {
            const option = await apiSource.getById(source, value)
            setFilteredOptions([option])
            setSelectedOption(option ?? {id: 0, [sourceLabelName]: ''})
            return
        }

        if (!valueById && selectedOption?.id !== value?.id) {
            setSelectedOption(value ?? {id: 0, [sourceLabelName]: ''})
            return
        }

        if (freeSolo) {
            setInputValue(value ?? '')
        }

        if (!value) {
            setSelectedOption({id: 0, [sourceLabelName]: ''})
        }
    }

    const onInputChange = (event, value) => {
        setInputValue(value)
    }

    const onBlur = () => {
        if (freeSolo !== true) {
            return
        }

        if (inputValue === (selectedOption ?? {})[sourceLabelName]) {
            return
        }

        onChange(valueById ? inputValue : {id: inputValue})
    }

    return (
        <Autocomplete
            id="asynchronous-demo"
            freeSolo={freeSolo}
            style={{width: width ?? '100%'}}
            className={`formAutocomplete ${disabled ? 'disabled' : ''}`}
            value={selectedOption}
            inputValue={inputValue}
            getOptionLabel={(option) => (option ?? {})[sourceLabelName] ?? ''}
            filterOptions={() => filteredOptions}
            options={filteredOptions}
            onChange={onSelectedItem}
            onInputChange={onInputChange}
            onBlur={onBlur}
            loading={loading}
            disabled={disabled}
            popupIcon={<Search/>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    disabled={disabled}
                    value={(selectedOption ?? [])[sourceLabelName]}
                    onChange={searchOption}
                    helperText={submitted ? errorMessage() : ''}
                    error={submitted && hasValidationError}
                    size="small"
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    )
}




FieldAutoComplete.propTypes = {
    disabled: PropTypes.any,
    field: PropTypes.any,
    freeSolo: PropTypes.bool,
    minLength: PropTypes.number,
    onChange: PropTypes.func,
    onValidate: PropTypes.any,
    params: PropTypes.object,
    parent: PropTypes.any,
    required: PropTypes.any,
    source: PropTypes.any,
    validators: PropTypes.any,
    value: PropTypes.string,
    valueById: PropTypes.bool,
    width: PropTypes.string,
    label: PropTypes.string
}

export default FieldAutoComplete
