import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import getBreadCrumbs from "./menus/getBreadCumbs";
import useApi from "../../api/useApi";
import {toTree} from "../utility/treeUtility";


const MenuContext = createContext({});


const useMenuProvider = () => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [breadCrumbs, setBreadCrumbs] = useState([])
    const [menuTree, setMenuTree] = useState([])
    const [menuMap, setMenuMap] = React.useState(new Map())
    const location = useLocation();
    const path = location.pathname.substring(1);

    const api = useApi('session/menus');

    useEffect(() => {
        setBreadCrumbs(getBreadCrumbs(menuTree, '', path.split('/')))
    }, [path, menuTree]);

    const getMenu = useCallback(async () => {
        const menus = await api.filter({}, {showLoading: false})
        setMenuMap(new Map(menus.map((e) => [e.id, e])))
        setMenuTree(toTree(menus))
    }, [api])

    useEffect(() => {
        getMenu().then()
    }, [getMenu]);

    const hasAccess = (option) => {
        return menuMap?.has(option) ?? false
    }

    const isEditable = (option) => {
        const menu = menuMap.get(option)
        return menu?.editable ?? false;
    }

    return {
        menuOpen,
        handleDrawerOpen: () => setMenuOpen(true),
        handleDrawerClose: () => setMenuOpen(false),
        path,
        menus: menuTree,
        breadCrumbs: breadCrumbs,
        hasAccess,
        isEditable
    };
}


export const MenuProvider = ({children}) => {
    const value = useMenuProvider();

    return (
        <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
    );
};

export const useMenu = () => {
    return useContext(MenuContext)
}

