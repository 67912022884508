import {FormControl, IconButton, Input,  InputLabel} from "@mui/material";
import {useTranslation} from "react-i18next";
import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ItemFaq from "./ItemFaq";
import FaqDetalle from "./FaqDetalle";
import {useEffect, useState} from "react";
import useApi from "../../../api/useApi";
const ListaFaq = ({data,onSaved, editable}) => {

    const {t} = useTranslation();
    const api = useApi('catalogos');
    const [idActualFaq, setIdActualFaq]=useState();
    const [pregunta, setPregunta]=useState();
    const [dataFaq, setDataFaq]=useState([]);

    const onSearch=async () =>{
        const response = await api.get('faq', { Pregunta:pregunta},{showSuccess:false});
        setDataFaq(response);

    }
    const load = async () => {
        setDataFaq(data);
    }

    useEffect(() => {
        load();
        // eslint-disable-next-line
    },data,[] );

    const onFaqSaved=()=>{
        setIdActualFaq(null);
        onSearch();
        onSaved();
    }

    const change=event=>{
        setPregunta(event.target.value);
    }

    return(
        <FormControl variant="standard" onSubmit={onSearch}>
            <InputLabel htmlFor="input-with-icon-adornment" >
                {t('Busca la pregunta')}
            </InputLabel>
            <Input
                onChange={change}
                id="input-with-icon-adornment"
                value={pregunta}
                endAdornment={
                    <IconButton position="start" onClick={onSearch}>
                        <SearchIcon />
                    </IconButton>
                }
            />
            {
                dataFaq.map(e=>{
                        return e.activo && !e.esVideo &&
                            <ItemFaq pregunta={e.pregunta} respuesta={e.respuesta} id={e.id}  onEdit={(id)=>setIdActualFaq(id)} editable={editable}></ItemFaq>
                        }
                )
            }
            <FaqDetalle id={idActualFaq} onCancel={()=>setIdActualFaq(null)} onSaved={onFaqSaved}></FaqDetalle>
        </FormControl>

    )
}

export default ListaFaq;