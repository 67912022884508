import i18n from 'i18next'

export class RequiredValidator {
    constructor (defaultValue) {
        this.defaultValue = defaultValue
    }

    validate (value) {
        let error = value == null || value === this.defaultValue
        if (!error && (typeof value === 'string' || value instanceof String)) {
            error = value.trim().length === 0
        }

        if (!error && Array.isArray(value)) {
            error = value.length === 0
        }

        return { error, errorType: 'required', message: 'requerido' }
    }
}

export class MinLengthValidator {
    constructor (min) {
        this.min = min
    }

    validate (value, field) {
        if (value == null) { return { error: false } }
        const error = value.length < this.min
        return { error, errorType: 'minLengthValidator', message: i18n.t('validation.minLenght', { filed: field, min: this.min }) }
    }
}

export class RequiredInputValidator {
    validate (value, field) {
        const error = value == null || value.isEmpty === true
        return { error, errorType: 'required', message: i18n.t('validation.required', { filed: field }) }
    }
}

class EmailValidator {
    validate (value, field) {
        if(value == null || value === "") return {error: false};

        let error = false;
        if (typeof value === 'string' || value instanceof String) {
            error = !(String(value)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ))
        }

        return { error, errorType: 'email-invalida', message: 'formato de email incorrecto' }
    }
}

export const emailValidator = new EmailValidator()
