import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

 const Generales = styled(Box)(({theme}) => ({
    display: 'grid',
    '& .title': {
        ...theme.labelTitle
    },
    '& .label': {
        ...theme.label
    },
}))

export default Generales;
