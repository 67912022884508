
export const getAgGridDefault = (flex) => {
    const result = {
        rowSelection: 'single',
        enableCellTextSelection: true,
        ensureDomOrder: true,
        enableSorting: true,
        enableFilter: true,
        rowHeight:40,
        defaultColDef: {
            width: 100,
            sortable: true,
            resizable: true,
            flex:1
        }
    }
    if(flex){
        result.defaultColDef.flex = flex;
    }
    return result
}

export const rightAligned = {
    headerClass: 'ag-right-aligned-header',
    cellClass: 'right'
}
