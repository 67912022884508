/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useState} from 'react'
import useNotification from "../components/hooks/useNotification";

const useGridFilter = ({ api, onDataLoaded}) => {
    const [id, setId] = React.useState(null)
    const [data, setData] = React.useState([])
    const notification = useNotification()
    const [lastFilter, setLastFilter] = useState({})

    const onFilter = useCallback(async (filter) => {
        const data = await api.filter(filter)
        setLastFilter(filter)
        setData(data)
        if (onDataLoaded != null) { onDataLoaded(data) }
    },[])

    const onSaved =useCallback( async (filter) => {
        setId(null)
        await onFilter(filter)
    },[])

    const onExport =useCallback(  (filter, nombreArchivo) => {
        api.excel(filter,nombreArchivo)
    },[])

    const onDelete = async (id, filter) => {
        if (await notification.confirm('notificaciones.confirmarEliminar')) {
            await api.delete(id)
            await onFilter(filter)
        }
    }

    const onCancel =()=>{
        setId(null);
        refresh();
    }

    const refresh = async ()=>{
         await onFilter(lastFilter)
    }

    return {
        data,
        onFilter,
        lastFilter,
        refresh,
        editForm: {
            id,
            onSaved,
            onCancel
        },
        addEditGridContext:
            {
                onEdit: (id) => setId(id),
                onDelete,
                onAdd: () => setId(0),
                onExport,
                refresh
            }
    }
}

export default useGridFilter
