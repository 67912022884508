import PropTypes from 'prop-types'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import useInputValidation from "../validations/useInputValidation";
import {isoDateFormat} from "../../../constants";
import dayjs from "dayjs";

const FieldCalendar = ({
                          value, onChange, field, disabled, required, validators, onValidate,
                           className, label, submitted, minDate, maxDate
                      }) => {
    disabled = disabled ?? false

    minDate = minDate == null ? null : dayjs(minDate);
    maxDate = maxDate == null ? null : dayjs(maxDate);

    const { hasValidationError, errorMessage } = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate
    })

    const onValueChange = (newValue) => {
        const newDate= newValue != null ? newValue.format(isoDateFormat) : null;
        onChange(newDate , field)
    }


    return (
        <DatePicker
            value={value != null ?  dayjs(value) : null}
            label={label}
            onChange={onValueChange}
            disabled={disabled}
            minDate={minDate && minDate}
            maxDate={maxDate && maxDate}
            size="small"
            slotProps={{ textField: { size: 'small' , error:submitted && hasValidationError, helperText:submitted ? errorMessage() : '' }
            }}
        />
    )
}

FieldCalendar.propTypes = {
    className: PropTypes.any,
    disabled: PropTypes.any,
    field: PropTypes.any,
    formDisabled: PropTypes.any,
    label: PropTypes.string,
    maxDate: PropTypes.any,
    minDate: PropTypes.any,
    onChange: PropTypes.func,
    onValidate: PropTypes.any,
    required: PropTypes.any,
    submitted: PropTypes.any,
    validators: PropTypes.any,
    value: PropTypes.any
}

export default FieldCalendar;