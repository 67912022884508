import {getIsRequired, getRender} from "../../utility/formUtility";
import {Box} from "@mui/material";

const ExCell = ({column, rowValue, parentValue, onChange, onValidate, submitted, onDelete}) => {

    const renderElement = column.render ? getRender(column.render) : null
    const field = column.field
    const textValue = column.valueFormatter == null ? rowValue[field] : column.valueFormatter({value:rowValue[field], ...column})

    return (<Box display={'flex'} className={column.cellClass}>
        {renderElement ?
            renderElement({
                value:rowValue[field],
                field,
                parentValue,
                onChange,
                required: getIsRequired( column.required, {field, rowValue, parentValue} ),
                onValidate,
                submitted,
                onDelete,
                ...column.cellParams
            }):
            <span>{(textValue)}</span>
        }
    </Box>)
}
export default ExCell;