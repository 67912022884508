import {
    estatusProcesoLayoutRender, estatusActivoLayoutRender, estatusBloqueadoLayoutRender, estatusInactivoLayoutRender
} from "../../Constantes";
import {enumFormatter} from "../../../common/formatters/useLocaleFormatters";
import PropTypes from "prop-types";
import EstatusContenedor from "../../../common/components/Shared/EstatusContenedor";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import useApi from "../../../api/useApi";
import useNotification from "../../../common/components/hooks/useNotification";

const AccionProveedorLayoutRender = ({ data,context, enumerador, tipo,mensaje}) => {
    const { t } = useTranslation();
    const api2 = useApi('catalogos/proveedores');
    const notification = useNotification()
    const confirm = async () => {
        if (data.id === 0 || data.id == null) {
            return
        }
        await api2.post( data.id+'/linkRegistro', data.id);
        await context.refresh()
        await notification.info(t('Se envía email para el proveedor '+data.razonSocial))
    }
    const desbloquear = async () => {
        await api2.put(data.id,'desbloquear');
        await context.refresh();
        await notification.info(t('Se desbloquea proveedor '+data.razonSocial))
    }

    if(estatusProcesoLayoutRender.includes(data.estatus))
    {
        tipo = 'success'
        return (

            <Button variant="outlined" color={'success'} onClick={() => confirm()}>
                Finalizar Registro
            </Button>
        )
    }
    else if(estatusActivoLayoutRender.includes(data.estatus)){

        if (data.tieneComplementoRetrasado){
            tipo = 'alert'
            mensaje=t('Complemento Retrasado')
        }
        else
        {
            tipo = ''
        }
        return (

            <EstatusContenedor>
                <label className={tipo}>
                    {mensaje}
                </label>
            </EstatusContenedor>
        )

    }
    else if (estatusBloqueadoLayoutRender.includes(data.estatus)){
        tipo = 'warning'
        return (
            <Button variant="outlined" onClick={() => desbloquear()}>
                Desbloquear
            </Button>
        )
    }
    else if (estatusInactivoLayoutRender.includes(data.estatus)){
        tipo = 'warning'
        return (
            <>
            </>
        )
    }
    else{
        return (

            <EstatusContenedor>
                <label>
                    {enumFormatter({'value':data.estatus, 'colDef':{'enum':enumerador}})}
                </label>
            </EstatusContenedor>
        )
    }


}

AccionProveedorLayoutRender.propTypes = {
    enumerador : PropTypes.any,
    context: PropTypes.any,
    tipo : PropTypes.string,
    mensaje : PropTypes.string
}

export default AccionProveedorLayoutRender