import DialogTitle from "@mui/material/DialogTitle";
import {Box, Button, IconButton} from "@mui/material";
import {CheckCircleOutlined, Close, CloudUploadOutlined} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentComponent from "../../../common/components/Shared/DialogContentComponent";
import Generales from "../../../common/components/Shared/Generales";
import useApi from "../../../api/useApi";
import useNotification from "../../../common/components/hooks/useNotification";
import {useAuth} from "../../../common/security/AuthProvider";
import DownloadOrUploadButton from "../../../common/components/Shared/DownloadOrUploadButton";

const ComplementoPago = ({complementoOpen,onClose, data}) => {
    const {t} = useTranslation();
    const open = complementoOpen;
    const apiComplemento = useApi('configuracion/Facturas');
    const notification = useNotification();
    const {esProveedor} = useAuth();
    const [cargandoFactura, setCargandoFactura] = useState(false);
    const [xml, setXml] = useState(null);
    const [pdf, setPdf] = useState(null);
    useEffect(() => {
        // eslint-disable-next-line
    }, [open]);



    const closeLogModal = () => {
        onClose();
    }

    const onChangeCell = async (event)=>{
        const formData = new FormData();
        const file = event.target.files[0];
        
        for (let i =0;i<data.length; i++)
        {
            formData.append('facturaIds['+i+']',data[i].id);
        }

        formData.append('complemento', file);

        await apiComplemento.post(`complemento`, formData);
        onClose();
        await notification.info(t('Complemento cargado'));
    }

    const setXmlProv = (value,field) =>{
        setXml(value);
    }

    const setPdfProv = (value,field) =>{
        setPdf(value);
    }

    const onCargarComplementoProv = async ()=>{
        if(xml === null || pdf === null){
            notification.warning(t('Favor de seleccionar el complemento en Xml y Pdf'));
            return
        }
        const formData = new FormData();

        for (let i =0;i<data.length; i++)
        {
            formData.append('facturaIds['+i+']',data[i].id);
        }

        formData.append('complemento', xml);
        formData.append('complementoPdf', pdf);
        setCargandoFactura(true);
        try {
            await apiComplemento.post(`complemento`, formData);
        }catch (e){
            //deja catch vacío porque no se debe detener la ejecucion de la funcion
        }
        setXml(null);
        setPdf(null);
        setCargandoFactura(false);
        onClose();
        await notification.info(t('Complemento cargado'));
    }

    return(
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}>
                <DialogTitle marginInline={4} marginTop={2} fontWeight={'bold'}>
                    <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                        <Box sx={{gridRow: "1", gridColumn: "1/9"}}>
                            { t(`Complemento de Pago`)}
                        </Box>
                    </Box>

                    <IconButton sx={{position: 'absolute', right: 8, top: 8}} onClick={() => closeLogModal()}>
                        <Close/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{marginInline: 4}}>

                    {esProveedor && <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                        <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "6/7"}}>
                            {!cargandoFactura && <DownloadOrUploadButton
                                value={data?.rutaXml}
                                id={'xml'} text={'XML'} accepted={'.xml'} allowedFileTypes={['text/xml']}
                                // soloDescarga={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                onChange={setXmlProv}
                                field={data?.rutaXml}/>
                            }
                        </Box>
                        <Box color={'black'} fontWeight={'bold'} textAlign={'right'} sx={{gridRow: "1", gridColumn: "7/8"}}>
                            {!cargandoFactura && <DownloadOrUploadButton value={data?.rutaPdf}
                                                                         id={'pdf'} text={'PDF'} accepted={'.pdf'} allowedFileTypes={['application/pdf']}
                                                                         // soloDescarga={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                                                         onChange={setPdfProv}
                                                                         field={data?.rutaPdf}/>
                            }
                        </Box>
                    </Box>}

                    <DialogContentComponent>

                        <Generales >
                            {esProveedor ?
                                <>
                                    <h3>
                                        {t('NOTA')}
                                    </h3>
                                    <label>
                                        {t('Puedes cargar un complemento de pago correspondiente a múltiples facturas y, mediante la lectura del archivo .xml, se actualizarán automáticamente los estatus de las facturas correspondientes')}
                                    </label>
                                </>
                                :
                                <Box display={'inline'}>
                                    <label style={{marginRight: '5px'}} className={'title'}>{t('Subir archivo complemento de pago')}</label>
                                    <IconButton title={t('Cargar archivo')} variant={'grid'}>
                                        <label htmlFor={'xml'}>
                                            <CloudUploadOutlined color={'success'}/>
                                        </label>

                                        <input type="file" onInput={onChangeCell} id={'xml'} name={'xml'} accept=".xml" style={{display:'none'}}/>
                                    </IconButton>

                                </Box>
                            }

                        </Generales>
                    </DialogContentComponent>
                    <DialogActions>
                        <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                            {esProveedor ?
                                <>
                                    <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "8/9"}} >
                                        <Button  variant="outlined" color={'inherit'} startIcon={<Close/>}
                                                 //disabled={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                                 onClick={() => closeLogModal()}
                                            >
                                            {t('CACELAR')}
                                        </Button>
                                    </Box>
                                    <Box textAlign={'right'} color={'black'} fontWeight={'bold'}
                                         sx={{gridRow: "1", gridColumn: "9/10"}}>
                                        <Button  variant="outlined" color={'success'} startIcon={<CheckCircleOutlined/>}
                                                 //disabled={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                                 onClick={() => onCargarComplementoProv()}
                                        >
                                            {t('GUARDAR')}
                                        </Button>
                                    </Box>
                                </>
                                :
                                <></>}
                        </Box>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ComplementoPago;
