import {useCallback, useContext, useState} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import FormTabPanel from "./FormTabPanel";
import Error from '@mui/icons-material/Error';
import {FormContext} from "../../FormContext";
import {getChildrenAsArray} from "../../utility/formUtility";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const FormTabs = ({tabs, componentProps, children}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [validationErrors, setValidationErrors] = useState({})
    tabs = tabs ?? getChildrenAsArray(children).map(e=>e.props)

    const {value, parentValue, onChange, submitted, onValidate} = useContext(FormContext);

    const handleChangeTabIndex = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleValidate = useCallback((error, field) => {
        onValidate(error, field)
        setValidationErrors(prevState => {
            return {...prevState, [field]: error}
        })
    }, [onValidate]);

    //todo temporal por debug, quitarlo una vez que esté mas estable
    const onTabChange = useCallback((value, field)=>{
            onChange(value, field)
    },[onChange])

    return (
        <Box sx={{width: '100%'}} {...componentProps}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabIndex} onChange={handleChangeTabIndex} aria-label="basic tabs example">
                    {tabs.map((tab, index) =>
                        <Tab key={tab.field} label={tab.label} {...a11yProps(index)}
                             icon={<>
                                 {validationErrors[tab.field] && submitted && <Error  color="error"  fontSize="small" />}
                             </>}
                             iconPosition={"end"} />
                    )}
                </Tabs>
            </Box>
            {tabs.map((tab, index) =>
                <FormTabPanel
                               key={tab.field}
                               field={tab.field}
                               fields={tab.fields}
                               children={tab.children}
                               index={index}
                               tabIndex={tabIndex}
                               value={value[tab.field]}
                               parentValue={parentValue}
                               onChange={onTabChange}
                               onValidate={handleValidate}
                               submitted={submitted}
                />
            )}
        </Box>
    )
}
export default FormTabs;