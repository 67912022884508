import DialogTitle from "@mui/material/DialogTitle";
import {Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {CheckCircleOutlined, Close} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentComponent from "../../../common/components/Shared/DialogContentComponent";
import useApi from "../../../api/useApi";
import Row from "./Row";
import {currencyFormat} from "../../../common/formatters/format";
import {sumBy} from "lodash";
import Button from "@mui/material/Button";
import ComplementoPago from "./ComplementoPago";


const ProcesoPagoDetalle = ({editFormPP, dataFactura}) => {
    const {t} = useTranslation();
    const open = editFormPP.id != null;
    const [data, setData] = useState([]);
    const [logDialogOpen, setLogDialogOpen] = useState(false);
    const api = useApi('configuracion/Facturas/FacturasPago');

    useEffect(() => {
        if(open){

            load(editFormPP.id,dataFactura?.filter((f) => f.id === editFormPP.id)[0].pagoId).then()}
        // eslint-disable-next-line
    }, [editFormPP.id, open]);

    const load = async (id,pagoId) => {

        if (id === 0 || id == null) {
            return
        }
        const response = await api.get(pagoId);
        setData(response);
    }

    const onSubirComplemento = () => {
        setLogDialogOpen(true);
    }

    const closeLogModal = () => {
        setLogDialogOpen(false);
    }

    return(
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'lg'}>
                <DialogTitle marginInline={4} marginTop={2} fontWeight={'bold'}>
                    <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                        <Box sx={{gridRow: "1", gridColumn: "1/7"}}>
                            { t(`Resumen de Pago`)}
                        </Box>
                    </Box>

                    <IconButton sx={{position: 'absolute', right: 8, top: 8}} onClick={editFormPP.onCancel}>
                        <Close/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{marginInline: 4}}>
                    <DialogContentComponent>
                        <TableContainer sx={{height:400 }}>
                            <Table aria-label="sticky collapsible table" stickyHeader size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{fontWeight:'bold', width: 100}}>{t('OC')}</TableCell>
                                        <TableCell align="left" sx={{fontWeight:'bold', width: 150}}>{t('ID Recepción')}</TableCell>
                                        <TableCell align="left" sx={{fontWeight:'bold', width: 150}}>{t('Folio Factura')}</TableCell>
                                        <TableCell align="left" sx={{fontWeight:'bold', width: 300}}>{t('UUID')}</TableCell>
                                        <TableCell align="left" sx={{fontWeight:'bold', width: 100}}>{t('Fecha Pago')}</TableCell>
                                        <TableCell align="left" sx={{fontWeight:'bold', width: 100}}>{t('Monto')}</TableCell>
                                        <TableCell align="left" sx={{fontWeight:'bold', width: 50}}>{t('Moneda')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.map((row, index) => (
                                        <Row key={index} props={row}/>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                            <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "6/9"}}>
                                <label >{t('Monto Total de Pago ')}</label>
                            </Box>
                            <Box color={'primary.main'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "8/9"}}>
                                <label >{currencyFormat(sumBy(data, 'monto'))}</label>
                            </Box>
                        </Box>

                    </DialogContentComponent>
                    <DialogActions>
                        <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                            <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "8/9"}}>
                            </Box>
                            <Box textAlign={'right'} color={'black'} fontWeight={'bold'}
                                 sx={{gridRow: "1", gridColumn: "9/10"}}>
                                <Button sx={{width: 250}} variant="outlined" color={'info'} startIcon={<CheckCircleOutlined/>}
                                        onClick={ () => onSubirComplemento() }>
                                    {t('Carga complemento de pago')}
                                </Button>
                            </Box>
                        </Box>

                    </DialogActions>
                    <ComplementoPago complementoOpen={logDialogOpen} data={data}  onClose={() => closeLogModal()}/>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ProcesoPagoDetalle;
