import axios from 'axios'
import swal from 'sweetalert2'
import * as qs from 'qs'
import FileDownload from 'js-file-download'
// import {useLoadingDispatch} from "../layout/show-loading-context";
import { useTranslation } from 'react-i18next'
import useNotification from "../../common/components/hooks/useNotification";
import {useAuth} from "../../common/security/AuthProvider";
import {useLoading} from "../../common/components/LoadingProvider";

// the service factory by default will show loading on post and put, but gets
// to change this default configuration, can set config.showLoading: true
// get example:   async (params) => service.get(`/apiUrl`, {params, showLoading:true}),
// post example:  async (params) => service.post(`apiUrl`, params, {showLoading:false}),
function useServiceFactory (options = {}) {
    const loading = useLoading()
    const notification = useNotification()
    const auth= useAuth()
    const headers = options.headers || {}
    const { t } = useTranslation()

    const baseUrl = (options && options.uri) || process.env.REACT_APP_API_URI
    const isMockServer = baseUrl == null || baseUrl.includes('http://localhost:3001')

    const service = axios.create({
        baseURL: baseUrl,
        timeout: (options && options.timeout) || 1000000,
        headers,
        paramsSerializer: (params) => qs.stringify(params),
        // withCredentials: true,
    })

    const showLoading = (config) => {
        if ((config.showLoading ?? config.method !== 'get') !== true) return
        loading.show()
    }

    const showSuccess = (config) => {
        const defaultShow = config.method !== 'get' && typeof config.data === 'string'
        if ((config.showSuccess ?? defaultShow) !== true) return
        notification.success(t('apiRequests.success'))
    }

    const hideLoading = (config) => {
        if (config != null && (config.showLoading ?? config.method !== 'get') !== true) return
        loading.hide()
    }

    const processError = (messagesStr) => {
        const messages = messagesStr.split(',')
        let result = ''

        for(let message of messages) {
            const componets = message.trim().split('|')
            let messageText = t('apiErrors.' + componets[0])
            for (let i = 1; i < componets.length; i++) {
                messageText = messageText.replaceAll('${' + (i - 1) + '}', componets[i])
            }
            result += messageText + '<br/>'
        }

        return result
    }

    service.interceptors.request.use(
        function (config) {

            showLoading(config)

            const token = config?.token ?? auth?.getTokenFromStorage()
            config.headers.authorization = 'Bearer ' + token

            if (config.download === true) {
                config.responseType = 'blob'
            }

            return config
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error)
        }
    )

    service.interceptors.response.use(
        (res) => {
            hideLoading(res.config)
            if (res.config.download === true) {
                FileDownload(res.data, res.config.fileName)
                return null
            }
            if (res.status === 202) {
                notification.error(processError(res.data.errorCode))
                return { error: true }
            } else { showSuccess(res.config) }
            if (res.data.timeGenerated != null) return res.data.result
            return res.data
        },
        async (error) => {
            hideLoading(error.response?.config)
            if (error.response) {
                switch (error.response.status) {
                case 400:
                    await swal.fire({
                        titleText: t('apiRequests.warning'),
                        html: processError(error.response.data.errorMessage || error.response.data),
                        type: 'error'
                    })
                    return Promise.reject(error)
                case 401:
                    if (!error.response?.config?.hideError) {
                        await swal.fire({
                            titleText: t('apiRequests.unauthenticatedTitle'),
                            text: t('apiRequests.unauthenticatedText'),
                            type: 'warning'
                        })
                        return Promise.reject(error)
                    }
                    return error
                case 403:
                    await swal.fire({
                        titleText: error.response.data != null ? error.response.data : t('apiRequests.forbiddenTitle'),
                        text: error.response.data != null ? '' : t('apiRequests.forbiddenText'),
                        type: 'warning'
                    })
                    return Promise.reject(error)
                case 404:
                case 422:
                case 500:
                case 502:
                default:
                    if (isMockServer) return
                    await swal.fire({
                        titleText: t('apiRequests.error'),
                        text: error.response.data.message,
                        type: 'warning'
                    })
                    return Promise.reject(error)
                }
            }
            return Promise.reject(error)
        }
    )

    return service
}

export default useServiceFactory
