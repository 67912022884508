import {IconButton} from "@mui/material";
import {CloudUploadOutlined, SimCardDownloadOutlined, PictureAsPdfOutlined} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const DownloadOrUploadButtonIcon = ({
    value,
    valuePdf,
    field,
    id,
    tieneUrl,
    soloDescarga,
    onChange
}) => {
    tieneUrl = false;
    if (value) {
        tieneUrl = value.trim() !== '';
    }

    const { t } = useTranslation();
    const allowedFileTypes = [
        "text/xml"
    ];

    const uploadXml = (event) => {
        const file = event.target.files[0];
        if (!allowedFileTypes.includes(file.type)) {
            return;
        }

        onChange(file, field);
    };

    return (
        <>
            {soloDescarga ? (
                <>
                    {tieneUrl && (
                        <IconButton title={t('Descargar archivo XML')} variant={'grid'}>
                            <a
                                href={process.env.REACT_APP_API_FILE_URI + value}
                                download={''}
                                target={'_blank'}
                                rel={'noreferrer noopener'}
                            >
                                <SimCardDownloadOutlined color={'info'} />
                            </a>
                        </IconButton>
                    )}
                    {valuePdf && (
                        <IconButton title={t('Descargar archivo PDF')} variant={'grid'}>
                            <a
                                href={process.env.REACT_APP_API_FILE_URI + valuePdf}
                                download={''}
                                target={'_blank'}
                                rel={'noreferrer noopener'}
                            >
                                <PictureAsPdfOutlined color={'error'} />
                            </a>
                        </IconButton>
                    )}
                </>
            ) : (
                <>
                    {tieneUrl ? (
                        <IconButton title={t('Descargar archivo XML')} variant={'grid'}>
                            <a
                                href={process.env.REACT_APP_API_FILE_URI + value}
                                download={''}
                                target={'_blank'}
                                rel={'noreferrer noopener'}
                            >
                                <SimCardDownloadOutlined color={'info'} />
                            </a>
                        </IconButton>
                    ) : (
                        <IconButton title={t('Cargar archivo')} variant={'grid'}>
                            <label htmlFor={'xml'}>
                                <CloudUploadOutlined color={'success'} />
                            </label>
                            <input
                                type="file"
                                onInput={uploadXml}
                                id={'xml'}
                                name={'xml'}
                                accept=".xml"
                                style={{ display: 'none' }}
                            />
                        </IconButton>
                    )}
                    {valuePdf && (
                        <IconButton title={t('Descargar archivo PDF')} variant={'grid'}>
                            <a
                                href={process.env.REACT_APP_API_FILE_URI + valuePdf}
                                download={''}
                                target={'_blank'}
                                rel={'noreferrer noopener'}
                            >
                                <PictureAsPdfOutlined color={'error'} />
                            </a>
                        </IconButton>
                    )}
                </>
            )}
        </>
    );
};

DownloadOrUploadButtonIcon.propTypes = {
    value: PropTypes.string,
    valuePdf: PropTypes.string,
    id: PropTypes.string,
    tieneUrl: PropTypes.bool,
    soloDescarga: PropTypes.bool,
    onChange: PropTypes.func
};

export default DownloadOrUploadButtonIcon;