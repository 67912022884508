/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'
import {RequiredValidator} from "./validators";

const useInputValidation = ({ value, field, required, validators, onValidate, defaultValue }) => {
    const [validationErrors, setValidationErrors] = React.useState(new Map())
    const validate = () => {
        if (!required && (validators ?? []).length === 0) {
            if (onValidate != null) {
                setValidationErrors(new Map())
                onValidate(false, field)
            }
            return
        }

        const requiredValidator = new RequiredValidator(defaultValue)

        const errors = new Map()
        const currentValidators = [...(required ? [requiredValidator] : []), ...(validators ?? [])]
        for (const validator of currentValidators) {
            const result = validator?.validate(value, field) ?? {}
            if (result.error) {
                errors.set(result.errorType, result.message)
            }
        }

        setValidationErrors(errors)
        if (onValidate != null)
            onValidate(errors.size > 0, field)
    }

    React.useEffect(()=>{
        return function cancelValidation() {
            if (onValidate != null)
                onValidate(false, field);
        };
    },[])

    React.useEffect(() => {
        validate()
    }, [value, required])

    return {
        hasValidationError: validationErrors.size > 0,
        validationErrorCss: validationErrors.size > 0 ? 'validation-error' : '',
        errorMessage: () => {
            if (validationErrors.size === 0) { return '' }

            const [message] = validationErrors.values()

            return message
        }
    }
}

export default useInputValidation
