import {IconButton} from "@mui/material";
import {AddCircleOutlined} from "@mui/icons-material";

const AddIconButton = ({onAdd}) => {
    return (<>
        <IconButton onClick={onAdd} size={"small"} color="success">
            <AddCircleOutlined/>
        </IconButton>
    </>)
}
export default AddIconButton;