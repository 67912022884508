import { useSnackbar } from 'notistack'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'

const useNotification = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    return {
        success: (message) => {
            enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 2000 })
        },
        error: (message) => {
            enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 2000 })
        },
        warning: (message) => {
            enqueueSnackbar(message, { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 2000 })
        },
        info: (message) => {
            enqueueSnackbar(message, { variant: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 2000 })
        },
        confirm: (message, { title, confirmText, cancelText } = {}) => {
            return new Promise((resolve) => {
                Swal.fire({
                    title: t(title ?? 'Favor de confirmar.'),
                    text: t(message),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t(confirmText ?? 'Si'),
                    cancelButtonText: t(cancelText ?? 'No'),

                }).then((result) => {
                    resolve(result.isConfirmed)
                })
            })
        },
        alterError: (message, { title, confirmText, cancelText } = {}) => {
            return new Promise((resolve) => {
                Swal.fire({
                    title: t(message),
                    icon: 'error',
                    confirmButtonText: t(confirmText ?? 'Cerrar'),
                }).then((result) => {
                    resolve(result.isConfirmed)
                })
            })
        },
        alterWarning: (message, { title, confirmText} = {}) => {
            return new Promise((resolve) => {
                Swal.fire({
                    title: t(title ?? 'Favor de confirmar.'),
                    text: t(message),
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: t(confirmText ?? 'Ok')
                }).then((result) => {
                    resolve(result.isConfirmed)
                })
            })
        }
    }
}

export default useNotification
