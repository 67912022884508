import {useTranslation} from "react-i18next";
import {Box, IconButton} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PropTypes from "prop-types";
import {DeleteForever} from "@mui/icons-material";

export const ViewDeleteRowIconRenderer=({ data, context, editable})=>{
    const { t } = useTranslation()
    return (
        <Box display={'flex'} justifyContent={'center'} width={'100%'} gap={'10px'}>
            <IconButton title={t('Ver')} onClick={() => context.onEdit(data.id)} variant={'grid'}>
                <MoreHorizIcon/>
            </IconButton>

            <IconButton onClick={()=> context.onDelete(data.id)} size={"small"} color="error">
                <DeleteForever/>
            </IconButton>
        </Box>
    )

}

ViewDeleteRowIconRenderer.propTypes = {
    data: PropTypes.any,
    context: PropTypes.any
}
