import useForm from "../components/hooks/useForm";
import Field from "../components/forms/Field";
import FieldTextBox from "../components/forms/fields/FieldTextBox";
import React, {} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Card, CardActions, CardContent, Link, Typography} from "@mui/material";
import FormGrid from "../components/forms/formGrid/FormGrid";
import useApiSecurity from "../../api/useApiSecurity";
import {useNavigate} from "react-router-dom";
import useNotification from "../components/hooks/useNotification";


const initial ={token:'inicial'}
const FormRegister = () => {

    const api = useApiSecurity()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const notification = useNotification();
    const params = new URLSearchParams(document.location.search);
    const token = params.get("token");

    const {value, onChange, submitted, onValidate, submitValidations} = useForm(initial)

    const onRegister = async () => {
        const valid = submitValidations();
        if (!valid)
            return
        if (value.confirmpassword !== value.password) {
            await notification.alterError(t('La confirmación del password no coincide.')) ;
            return
        }
        try {
            await api.register({...value,token})
            navigate('/Login')
        }
        catch (error)
        {

        }

    }

    return (
        <Box display={'flex'} bgcolor={'blue'} flexGrow={1} height={'100vh'} >
            <Box flexGrow={1} bgcolor={'#0A1E8D'} >
                <Box sx={{
                    position: 'relative',
                    width: '100%',

                    paddingTop: '71.5%',
                    overflow: 'hidden',
                }} >
                    <img
                        src="https://static.vecteezy.com/system/resources/thumbnails/034/883/101/original/abstract-tech-3d-circuit-board-styled-background-with-fast-moving-nodes-streaming-digital-data-this-modern-technology-motion-background-is-a-seamless-loop-free-video.jpg"
                        alt="Car"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </Box>
                <Box  position={"absolute"} top={100} left={100} >

                    <Typography variant="h3" component="div" color={'white'} >
                        {t('Bienvenido al')}
                    </Typography>
                    <Typography variant="h3" component="div" color={'white'} >
                        {t('portal de proveedores')}
                    </Typography>
                    <Typography variant="h3" component="div" color={'white'} >
                        {t(' de DX')}
                    </Typography>
                    <Typography variant="h6" component="div" color={'white'}>
                        {t('En este portal encontrarás todas tus órdenes de compra generadas,')}
                    </Typography>
                    <Typography variant="h6" component="div" color={'white'}>
                        {t('podrás conocer el estatus en que se encuentra y darle seguimiento')}
                    </Typography>
                    <Typography variant="h6" component="div" color={'white'}>
                        {t('en cualquier momento.')}
                    </Typography>
                </Box>
            </Box>

            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} bgcolor={'white'} sx={{maxWidth: 500}}>
                <Box justifyContent="center">
                    <Typography variant="h4" component="div" color={"deepskyblue"} align={"center"} >
                        {t('Registro')}
                    </Typography>
                    <Typography variant="p" component="div" align={"center"}>
                        {t('Ingresa los datos solicitados.')}
                    </Typography>
                </Box>
                <Card sx={{maxWidth: 600,  boxShadow: 0 }} >
                    <CardContent alignContent={"center"}>

                        <FormGrid submitted={submitted} onChange={onChange} onValidate={onValidate} value={value} onSubmit={onRegister} inputProps={{variant:'standard'}}>
                            <Field field={'userName'} label={t('usuario')}  render={FieldTextBox} required={true} />
                            <Field field={'password'} label={t('password')} render={FieldTextBox} required={true} componentProps={{type:'password'}} />
                            <Field field={'confirmpassword'} label={t('repetir password')} render={FieldTextBox} required={true} componentProps={{type:'password'}} />
                            <Field field={'name'} label={t('nombre')} render={FieldTextBox} required={true} />
                            <Field field={'lastName'} label={t('apellido')} render={FieldTextBox} required={true} />
                        </FormGrid>
                    </CardContent>
                    <CardActions sx={{

                        display: 'flex',
                        justifyContent: "center"
                    }}>
                        <Button variant={"contained"} onClick={onRegister} size="large" >{t('Registrar')}</Button>
                    </CardActions>
                </Card>
                <Box justifyContent="center">

                    <Typography variant="p" component="div"  align={"center"}>

                        <Link href="login" underline="none" >
                            Regresar a Login.
                        </Link>
                    </Typography>

                </Box>
                <Box  position="absolute"  bottom={0} display={'flex'} flexDirection={'column'}>
                    <Typography  variant="h7" component="div"  align={"center"}>
                        {t('Powered by Exertus Consulting')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
export default FormRegister;