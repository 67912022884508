// se debe agregar en el index.js las referencia a al i18n
// se debe agregar en el index.html las referencias al fonts google apis para que funcionen los material icons del menú

import './App.css';
import Main from "./common/layout/Main";
import React from 'react'
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import LoadingProvider from "./common/components/LoadingProvider";
import {ThemeProvider} from "@mui/material";
import theme from "./common/themes/theme";
import AuthProvider from "./common/security/AuthProvider";
import LocalizationHandlerProvider from "./common/internalization/LocalizationHandlerProvider";
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-material.css'
import './common/components/agGrid/AgGridCustomization.css'

function App() {
    return (<React.Suspense fallback="loading">
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3}>
                    <LoadingProvider>
                        <AuthProvider>
                            <LocalizationHandlerProvider>
                                <BrowserRouter>
                                    <Main/>
                                </BrowserRouter>
                            </LocalizationHandlerProvider>
                        </AuthProvider>
                    </LoadingProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </React.Suspense>
    )
}


export default App;
