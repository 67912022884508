import {Box} from "@mui/material";
import React from "react";
import {getRender} from "../../utility/formUtility";

const ExColumn = ({field, value, block, title, submitted, disabled, required, headerComponent, headerClass, onAdd, cellParams, ...props}) => {

    const renderHeader = headerComponent?.render != null ?
        getRender(headerComponent.render) :
        null

    // const emptyFunction = useCallback(() => {
    // }, [])

    return (
        <Box display={'flex'} className={`field-grid-header ${headerClass ?? ''}`} fontWeight={'bold'} >
            {renderHeader ?
                renderHeader({
                    value,
                    submitted,
                    disabled,
                    onAdd
                })
                : title
            }
        </Box>
    )
}
export default ExColumn;