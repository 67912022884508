import {useTranslation} from "react-i18next";
import Page from "../../common/layout/Page";

const Home = () => {
    const {t} = useTranslation()
    return (
        <Page>
            <h1>{t('Pagina Inicial')}</h1>
        </Page>
    )
}
export default Home