import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";

const Contador = ({total, titulo, onSelect, selected, width = '140px',
                      height = '100px'}) => {

    const {t} = useTranslation();
    const handleSelect = () => {

        if (onSelect) {
            onSelect();
        }
    }

  return(
      <Box
          display={'flex'}
          flexDirection='column'
          justifyContent={'center'}
          borderRadius={4}
          onClick={handleSelect}
          border={2}
          borderColor={selected ? 'primary.main' : 'darkgray'}
          color={selected ? 'primary.main' : 'darkgray'}
          padding={2}
          marginRight={2}
          boxShadow={3}
          sx={{cursor : 'pointer'}}
          width={'200px'}
          minWidth={250}
      >
          <Box display={'flex'} textAlign={'left'}>
              {t(titulo)}
          </Box>
          <Box display={'flex'} fontWeight={'bold'} fontSize={'1.5em'} textAlign="left">
              {total}
          </Box>
      </Box>
  )
}

export default Contador;