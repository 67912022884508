import React from "react"
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {styled} from "@mui/material/styles";


const FromDatePicker = styled(DatePicker)(({theme}) => ({
    '& fieldset': {
        borderRight: '0',
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0'
    },
}))

const ToDatePicker = styled(DatePicker)(({theme}) => ({
    '& fieldset': {
        borderLeft: '0',
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0'
    },
}))


const FieldDateRangePicker = ({value, onChange, field,label, disabled, submitted, autoOpenTo}) => {
    const { t } = useTranslation()
    const toText=(date)=>{
        if (date == null)
            return null;

        return date.startOf('day').format('YYYY-MM-DD')
    }

    const startChanged = (from) => {
        if (dayjs(from).isSame(getFrom())) {
            return
        }

        onChange([toText(from), toText(getTo())], field)
    }


    const endChanged = (to) => {
        onChange([toText(getFrom()), toText(to)], field)
    }

    const getFrom = () => {
        if (value?.length > 0) { return dayjs(value[0]) }
        return null
    }

    const getTo = () => {
        if (value?.length > 1) { return dayjs(value[1]) }
        return null
    }

    return (<>
        <Box display="flex">
            <FromDatePicker
                disabled={disabled}
                value={getFrom()}
                onChange={startChanged}
                maxDate={getTo()}
                label={ `${label}`}
                clearable
                slotProps={{ textField: { size: 'small' ,error:false}}}
            />
            <ToDatePicker
                disabled={disabled}
                slotProps={{ textField: { size: 'small' ,error:false }}}
                value={getTo()}
                onChange={endChanged}
                minDate={getFrom()}
                label={t('Hasta')}
                clearable
            />
        </Box>


    </>)
}
export default FieldDateRangePicker;