import FormFilter from "../../../common/components/forms/formFilter/FormFilter";
import Field from "../../../common/components/forms/Field";
import Page from "../../../common/layout/Page";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import {AgGridReact} from "ag-grid-react";
import {useCallback, useEffect, useRef, useState} from "react";
import {getAgGridDefault} from "../../../common/components/agGrid/agGridUtility";
import {useTranslation} from "react-i18next";
import AgGridContainer from "../../../common/layout/AgGridContainer";
import useGridFilter from "../../../common/hooks/useGridFilter";
import useApi from "../../../api/useApi";
import {useMenu} from "../../../common/layout/MenuProvider";
import menuOptions from "../../../common/security/menuOptions";
import EstatusProveedorLayoutRender from "./EstatusProveedorLayoutRender";
import AccionProveedorLayoutRender from "./AccionProveedorLayoutRender";
import {estatusProveedor} from "../../Constantes";
import {Box, Button} from "@mui/material";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import useNotification from "../../../common/components/hooks/useNotification";
import ProveedorBloqueo from "./ProveedorBloqueo";
import ProveedorDetalle from "./ProveedorDetalle";
import FieldMultiSelect from "../../../common/components/forms/fields/multiselect/FieldMultiSelect";
import {ViewRowIconRenderer} from "../../../common/components/agGrid/ViewRowIconRenderer";

const Proveedor = () => {
    const {t} = useTranslation()
    const editable = useMenu().isEditable(menuOptions.proveedores);
    const gridRef = useRef();
    const api = useApi('catalogos/Proveedores');
    const notification = useNotification();
    const [defaultFilter] = useState({
    });
    const [proveedoresBloqueadosDialogOpen, setproveedoresBloqueadosDialogOpenDialogOpen] = useState(false);
    const [proveedoresABloquear, setproveedoresABloquear] = useState([])
    const [columnDefs, setColumnDefs] = useState([])
    const {data, onFilter, addEditGridContext, editForm} = useGridFilter({api});
    const isRowSelectable = useCallback((rowNode) => {
        return rowNode.data ? rowNode.data.estatus === estatusProveedor.Activo : false;
    }, []);

    const bloqueoProveedor = async() => {
        if(gridRef.current.api.getSelectedRows().length === 0){
            notification.warning(t('Selecciona al menos un registro'));
            return;
        }
        setproveedoresABloquear(gridRef.current.api.getSelectedRows()?.map(e => e.id));
        setproveedoresBloqueadosDialogOpenDialogOpen(true);
    }

    const closeProveedorBloqueoModal = async () => {
        setproveedoresBloqueadosDialogOpenDialogOpen(false);
        await addEditGridContext.refresh();
    }


    useEffect(() => {
        setColumnDefs([
            {field: 'check', headerName: t(''), minWidth: 50,maxWidth: 50, headerCheckboxSelection: true, checkboxSelection: true},
            {field: 'externalSystemId', headerName: t('ID Proveedor'), minWidth: 100,maxWidth: 120,},
            {field: 'razonSocial', headerName: t('Proveedor'), },
            {field: 'rfc', headerName: t('RFC'), minWidth: 150,maxWidth: 150},
            {field: 'diasCredito', headerName: t('Días de Crédito'), minWidth: 100,maxWidth: 100},
            {
                headerName: t('Estatus'),
                cellRenderer: EstatusProveedorLayoutRender,
                cellRendererParams: {editable, 'enumerador' : 'estatusProveedor'},
                headerComponentParams: {editable},
                maxWidth: 150
            },
            {
                cellRenderer: AccionProveedorLayoutRender,
                cellRendererParams: {editable, 'enumerador' : 'estatusProveedor'},
                headerComponentParams: {editable},
                maxWidth: 210
            },
            {
                field: 'edit', headerName: t('Ver'),
                cellRenderer: ViewRowIconRenderer,
                cellRendererParams: {editable},
                maxWidth: 100
            },

        ])
    }, [editable, t]);

    const onExport=(filtro)=>{
        addEditGridContext.onExport(filtro,'Proveedores');
    }
    return (
        <Page>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap:'10px' }}>
                <Box color={'black'} fontWeight={'bold'} >

                        <Button variant="outlined" color={'info'} component="span" startIcon={<DoDisturbIcon fontSize="small" />} onClick={() => bloqueoProveedor()}>
                            {t('Bloquear')}

                        </Button>
                </Box>
                {/*<Box textAlign={'right'} color={'black'} fontWeight={'bold'}>*/}
                {/*    <Button variant="outlined" color={'inherit'} startIcon={<DownloadIcon fontSize="small" />}>*/}
                {/*         {t('Descargar')}*/}
                {/*    </Button>*/}
                {/*</Box>*/}
            </Box>
            <FormFilter onFilter={onFilter} filterOnInit={true} defaultValue={defaultFilter} onExport={onExport}>
                <Field label={'ID Proveedor'} field={'ExternalSystemId'} render={FieldTextBox} xs={2}/>
                <Field label={'Razón Social'} field={'razonSocial'} render={FieldTextBox} xs={2}/>
                <Field label={'RFC'} field={'rfc'} render={FieldTextBox} xs={2}/>
                <Field label={'Días Crédito'} field={'DiasCredito'} render={FieldTextBox} xs={2}/>
                <Field label={'Estatus'} field={'EstatusIds'} render={FieldMultiSelect} source={'estatusProveedor'} md={2}/>
            </FormFilter>
            <AgGridContainer className="ag-theme-material">
                <AgGridReact
                    {...getAgGridDefault(false)}
                    ref={gridRef}
                    rowData={data}
                    context={addEditGridContext}
                    getRowId={(params) => params.data.id}
                    columnDefs={columnDefs}
                    rowSelection={"multiple"}
                    isRowSelectable={isRowSelectable}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector = {[10, 20, 50, 100]}
                />
            </AgGridContainer>
            <ProveedorBloqueo dataProveedor={proveedoresABloquear} open={proveedoresBloqueadosDialogOpen} onClose={closeProveedorBloqueoModal}></ProveedorBloqueo>
            <ProveedorDetalle editForm={editForm} disabled={!editable} ></ProveedorDetalle>
        </Page>
    )
}
export default Proveedor;
