import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import Field from "../../../common/components/forms/Field";
import FieldDropDown from "../../../common/components/forms/fields/FieldDropDown";
import FieldCheckBox from "../../../common/components/forms/fields/FieldCheckBox";
import FormEditDialog, {
    withEditDialogContext
} from "../../../common/components/forms/formEditDialog/FormEditDialog";
import useApi from "../../../api/useApi";
import ExColumn from "../../../common/components/forms/fields/grid/ExColumn";
import {useTranslation} from "react-i18next";
import {rightAligned} from "../../../common/components/agGrid/agGridUtility";
import DeleteIconButton from "../../../common/components/forms/fields/grid/buttons/DeleteIconButton";
import AddIconButton from "../../../common/components/forms/fields/grid/buttons/AddIconButton";
import FieldExGrid from "../../../common/components/forms/fields/grid/FieldExGrid";
import FieldInputNumber from "../../../common/components/forms/fields/FieldInputNumber";

const FlujoAutorizacionDetalle = ({editForm, disabled}) => {
    const {t} = useTranslation();
    const api = useApi('catalogos/flujoAutorizacion')

    // const {entity} = useDialogForm();

    return (
        <FormEditDialog api={api} title={'Flujo Autorizacion'} {...editForm} disabled={disabled} width={'sm'} hideDelete={true}>
            <Field field="name" label={'Nombre Flujo'} render={FieldTextBox} required={true}/>
            <Field field="montoMinimo" label={'Monto Mínimo'} render={FieldInputNumber} disabled={true} />
            <Field field="montoMaximo" label={'Monto Máximo'} render={FieldInputNumber} required={true}/>
            <FieldExGrid field={'detalle'}>
                <ExColumn field={'perfilId'} title={t('Perfil')} render={FieldDropDown} cellParams={{source:'profiles', required:true}}/>
                <ExColumn render={DeleteIconButton} {...rightAligned} width={'100px'} headerComponent={{render:AddIconButton}} />
            </FieldExGrid>

            <Field field="activo" label={'Activo'} render={FieldCheckBox} visible={false}/>
        </FormEditDialog>
    )

}

export default withEditDialogContext(FlujoAutorizacionDetalle);
