import {Box} from "@mui/material";

const ContenedorContainer = ({children}) => {
  return(
      <Box display={'flex'} justifyContent={'flex-start'} paddingBlock={2}>
          <Box display={'flex'} justifyContent={'flex'} width="100%">
              {children}
          </Box>
      </Box>
  )
}

export default ContenedorContainer;