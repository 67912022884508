/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import 'numeral/locales/es'
import 'numeral/locales/de'
import 'dayjs/locale/es'
import 'dayjs/locale/de'
import useApiSource from "../../api/useApiSource";
import {useAuth} from "../../common/security/AuthProvider";
import useApi from "../../api/useApi";

const applicationContext = React.createContext(undefined)

const useApplicationProvider = () => {
    const [empresas, setEmpresas] = useState([])
    const [empresaActual, setEmpresaActual] = useState({})
    const [actualizandoEmpresa, setActualizandoEmpresa] = useState(false)
    const api = useApiSource()
    const apiSession = useApi('session/empresas');

    const {user,updateToken} = useAuth()

    const cambiarEmpresa = async (empresa)=>{
        setEmpresaActual(empresa)
        setActualizandoEmpresa(true)
        const nuevoToken = await apiSession.update(empresa.id,null,{showLoading:false})
        updateToken(nuevoToken)
        setActualizandoEmpresa(false)
    }
    const load = async ()=>{
        const empresas = await api.get('empresas')
        setEmpresas(empresas)
        const empresa = empresas.find(e=>e.id === user.empresaId)
        setEmpresaActual(empresa)
    }

    useEffect(() => {
        load().then()
    }, []);

    return {
        empresas,
        empresaActual,
        cambiarEmpresa,
        actualizandoEmpresa
    }
}

const ApplicationProvider = ({children}) => {
    const localization = useApplicationProvider()
    return (
        <applicationContext.Provider value={localization}>
            {children}
        </applicationContext.Provider>
    )
}

applicationContext.propTypes = {
    children: PropTypes.any
}

export default ApplicationProvider

export const useApplication = () => {
    return React.useContext(applicationContext)
}
