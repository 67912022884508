import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

const DialogContentComponent = styled(Box)(({theme}) => ({
    border: `1px solid ${theme.borderColor}`,
    // borderWidth: '5px',
    // borderColor: 'darkgray',
    paddingInline: '15px',
    borderRadius: '7px',
    paddingBlock: '15px',
    marginBlock: '10px',
    '& .componentHeader': {
        fontWeight: 'bold',
        marginBottom: '10px'
    },
}))

export default DialogContentComponent;