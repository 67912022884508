import {useCallback, useEffect, useState} from "react";
import Field from "../forms/Field";

/**
 * convierte los elementos recibidos dentro de la forma en form fields
 * @param {children}   son los elementos que están dentro de la forma ejemplo <Form>{children}</Form>
 * @param {fields}   en caso de no tener elementos hijos se pueden utilizar como json ejemeplo <Form fields:[{fiedl:'field1', render:'textbox}] />
 */
const useFormFields = (children, fields) => {

    const [formFields, setFormFields] = useState([])

    const getField = useCallback( ({render, componentProps, ...props})=>{
        return{
            render,
            componentProps,
            ...props
        }
    },[])

    useEffect(() => {
        if (children != null) {
            const newFields = (children.length != null ? children : [children])
                .map(e => (e.type === Field) ?  getField(e.props) : e)
            setFormFields(newFields)
        }
    }, [getField, children]);

    useEffect(() => {
        if (fields !=null){
            setFormFields(fields)
        }
    }, [fields]);


    return {formFields}
}

export default useFormFields