import Field from "./Field";
import useFormFields from "../hooks/useFormFields";

import { FormContext } from './FormContext';
import {useCallback} from "react";

const FormFields = ({
                        fields,
                        value,
                        parentValue,
                        onChange,
                        onValidate,
                        submitted,
                        children
                    }) => {

    const {formFields} = useFormFields(children, fields)

    //todo temporal por debug, quitarlo una vez que esté mas estable
    const handleChange = useCallback((value, field)=>  {
        onChange(value,field)
    },[onChange])

    return (<>
        <FormContext.Provider
            value={{
                value,
                onChange:handleChange,
                onValidate,
                submitted,
                parentValue:parentValue
            }}
        >
        {formFields.map(e =>
            e.render != null ?
                <Field
                    key={e.field}
                    render={e.render}
                    {...e}
                /> :
                e
        )}
        </FormContext.Provider>
    </>)
}
export default FormFields;