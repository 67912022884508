import {Box, Checkbox, Typography} from "@mui/material";
import { styled } from '@mui/material/styles'
import {checkboxState} from "../../Constantes";
import { TreeItem, treeItemClasses, SimpleTreeView } from '@mui/x-tree-view'
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {ArrowDropDown, ArrowRight} from "@mui/icons-material";
import {useEffect, useState} from "react";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        width: '95%',
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)'
        },
        '& .MuiCheckbox-root': {
            padding: '2px'
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit'
        }
    }
}))

function StyledTreeItem (props) {
    const {
        bgColor,
        color,
        menuId,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        submenu,
        value,
        onChange,
        showEditCheck,
        ...other
    } = props

    const accessState = () => {
        const menu = value?.get(menuId)
        return {
            checked: menu?.access === checkboxState.checked,
            indeterminate: menu?.access === checkboxState.indeterminate
        }
    }

    const handleSelect = (event) => {
        const newValue = new Map(value)
        if (event.target.checked) {
            newValue.set(menuId, {
                access: checkboxState.checked
            })
        } else { newValue.delete(menuId) }
        propagateToChildren(newValue)
        onChange(newValue, 'menus')
    }

    const editableState = () => {
        const menu = value?.get(menuId)
        return {
            checked: menu?.editable === checkboxState.checked,
            indeterminate: menu?.editable === checkboxState.indeterminate
        }
    }

    const onChangeEditable = (event) => {
        const newValue = new Map(value)
        newValue.set(menuId, {
            access: checkboxState.checked,
            editable: event.target.checked ? checkboxState.checked : null
        })
        propagateToChildren(newValue)
        onChange(newValue,'menus')
    }

    const propagateToChildren = (mapValues) => {
        const state = mapValues.get(menuId)

        for (const item of submenu) {
            if (state != null) { mapValues.set(item.id, { ...state }) } else { mapValues.delete(item.id) }
        }
    }

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    <Checkbox size={'small'} {...accessState()} onChange={handleSelect}
                              disabled={other.disabled}></Checkbox>
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    {showEditCheck&&<Checkbox size={'small'} {...editableState()} onChange={onChangeEditable}
                                              disabled={other.readOnly}></Checkbox>}
                </Box>
            }
            {...other}
        >
            {submenu?.map((item) => (
                <StyledTreeItem key={item.id}
                                menuId={item.id}
                                nodeId={item.id.toString()}
                                labelText={item.name}
                                submenu={item.children}
                                value={value}
                                onChange={onChange}
                                readOnly={other.readOnly}
                                itemId={item.id}
                />
            ))}
        </StyledTreeItemRoot>
    )
}

const FormMenu = ({ value, parent, menu, onChange, readOnly, showEditCheck=true }) => {
    const { t } = useTranslation()

    const [expanded, setExpanded] = useState([1])
    const [states, setStates] = useState(new Map())

    const handleToggle = (event, nodeIds) => {
        event.persist()
        const iconClicked = event.target.closest('.MuiTreeItem-iconContainer')
        if (iconClicked) {
            setExpanded(nodeIds)
        }

    }

    useEffect(() => {
        if (value != null) {
            checkIndeterminated()
        }

        // eslint-disable-next-line
    }, [value])

    const checkIndeterminated = () => {
        for (const node of menu) {
            setNodeState(node)
        }
        setStates(new Map(value))
    }

    const setNodeState = (node) => {
        if (node.children.length === 0) { return }

        const access = { count: 0, indeterminate: false }
        const editable = { count: 0, indeterminate: false }

        for (const child of node.children) {
            if (child.children.length > 0) {
                setNodeState(child)
            }

            const childState = value.get(child.id);
            //const childState = value.find(a=> a.id===child.id);

            if (childState != null) {
                access.count += (childState.access === checkboxState.checked ? 1 : 0)
                access.indeterminate = access.indeterminate || childState.access === checkboxState.indeterminate
                editable.count += (childState.editable === checkboxState.checked ? 1 : 0)
                editable.indeterminate = editable.indeterminate || childState.editable === checkboxState.indeterminate
            }
        }

        value.set(node.id, {
            access: getState(access, node.children.length),
            editable: getState(editable, node.children.length)
        })
    }

    const getState = (params, childCount) => {
        if (params.indeterminate === true) { return checkboxState.indeterminate }

        if (params.count === 0) { return null }

        if (params.count === childCount) { return checkboxState.checked }

        return checkboxState.indeterminate
    }

    return (
        <>
            <Box display={'flex'} width={'91%'} pl={5}>
                {showEditCheck&&<Box flexGrow={1}>{t('Visualización')}</Box>}
                {showEditCheck&&<span>{t('Edición')}</span>}
            </Box>
            <SimpleTreeView
                aria-label="gmail"
                expandedItems={expanded}
                // onNodeToggle={handleToggle}
                disableSelection={false}
                defaultCollapseIcon={<ArrowDropDown/>}
                defaultExpandIcon={<ArrowRight/>}
                onExpandedItemsChange={(event,itemId)=>{handleToggle(event,itemId)}}
                defaultEndIcon={<div style={{ width: 24 }}/>}
                sx={{ flexGrow: 1, overflowY: 'auto', width: '100%' }}
            >
                {menu.map((item, index) => (
                    <StyledTreeItem
                        key={item.id}
                        menuId={item.id}
                        nodeId={item.id.toString()}
                        labelText={item.name}
                        submenu={item.children}
                        value={states}
                        onChange={onChange}
                        readOnly={readOnly}
                        showEditCheck={showEditCheck}
                        itemId={item.id}
                    />
                ))}
            </SimpleTreeView>
        </>
    )
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.any,
    color: PropTypes.any,
    labelIcon: PropTypes.any,
    labelInfo: PropTypes.any,
    labelText: PropTypes.any,
    menuId: PropTypes.any,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    submenu: PropTypes.shape({
        map: PropTypes.func
    }),
    value: PropTypes.shape({
        get: PropTypes.func
    })
}

FormMenu.propTypes = {
    menu: PropTypes.shape({
        map: PropTypes.func
    }),
    onChange: PropTypes.any,
    parent: PropTypes.any,
    value: PropTypes.shape({
        get: PropTypes.func,
        set: PropTypes.func
    })
}

const component = (props) => <FormMenu {...props}/>
export default component

