import React from 'react'
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types'
import * as numeral from 'numeral'

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom (props, ref) {
    const { onChange, precision, thousandSeparator, max, min, ...other } = props
    const delimiters = numeral.localeData()?.delimiters ?? {
        thousands: thousandSeparator ? ',' : '',
        decimal: '.'
    }

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            isAllowed={(values) => {
                const { floatValue } = values
                if (floatValue == null) { return true }

                return (min == null || floatValue >= min) &&
                    (max == null || floatValue <= max)
            }}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                })
            }}
            thousandSeparator={delimiters.thousands}
            decimalSeparator={delimiters.decimal}
            isNumericString
            decimalScale={props.precision}
        />
    )
})

export default NumberFormatCustom

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    precision: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    thousandSeparator: PropTypes.any,
    max: PropTypes.number,
    min: PropTypes.number
}
