import useServiceFactory from "./hooks/useServiceFactory";
import {useCallback, useState} from "react";
const useApi = (api) => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URI })
    const [result] = useState({
        save: async (params, config) => service.post(`/${api}`, params, config),
        update: async (id,params,config) => service.put(`/${api}/${id}`, params, config),
        delete: async (id,config) => service.delete(`/${api}/${id}`, config),
        filter: useCallback(async (params, config) => service.get(`/${api}`, {params,showLoading:true,  ...config}),[api, service]) ,
        getbyId: async (id, config) => service.get(`/${api}/${id}`, config),
        get: async (url, params, config) => service.get(`/${api}/${url}`, {params,showLoading:true, ...config}),
        post: async (url, params, config) => service.post(`/${api}/${url}`, params, config),
        put: async (url, id, params, config) => service.put(`/${api}/${url}/${id}`, params, config),
        menus: async () => service.get(`${api}/tree`, {}),
        excel: async (params, fileName) => service.get(`${api}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `${fileName}.xlsx`
            }),
        download: async (url, params, fileName, extension) => service.get(`${api}/${url}`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `${fileName}.${extension}`
            })
    })

    return result;
}

export default useApi
