import {
    Routes,
    Route
} from 'react-router-dom'
import Login from "../../components/security/Login";
import Registro from "../../components/security/Registro";
import Recupera from "../../components/security/Recupera";
import CambiarPassword from "../../components/security/CambiarPassword";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path={"registro"} exact={true} element={<Registro/>}/>
            <Route path={"recuperar"} exact={true} element={<Recupera/>}/>
            <Route path={"recuperarPassword"} exact={true} element={<CambiarPassword/>}/>
            <Route path={"*"} exact={false} element={<Login/>}/>

        </Routes>
    )
}
export default AppRoutes
