import {Box, Alert, AlertTitle, Badge, Divider, Drawer, Snackbar, Typography} from "@mui/material";

import {SignalRContext} from "../../../common/layout/header/Header";
import {useEffect, useState} from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import useApi from "../../../api/useApi";
import {utcToString} from "../../formatters/format"
import DeleteIconButton from "../../../common/components/forms/fields/grid/buttons/DeleteIconButton";
const UserNotifications = (user) => {

    const [open, setOpen] = useState(false);
    const [notificacionOpen, setNotificacionOpen] = useState(false);
    const [notificaciones, setNotificaciones] = useState([]);
    const api = useApi('configuracion/notificaciones');
    const [cantidadNotificaciones, setCantidadNotificaciones] = useState();
    const [notificacion, setNotificacion] = useState({});

    const obtenerNotificaciones = async () => {
        const notificaciones = await api.filter({});
        setNotificaciones(notificaciones);
        setCantidadNotificaciones(notificaciones.length);
    };

    useEffect(() => {
        obtenerNotificaciones();
// eslint-disable-next-line
    }, [])



    SignalRContext.useSignalREffect(
        "notificacion", // Your Event Key
        (notificacion) => {
            if(user.user.userName === notificacion.userName)
            {
                setOpen(false);
                setTimeout(() => {
                    setNotificacion(notificacion);
                    let cantidad = cantidadNotificaciones + 1
                    setCantidadNotificaciones(cantidad);
                    //setNotificaciones(notificaciones => [...notificaciones, notificacion]);
                    setNotificaciones([notificacion, ...notificaciones]);

                    setOpen(true);

                }, 500)
            }

        }
    );

    SignalRContext.useSignalREffect(
        "notificacionTest", // Your Event Key
        (notificacion) => {
            setOpen(false);
            setTimeout(() => {
                setOpen(true);
            }, 500)

        }
    );


    const handleClose = () => {
        setOpen(false);
    };

    const consultaNotificaciones = async () => {
        setNotificacionOpen(!notificacionOpen);
       // obtenerNotificaciones();
    }

    const borrarNotificacion = async (item) => {
        let notificacionesNuevas = notificaciones.filter(a => a.id !== item.id);
        setNotificaciones(notificacionesNuevas);

        let params = {notificacionId: item.id };
        await api.post('marcarComoLeidas', params, {showLoading: false, showSuccess: false})
        let cantidad = notificacionesNuevas.length ;

        setCantidadNotificaciones(cantidad);
    }




    return (<>

        <Badge badgeContent={cantidadNotificaciones} color="success">
            <NotificationsIcon onClick={consultaNotificaciones} style={{'cursor': 'pointer'}}>

            </NotificationsIcon>
        </Badge>


        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Note archived"
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}

        >
            <Alert
                onClose={handleClose}
                severity="info"
                variant="filled"
                sx={{width: '100%'}}
            >
                <AlertTitle>Notificación</AlertTitle>
                <b>{notificacion.tipoNotificacion}</b>
                <br/>
                {notificacion.mensaje}


            </Alert>
        </Snackbar>

        <Drawer open={notificacionOpen} anchor='right' hideBackdrop={true}
                sx={{
                    '&.MuiDrawer-root .MuiDrawer-paper': {top: '63px'}
                }}
        >

            <Divider/>
            <Box display={'flex'} width={'25vw'} flexDirection={'column'}>
                {notificaciones.length===0 &&
                    <Box display={'flex'} sx={{justifyContent: 'flex-center', flexGrow: 1}} flexDirection={'row'}>
                        <Typography sx={{p: 1, flexGrow: 1, justifyContent: 'center'}}>
                            <b>No tiene notificaciones</b>
                        </Typography>
                    </Box>


                }

                {notificaciones.map((notificacion) => (
                    <>

                        <Box key={notificacion.id} display={'flex'} flexDirection={'column'}
                             sx={{borderLeft: '5px solid green', boxShadow: '5px 5px 5px silver'}}>
                            <Box display={'flex'} flexDirection={'row'} sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{p: 0.5, flexGrow: 1}}>
                                    <b>{notificacion.tipoNotificacion}</b>
                                </Typography>
                                <Typography sx={{p: 0.5, flexGrow: 1, color: 'silver', fontSize: '12px'}}>
                                    {utcToString(notificacion.fecha, 'DD/MM/YYYY HH:mm')}
                                </Typography>
                                <DeleteIconButton onDelete={(item) => borrarNotificacion(notificacion)}>

                                </DeleteIconButton>
                            </Box>

                            <Box display={'flex'} flexDirection={'row'}>
                                <Typography sx={{p: 0.5, fontSize: '15px'}}>
                                    {notificacion.mensaje}
                                </Typography>

                            </Box>

                        </Box>
                        <Box sx={{height: '10px'}}></Box>

                    </>


                ))}
            </Box>


        </Drawer>
    </>)
}

export default UserNotifications;
