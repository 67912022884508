import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

const Page = styled(Box)(({theme}) => ({
    display:'flex',
    flexGrow: 1,
    flexDirection: 'column',
    position: 'relative'
}))

export default Page;