import i18next from "i18next";
import dayjs from "dayjs";
import {currencyFormat, numericFormat} from "./format";

export const dateTimeFormatter = ({value}) => {
    return value != null ? dayjs(value).format('LT') : ''
}

export const dateFormatter =({value}) => {
    return value != null ? dayjs(value).format('L') : ''
}
export const isoDateFormatter =({value}) => {
    return value != null ? dayjs(value).format('YYYY-MM-DD') : ''
}
export  const numericFormatter =({value, precision}) => {
    return numericFormat(value, precision)
}
export  const currencyFormatter =({value, precision, currencySymbol}) => {
    return currencyFormat(value, precision, currencySymbol)
}
export  const enumFormatter =({value, colDef}) => {
    return value == null ? '' : i18next.t(`enums.${colDef.enum}.${value}`);
}
