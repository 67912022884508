/* eslint-disable react-hooks/exhaustive-deps */
import {Button, MenuItem} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";
import {useState} from "react";
import {styled} from "@mui/material/styles";
import {blue} from "@mui/material/colors";
import {StyledMenu} from "../menus/StyledMenu";
import {useApplication} from "../../../components/home/ApplicationProvider";

const EmpresasButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(blue[500]),
}));

const Empresas = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const {empresas, empresaActual, cambiarEmpresa} = useApplication()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onSeleccionarEmpresa = (empresa) => {
        cambiarEmpresa(empresa)
        setAnchorEl(null)
    };

    return (
        <>
            <EmpresasButton
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                color="success"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}
            >
                {empresaActual?.name}
            </EmpresasButton>
            <StyledMenu
                id="demoempresasMenus"
                anchorEl={anchorEl}
                open={open}
                onClose={()=>setAnchorEl(null)}
            >
                {empresas.map((item, index) =>
                    <MenuItem key={item.id} onClick={()=>onSeleccionarEmpresa(item)} disableRipple>
                        {item.name}
                    </MenuItem>
                )}
            </StyledMenu>
        </>
    )
}
export default Empresas;