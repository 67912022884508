import {useTranslation} from "react-i18next";
import useApi from "../../../api/useApi";
import {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Box, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentComponent from "../../../common/components/Shared/DialogContentComponent";
import {utcToString} from "../../../common/formatters/format"
const AutorizadoresEnCurso = ({open, id, onClose}) => {
    const {t} = useTranslation();
    const api = useApi('configuracion/recepciones/flujoAutorizacion');
    const [data, setData] = useState([]);

    useEffect(() => {
        if(open){
            load(id).then()}
        // eslint-disable-next-line
    }, [id, open]);

    const load = async (id) => {
        const response = await api.getbyId(id);
        setData(response);
    }

    return(
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'md'}>
            <DialogTitle marginInline={4} marginTop={2} fontWeight={'bold'}>
                {t('Flujo Autorización')}
                <IconButton sx={{position: 'absolute', right: 8, top: 8}} onClick={onClose}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{marginInline: 4}}>
                <DialogContentComponent>
                    <Box>
                        {data?.length > 0 ?
                            data?.map((row, index) =>
                                <Box display={'flex'} gap={'10px'} id={index} key={index}
                                     color={row.siguienteAprobador ? 'primary.main' : ''}
                                >
                                    <label>{t(`${row.orden}:`)}</label>
                                    <label style={{fontWeight: 'bold'}}>{row.autorizador}</label>
                                    <label>
                                        {(row.fechaAutorizacion=== null && row.aprobadoPorSuperAdmin) ? row.usuarioSuperAdmin :
                                            ((row.fechaAutorizacion=== null)?t('Pendiente'):  utcToString(row.fechaAutorizacion,'DD/MM/YYYY HH:mm') )

                                        }

                                    </label>
                                </Box>
                            )
                            :
                            <Box textAlign={'center'} color={'darkgray'}>
                                {t('No existen movimientos.')}
                            </Box>
                        }
                    </Box>
                </DialogContentComponent>
            </DialogContent>
        </Dialog>
    )
}

export default AutorizadoresEnCurso;
