import React from 'react'


export default function useSessionState (key, defaultValue) {
    const [state, setState] = React.useState(() => {
        if (key == null)
            return  defaultValue;

        const persistedState = sessionStorage.getItem(key)

        if (typeof defaultValue === 'object') {
            return { ...defaultValue, ...(persistedState ? JSON.parse(persistedState) : {}) }
        }

        return persistedState ?? defaultValue
    })
    React.useEffect(() => {
        if (key == null)
            return;

        if(typeof state === 'object' || Array.isArray(state)) {
            window.sessionStorage.setItem(key, JSON.stringify(state))
        }
        else {
            window.sessionStorage.setItem(key, state)
        }
    }, [state, key])
    return [state, setState]
}
