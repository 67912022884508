import {createContext, useContext, useState} from "react";


const PruebaContext = createContext({});

export const usePruebaProvider = ()=>{
    const [prueba, setPrueba] = useState('prueba')

    return  {
        prueba,
        update: (v)=> setPrueba(v)
    };
}


export const PruebaProvider = ({ children }) => {
    const value = usePruebaProvider();

    return (
        <PruebaContext.Provider value={value}>{children}</PruebaContext.Provider>
    );
};

export const usePrueba = () => {
    return useContext(PruebaContext)
}
