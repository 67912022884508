import useFormValidation from "../../../hooks/useFormValidation";
import useChangeFieldHandler from "../../../hooks/useChangeFieldHandler";
import AccordionDetails from "@mui/material/AccordionDetails";
import * as React from "react";
import FormFields from "../../FormFields";
import {useCallback} from "react";

const FormAccordionPanel = ({
                                  parentValue,
                                  field,
                                  value,
                                  onChange,
                                  onValidate,
                                  submitted,
                                  children,
                                  fields
                              }) => {

    const {onValidateField} = useFormValidation(onValidate, field)

    const {onChangeField} = useChangeFieldHandler(value, onChange, field)

    //todo temporal por debug, quitarlo una vez que esté mas estable
    const handleChangeField = useCallback((value, field)=>{
        onChangeField(value, field)
    },[onChangeField])

    return (
        <AccordionDetails>
            <FormFields
                value={value}
                onChange={handleChangeField}
                onValidate={onValidateField}
                submitted={submitted}
                parentValue={parentValue}
                fields={fields}
                children={children}
            />
        </AccordionDetails>
    );
}

FormAccordionPanel.propTypes = {};

export default FormAccordionPanel;
