import PropTypes from 'prop-types'
import { Autocomplete, TextField } from '@mui/material'
import useInputValidation from "../validations/useInputValidation";
import {dropDownIdEmpty, sourceLabelName} from "./fieldConstants";
import useSourceUtils from "../../hooks/useSouceUtils";

const FieldDropDown = ({
                          value, onChange, parent, field, label, options, source, disabled,  required,
                          submitted, className, orderBy, validators,
                          params, onValidate, width, valueById
                      }) => {
    valueById = valueById ?? true
    disabled = disabled ?? false

    const {
        currentOptions,
        setSelectedId,
        getSelected
    } = useSourceUtils(value, options, source, params, false, valueById, orderBy)

    const { hasValidationError, errorMessage } = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent,
        defaultValue: dropDownIdEmpty
    })

    const onDropChange = (event, value) => {
        const ids = [value?.id??dropDownIdEmpty]
        setSelectedId(ids)
        const newValue =valueById ? value?.id : value;
        onChange(newValue, field, value)
    }

    const isOptionEqualToValue = (option, value)=>{
        return  (value === dropDownIdEmpty || value.id === dropDownIdEmpty) || option.id === value.id;
    }

    return (
        <Autocomplete
            style={{ width: width ?? '100%' }}
            value={getSelected() ?? { id: dropDownIdEmpty, [sourceLabelName]: '' }}
            options={currentOptions}
            disabled={disabled}
            getOptionLabel={(option) => (option ?? {})[sourceLabelName] ?? ''}
            onChange={onDropChange}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={(autoCompleteParams) => <TextField
                {...autoCompleteParams}
                disabled={disabled}
                helperText={submitted ? errorMessage() : ''}
                error={submitted && hasValidationError}
                label={label}
                variant="outlined"
                className={className}
                size="small"
            />}
        />

    )
}

FieldDropDown.propTypes = {
    className: PropTypes.any,
    disabled: PropTypes.any,
    field: PropTypes.any,
    onChange: PropTypes.func,
    onValidate: PropTypes.any,
    options: PropTypes.any,
    orderBy: PropTypes.any,
    params: PropTypes.any,
    parent: PropTypes.any,
    required: PropTypes.any,
    source: PropTypes.any,
    submitted: PropTypes.any,
    validators: PropTypes.any,
    value: PropTypes.any,
    valueById: PropTypes.bool,
    width: PropTypes.any,
    label: PropTypes.string
}

export default FieldDropDown
