import FormEditDialog, {
    withEditDialogContext
} from "../../../common/components/forms/formEditDialog/FormEditDialog";
import Field from "../../../common/components/forms/Field";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import useApi from "../../../api/useApi";
import FieldCheckBox from "../../../common/components/forms/fields/FieldCheckBox";

const VideoDetalle = ({id, onCancel,onSaved}) => {

    const api = useApi('catalogos/faq')

    return (
        <FormEditDialog api={api} title={'Video'}   hideDelete={true} id={id} onCancel={onCancel} onSaved={onSaved}>
            <Field field="id" label={'Faq ID'} render={FieldTextBox} visible={false}  />
            <Field field="titulo" label={'Titulo'} render={FieldTextBox} required={true} maxLength={200} />
            <Field field="link" label={'Link'} render={FieldTextBox} required={true} maxLength={200}  />
            <Field field="activo" label={'Activo'} render={FieldCheckBox} />
        </FormEditDialog>
    )
}
export default withEditDialogContext(VideoDetalle)