
import Page from "../../../common/layout/Page";
import Box from '@mui/material/Box';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";
import useApi from "../../../api/useApi";
import ItemVideo from "./ItemVideo";
import ListaFaq from "./ListaFaq";
import {useEffect, useState} from "react";
import ContenedorHorizontal from "./ContenedorHorizontal";
import VideoDetalle from "./VideoDetalle";
import FaqDetalle from "./FaqDetalle";
import {useMenu} from "../../../common/layout/MenuProvider";
import {menus} from "../../Constantes";
import {Button} from "@mui/material";
const PreguntasFrecuentes=()=>{
    const {t} = useTranslation();
    const api = useApi('catalogos');
    const [data, setData] = useState([]);
    const [idActual, setIdActual] = useState();
    const [idActualFaq, setIdActualFaq] = useState();

    const {isEditable} = useMenu()
    const editable = isEditable(menus.FAQ)

    const load = async () => {
        const response = await api.get('faq', { },{showSuccess:false});
        setData(response);
    }

    const saveVideo=()=>{
        setIdActual(null);
        load();
    }

    const saveFaq=()=>{
        setIdActualFaq(null);
        load();
    }

    useEffect(() => {
        load();
        // eslint-disable-next-line
    },[] );

    return (
        <Page>
            {
            editable &&
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap:'10px' }}>
                        <Box color={'black'} fontWeight={'bold'} >
                            <Button variant="outlined" color={'success'} component="span" startIcon={<AddIcon fontSize="small" />} onClick={()=>setIdActual(0)} >
                                {t('Agregar video')}
                            </Button>
                        </Box>
                    </Box>
            }
            <h3>{t('Preguntas frecuentes')}</h3>
            <h4>{t('Videos')}</h4>
            <Box style={{maxHeight: 300, overflowX: 'auto'}}>
                <ContenedorHorizontal>
                    {data.map(e =>{
                        return e.activo && e.esVideo &&
                            <ItemVideo
                                key={e.id}
                                titulo={e.titulo}
                                link={e.link}
                                id={e.id}
                                onEdit={(id)=>setIdActual(id)}
                                editable={editable}
                            />
                            }
                    )}
                </ContenedorHorizontal>
            </Box>
            {
                editable &&
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap:'10px' }}>
                            <Box color={'black'} fontWeight={'bold'} >
                                <Button variant="outlined" color={'success'} component="span" startIcon={<AddIcon fontSize="small" />} onClick={()=>setIdActualFaq(0)}>
                                    {t('Agregar FAQ')}
                                </Button>
                            </Box>
                        </Box>
            }
            <h4>{t('FAQ')}</h4>
            <ListaFaq data={data} onSaved={saveFaq} editable={editable}></ListaFaq>
            <VideoDetalle id={idActual} onCancel={()=>setIdActual(null)} onSaved={saveVideo} ></VideoDetalle>
            <FaqDetalle  id={idActualFaq} onCancel={()=>setIdActualFaq(null)} onSaved={saveFaq} ></FaqDetalle>
        </Page>
    )
}

export default PreguntasFrecuentes;