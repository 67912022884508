import {useCallback} from "react";

const useChangeFieldHandler = (value, onChange, field) => {

    const onChangeField = useCallback ((newFieldValue, fieldName) => {
        const newValue = fieldName != null ?
            {...value, [fieldName]: newFieldValue} :
            {...value, ...newFieldValue} ;
        onChange(newValue, field)
    },[field, value,onChange])

    return {
        onChangeField
    }
}

export default useChangeFieldHandler