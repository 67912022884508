/* eslint-disable react-hooks/exhaustive-deps */
import useFormFields from "../../hooks/useFormFields";
import useChangeFieldHandler from "../../hooks/useChangeFieldHandler";
import {FormContext} from "../FormContext";
import Grid2 from "@mui/material/Unstable_Grid2";
import Field from "../Field";
import {Box, IconButton} from "@mui/material";
import {FilterAltOff, Search} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {getIsVisible} from "../utility/formUtility";
import useSessionState from "../../../hooks/useSessionState";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const FormFilter = ({defaultValue, filterOnInit, fields, children, spacing, onFilter, otherFilters, sessionSorageKey, onExport }) => {
    const {formFields} = useFormFields(children, fields);
    const {t} =useTranslation();
    const [value, setValue] = useSessionState(sessionSorageKey,defaultValue)
    const {onChangeField} = useChangeFieldHandler(value, setValue)

    useEffect(()=>{
        if (filterOnInit)
            onFilter({...value, ...otherFilters})
    },[filterOnInit, onFilter])

    const onKeyDown = (e)=>{
        if (e.code === 'Enter')
            onFilter({...value,...otherFilters})
    }

    const handleFilter = ()=>{
        onFilter({...value,...otherFilters})
    }

    const handleResetFilter = ()=>{
        setValue(defaultValue)
        onFilter(defaultValue)
    }
    const handleExport=()=>{
        onExport(value);
    }

    return (

        <FormContext.Provider
            value={{
                value,
                onChange: onChangeField,
                onValidateField: () => {
                },
                submitted: false,
                parentValue: value
            }}
        >
            <Box display={'flex'} width={'100%'} onKeyDown={onKeyDown}>
                <Grid2 container spacing={spacing ?? 2} flexGrow={1} >
                    {formFields && formFields.filter(e=>getIsVisible(e.visible, null)).map((item, index) =>
                        <Grid2 key={item.field}
                               xs={item.xs ?? 12}
                               sm={item.sm}
                               md={item.md}
                               lg={item.lg}
                               xl={item.xl}
                        >
                            {item.render != null ?
                                <Field
                                    key={item.field}
                                    render={item.render}
                                    {...item}
                                /> :
                                item}
                        </Grid2>
                    )}
                </Grid2>
                <Box width={'120px'} display={'flex'} alignItems={'flex-start'}>
                    <IconButton title={t('Filtrar')} aria-label={t('Filtrar')} onClick={handleFilter}>
                        <Search />
                    </IconButton>
                    <IconButton title={t('Limpiar Filtros')} aria-label={t('Limpiar Filtros')} onClick={handleResetFilter}>
                        <FilterAltOff/>
                    </IconButton>
                    <IconButton title={t('Exportar a Excel')} aria-label={t('Exportar a Excel')} onClick={handleExport}>
                        <FontAwesomeIcon icon={faDownload}/>
                    </IconButton>
                </Box>
            </Box>
        </FormContext.Provider>
    )
}
export default FormFilter;
