import PruebaState from "./pruebas/PruebaState";
import FieldTextBox from "./forms/fields/FieldTextBox";
import FormBox from "./forms/fields/FormBox";
import FormTabs from "./forms/fields/tabs/FormTabs";
import FormAccordions from "./forms/fields/accordions/FormAccordions";
import FieldDropDown from "./forms/fields/FieldDropDown";
import FieldMultiSelect from "./forms/fields/multiselect/FieldMultiSelect";
import FieldCalendar from "./forms/fields/FieldCalendar";
import FieldDateRangePicker from "./forms/fields/dateRangePicker/FieldDateRangePicker";
import ExGrid from "./forms/fields/grid/ExGrid";
import AddIconButton from "./forms/fields/grid/buttons/AddIconButton";
import DeleteIconButton from "./forms/fields/grid/buttons/DeleteIconButton";

export const allComponents = {
    pruebaState: PruebaState,
    textBox: FieldTextBox,
    box:FormBox,
    tab: FormTabs,
    accordion: FormAccordions,
    dropDown: FieldDropDown,
    multiselect: FieldMultiSelect,
    calendar: FieldCalendar,
    dateRangePicker: FieldDateRangePicker,
    grid: ExGrid,
    addIconButton: AddIconButton,
    deleteIconButton: DeleteIconButton
};
