import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import * as React from "react";
const ItemVideo = ({titulo, link,id,onEdit, editable}) => {
    const {t} = useTranslation();
    const onLink= () => {
        window.open(
            link, "_blank");
    }
    const handlEdit= () => {
        onEdit(id);
        //setOpenDialog(id);
    }

    return(
            <Card sx={{ minWidth:200,maxWidth:200}}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="p" sx={{textOverflow:"ellipsis",overflow: "hidden", whiteSpace: "nowrap",maxWidth: 200}}>
                            {t(titulo)}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div" sx={{textOverflow:"ellipsis",overflow: "hidden", whiteSpace: "nowrap",maxWidth: 100}}>
                            {t('video')}
                        </Typography>
                    </CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 8, pb: 1 }} alignItems={"center"}>
                        <IconButton aria-label="play/pause" onClick={onLink}>
                            <PlayArrowIcon sx={{ height: 45, width: 45 }} />
                        </IconButton>
                    </Box>
                    {
                        editable &&
                                <Button variant="outlined" color={'info'} component="span"  onClick={handlEdit} >
                                    {t('Editar')}
                                </Button>
                    }
                </Box>
            </Card>
    )
}

export default ItemVideo;