import DialogTitle from "@mui/material/DialogTitle";
import {Box, Button, IconButton} from "@mui/material";
import {CheckCircleOutlined, Close} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentComponent from "../../../common/components/Shared/DialogContentComponent";
import Generales from "../../../common/components/Shared/Generales";
import useApi from "../../../api/useApi";
import {useAuth} from "../../../common/security/AuthProvider";

const ComunicadoUsuario = ({data,onClose, openComunicado}) => {
    const {t} = useTranslation();
    const open = openComunicado;
    const comunicadoId=data.id;
    const apiComunicado = useApi('catalogos/comunicado');
    const auth = useAuth();

    useEffect(() => {
        // eslint-disable-next-line
    }, [open]);


    const close = () => {
        onClose();
    }

    const closeModal=()=>{
        //save
        apiComunicado.post('confirmacion',{comunicadoId:comunicadoId,userId: auth?.user?.id})
        onClose();
    }

    return(
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}>
                <DialogTitle marginInline={4} marginTop={2} fontWeight={'bold'}>
                    <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                        <Box sx={{gridRow: "1", gridColumn: "1/9"}}>
                            {data.titulo}
                        </Box>
                    </Box>

                    <IconButton sx={{position: 'absolute', right: 8, top: 8}} onClick={() => close()}>
                        <Close/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{marginInline: 4}}>
                    <DialogContentComponent>
                        <Generales >
                            {data.mensaje}
                        </Generales>
                    </DialogContentComponent>
                    <DialogActions>
                        <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%", gap: "10px"}}>
                            <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "8/9"}} >
                                <Button  variant="outlined" color={'inherit'} startIcon={<Close/>}
                                    //disabled={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                         onClick={() => close()}
                                >
                                    {t('CACELAR')}
                                </Button>
                            </Box>
                            <Box textAlign={'right'} color={'black'} fontWeight={'bold'}
                                 sx={{gridRow: "1", gridColumn: "9/10"}}>
                                <Button  variant="outlined" color={'success'} startIcon={<CheckCircleOutlined/>}
                                    //disabled={!(data.estatusId === estatusProcesoFacturacion.PendienteCargaFactura)}
                                         onClick={() => closeModal()}
                                >
                                    {t('Confirmar')}
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ComunicadoUsuario;