import PropTypes from 'prop-types'
import { useAuth } from './AuthProvider'
import {
    Navigate,
    Outlet
} from 'react-router-dom'

const GuardRoute = ({ allowAnonymous, children }) => {
    const auth = useAuth()
    return (auth?.isAuthenticated() || auth?.loggedOut) ? <Outlet /> : <Navigate to="/login" />
}

GuardRoute.propTypes = {
    allowAnonymous: PropTypes.any,
    children: PropTypes.any
}
export default GuardRoute
