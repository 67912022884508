import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel } from '@mui/material'

const FormCheckBox = ({ value, onChange, label, field, disabled, labelPlacement = "start" }) => {
    disabled = disabled ?? false
    const handleChange = (event) => {
        if (disabled) { return }
        onChange(event.target.checked, field)
    }

    return (
        <>
            <FormControlLabel
                control={<Checkbox name={field} onChange={handleChange} checked={value ?? false}/>}
                label={label}
                labelPlacement={labelPlacement}
            />
        </>
    )
}

FormCheckBox.propTypes = {
    disabled: PropTypes.any,
    field: PropTypes.any,
    formDisabled: PropTypes.any,
    onChange: PropTypes.func,
    placeholder: PropTypes.any,
    value: PropTypes.bool
}

const component = (props) => <FormCheckBox {...props}/>
export default component
