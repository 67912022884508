import { Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
const ItemFaq = ({pregunta,respuesta,id, onEdit, editable}) => {
    const {t} = useTranslation();

    const handleEdit= () => {
        onEdit(id);
    }

    return(
        <Accordion >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header" >
                {pregunta}
                {
                    editable &&
                            <Button variant="outlined" color={'info'} component="span"  onClick={handleEdit}  >
                                {t('Editar')}
                            </Button>
                }
            </AccordionSummary>
            <AccordionDetails>
                {respuesta}
            </AccordionDetails>
        </Accordion>
    )
}

export default ItemFaq;