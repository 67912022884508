import {addDaysIsoString, getTodayIsoString} from "../common/utility/dateUtility";

export const menus = {
    Configuracion: 1,
    Proveedores: 10,
    Perfiles: 20,
    Usuarios: 30,
    FlujosAutorización: 40,
    FAQ: 50,
    OrdenesCompra: 100,
    Programacionpagos: 200
}

export const estatusOrdenCompra = {
    PendienteConfirmar: 1,
    EnProcesoEntrega: 2,
    Entregada: 3,
    EntregaParcial: 4
}

export const estatusProcesoFacturacion = {
    PendienteCargaFactura: 11,
    ProgramacionPago: 21,
    PorAutorizar: 22,
    ProgramadoPago: 23,
    PendienteEjecuciónPago: 24,
    Pagado: 25
}

export const estatusFacturasComplemento = {
    PendienteCargaFactura: 11,
    ProgramacionPago: 21,
    PorAutorizar: 22,
    ProgramadoPago: 23,
    PendienteEjecuciónPago: 24,
    Pagado: 25,
    CargaComplemento: 26,
    ComplementoRetrasado: 27,
    RegistroFinalizado: 28
}

export const estatusProcesoLayoutRender = [
    31
]

export const estatusActivoLayoutRender = [
    32
]

export const estatusBloqueadoLayoutRender = [
    33
]

export const estatusInactivoLayoutRender = [
    34
]
export const seccionOrdenesCompra = {
    OrdenesCompra: 1,
    ProcesoFacturacion: 2,
    ProcesoPago: 3
}

export const estatusInfoLayoutRender = [
    4,
    14
]

export const estatusErrorLayoutRender = [
    27
]

export const estatusInfoLayoutExGrid = [
    11,
    12,
    14,
    13,
    21
]

export const estatusSuccessLayoutExGrid = [
    22
]

export const estatusEnOrdenOrdenCompra = {
    1: 0, //Por Confirmar
    2: 1, //En Proceso Entrega
    4: 2, //Entrega Parcial
    3: 4 // Entregada
}

export const estatusAdminEnOrdenProcesoFacturacion = {
    11: 0, //PendienteCargaFactura
    21: 1, //ProgramaciónPago
    22: 1, //PorAutorizar
    23: 2, //ProgramadoPago
    24: 3, //PendienteEjecuciónPago
    25: 5 //Pagado
}

export const procesoLog = {
    OrdenCompra: 31,
    Recepcion: 32,
    Factura: 33,
    Pagos: 34
}

export const defaultFilterOrdenesCompra = {
    fechaRegistro: [addDaysIsoString(-7), getTodayIsoString()]
}
export const estatusProveedor = {
    EnProceso: 31,
    Activo: 32,
    Bloqueado: 33,
    Inactivo:34
}

export const defaultFilterProcesoFacturacion = {
    fechaProgramadaPago: [getTodayIsoString(), addDaysIsoString(7)]
}

export const defaultFilterProcesoPagos = {
    fechaProgramadaPago: [getTodayIsoString(), addDaysIsoString(7)],
    fechaRealPago: [addDaysIsoString(-7), getTodayIsoString()]
}

export const checkboxState = {
    checked: 1,
    indeterminate: 2
}



