import {
    Divider,
    IconButton,
    List,
} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {useMenu} from "../MenuProvider";
import {Drawer, DrawerHeader} from "../Drawers";
import MainMenuItem from "./MainMenuItem";



const MainMenu = () => {
    const theme = useTheme();
    const {menuOpen, handleDrawerClose, path, menus} = useMenu()

    return (
        <Drawer variant="permanent" open={menuOpen}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRight/> : <ChevronLeft/>}
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List>
                {menus.map((menu) => (
                    <MainMenuItem key={menu.id} item={menu} level={0} path={path} />
                ))}
            </List>
            <Divider/>
        </Drawer>
    )
}
export default MainMenu;