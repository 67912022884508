/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import useNotification from "../../hooks/useNotification";
import useForm from "../../hooks/useForm";
import FormGrid from "../formGrid/FormGrid";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const editDialogValueContext = React.createContext({})

const FormEditDialog = ({
                            api,
                            id,
                            width,
                            onCancel,
                            onSaved,
                            initialValue,
                            title,
                            disabled,
                            onChanging,
                            onSaving,
                            onLoading,
                            children,
                            fields,
                            hideDelete
                        }) => {
    const {t} = useTranslation()

    const notification = useNotification()

    const open = id != null

    const {value, onChange, submitted, onValidate, clear, initialize, submitValidations} = useForm(initialValue)

    const {setEntity} = useDialogForm()

    const load = async (id) => {

        if (id === 0 || id == null) {
            clear()
            return
        }

        let entity = await api.getbyId(id)

        if (onLoading != null) {
            entity = onLoading(entity) ?? entity
        }

        initialize(entity)
        setEntity(entity)
    }

    useEffect(() => {
        load(id).then()
    }, [id])

    const handleChange = (entity)=>{
        if (onChanging != null)
            entity = onChanging()

        onChange(entity)
        setEntity(entity)
    }

    const onSave = async () => {
        if (!submitValidations()) {
            return
        }
        let entity = value;

        if (onSaving != null)
        {
            entity = onSaving(value)
            if(entity===undefined)
                return;
        }


        const result = await api.save(entity)

        onSaved(result)
    }

    const onDelete = async () => {
        if (await notification.confirm('notificaciones.confirmarEliminar')) {
            await api.delete(id)
            onSaved()
        }
    }

    const getTitle = () => {
        if (typeof title === 'function') {
            return title(value)
        }

        return title
    }

    return (
        <React.Fragment>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={width}
            >
                <DialogTitle sx={{m: 0, p: 2, pb: 1}} id="customized-dialog-title">
                    {getTitle()}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <FormGrid submitted={submitted}
                              onChange={handleChange}
                              onValidate={onValidate}
                              value={value}
                              fields={fields}
                              children={children}
                              disabled={disabled}
                    >
                    </FormGrid>
                </DialogContent>
                <DialogActions>
                    {!hideDelete &&
                    <Button autoFocus onClick={onDelete} color="error">
                        {t('Eliminar')}
                    </Button>
                    }
                    <Button autoFocus onClick={onSave}>
                        {t('Guardar')}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export const withEditDialogContext = (Component) => ({...props}) => {
    const [entity, setEntity] = useState()
    return (
        <editDialogValueContext.Provider value={{entity, setEntity}}>
            <Component {...props}/>
        </editDialogValueContext.Provider>
    )
}

export const useDialogForm = () => {
    return React.useContext(editDialogValueContext)
}

export default FormEditDialog;

FormEditDialog.propTypes = {
    api: PropTypes.object.isRequired,
    id: PropTypes.number,
    width: PropTypes.any,
    initialValue: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onChangeForm: PropTypes.func,
    disabled: PropTypes.bool,
    onLoad: PropTypes.func,
    onSaved: PropTypes.func.isRequired,
    saveText: PropTypes.string,
    showCancelButton: PropTypes.bool,
    title: PropTypes.string,
    beforeSave: PropTypes.func
}
