import React from 'react';
import PropTypes from 'prop-types';
import { AddCircle } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../common/security/AuthProvider';

const GridButtonsComponent = ({ editable, context }) => {
    const { t } = useTranslation();
    const { esProveedor } = useAuth();

    const handleButtonClick = () => {
        if (esProveedor) {
            context.onInviteUser();
        } else {
            context.onAdd();
        }
    };

    return (
        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
            {(editable || esProveedor) && (
                <IconButton
                    title={esProveedor ? t('Invitar Usuario') : t('Agregar')}
                    onClick={handleButtonClick}
                    variant={'grid'}
                    color={'secondary'}
                >
                    <AddCircle />
                </IconButton>
            )}
        </Box>
    );
};

GridButtonsComponent.propTypes = {
    context: PropTypes.any,
    column: PropTypes.any,
    editable: PropTypes.bool
};

export default GridButtonsComponent;
