import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
// se deben cargar los locales para los idiomas que se utilizarán (excepto inglés que es el default)
// https://github.com/adamwdraper/Numeral-js/tree/master/locales her we can check all default locales
import 'numeral/locales/es'
import 'numeral/locales/de'
import 'dayjs/locale/es'
import 'dayjs/locale/de'
import * as numeral from 'numeral'
import dayjs from "dayjs";
import i18n from 'i18next'
import usePersistedState from "../hooks/usePersistedState";

const localizationContext = React.createContext(undefined)

const countries = [
    {countryCode: 'mx', language: 'es', languageName: 'Español', dateLocale: 'es', numeralLocale: 'en' },
    {countryCode: 'de', language: 'de', languageName: 'Deutch' , dateLocale:'de' },
    {countryCode: 'us', language: 'en', languageName: 'Inglés'},
    {countryCode: 'es', language: 'es', languageName: 'Español', numeralLocale: 'es'}
]

const useLocalizationProvider = () => {
    // const {i18n} = useTranslation()
    const [country, setCountry] = usePersistedState( 'user-country',countries[0])

    const setLocales = React.useCallback((country) => {
        numeral.locale(country.numeralLocale ?? country.language)
        dayjs.locale(country.dateLocale ?? country.language)
        i18n.changeLanguage(`${country.language}`).then()
    }, [])

    useEffect(() => {
        setLocales(country)
    }, [setLocales,country]);

    const onChangeCountry = (country) => {
        setCountry(country)
        setLocales(country)
    }

    return {
        countries,
        onChangeCountry,
        country
    }
}

const LocalizationHandlerProvider = ({children}) => {
    const localization = useLocalizationProvider()
    return (
        <localizationContext.Provider value={localization}>
            {children}
        </localizationContext.Provider>
    )
}

LocalizationHandlerProvider.propTypes = {
    children: PropTypes.any
}

export default LocalizationHandlerProvider

export const useLocalization = () => {
    return React.useContext(localizationContext)
}
