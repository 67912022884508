import {
    Routes,
    Route
} from 'react-router-dom'
import FormDemo from "../../components/demos/FormDemo";
import GuardRoute from "../security/GuardRoute";
import Home from "../../components/home/Home";
import Usuarios from "../../components/configuracion/usuarios/Usuarios";
import GestionDocumentos from "../../components/gestionDocumentos/GestionDocumentos";
import OrdenCompraDetalle from "../../components/gestionDocumentos/ordenesCompra/OrdenCompraDetalle";
import Proveedor from "../../components/configuracion/proveedores/Proveedor";
import Perfiles from "../../components/configuracion/perfiles/Perfiles";
import ProgramacionPagos from "../../components/programacionPagos/ProgramacionPagos";
import {useApplication} from "../../components/home/ApplicationProvider";
import FlujoAutorizaciones from "../../components/configuracion/flujoAutorizacion/FlujoAutorizaciones";
import PreguntasFrecuentes from "../../components/catalogos/faq/PreguntasFrecuentes.";
import Comunicados from "../../components/catalogos/comunicados/Comunicados";

const AppRoutes = () => {

    const {actualizandoEmpresa} = useApplication()

    if (actualizandoEmpresa)
        return (<></>)

    return (
        <Routes>
            <Route exact={true} element={<GuardRoute/>}>
                <Route path="" element={<Home/>}/>
                <Route path="/home" exact={true} element={<Home/>}/>
                <Route path="/configuracion/usuarios" exact={true} element={<Usuarios/>}/>
                <Route path="/catalogos/faq" exact={true} element={<PreguntasFrecuentes/>}/>
                <Route path="/catalogos/comunicados" exact={true} element={<Comunicados/>}/>
                <Route path="/demo" exact={true} element={<FormDemo/>}/>
                <Route path="/otro" exact={true} element={<FormDemo/>}/>
                <Route path="/ordenesCompra" exact={true} element={<GestionDocumentos/>}/>
                <Route path="/ordenCompraDetalle" exact={true} element={<OrdenCompraDetalle/>}/>
                <Route path="/configuracion/proveedores" exact={true} element={<Proveedor/>}/>
                <Route path="/configuracion/perfiles" exact={true} element={<Perfiles/>}/>
                <Route path="/programacionPagos" exact={true} element={<ProgramacionPagos/>}/>
                <Route path="/configuracion/flujoAutorizacion" exact={true} element={<FlujoAutorizaciones/>}/>
            </Route>
        </Routes>
    )
}
export default AppRoutes
