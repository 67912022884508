import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, IconButton } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const ViewRowIconRenderer = ({ data, context, editable}) => {
    const { t } = useTranslation()

    return (
        <Box display={'flex'} justifyContent={'center'} width={'100%'} gap={'10px'}>
            <IconButton title={t('Ver')} onClick={() => context.onEdit(data.id)} variant={'grid'}>
                <MoreHorizIcon/>
            </IconButton>
        </Box>
    )
}

ViewRowIconRenderer.propTypes = {
    data: PropTypes.any,
    context: PropTypes.any
}
