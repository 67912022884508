import PropTypes from 'prop-types'
import {TextField} from '@mui/material'
import useInputValidation from "../validations/useInputValidation";

const FieldTextBox = ({
    value,
    onChange,
    field,
    label,
    disabled,
    maxLength,
    width,
    required,
    validators,
    onValidate,
    submitted,
    selectTextOnFocus,
    type,
    inputProps
}) => {
    disabled = disabled ?? false

    const {hasValidationError, errorMessage} = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate
    })

    function onValueChange (event) {
        if (value?.toString() === event.target.value || disabled === true) {
            return
        }

        const newValue = event.target.value
        onChange(newValue, field)
    }

    return (
        <>
            <TextField
                variant={inputProps?.variant ??"outlined"}
                autoComplete={'off'}
                label={label}
                value={value ?? ''}
                disabled={disabled}
                style={{ width: width ?? '100%' }}
                onChange={onValueChange}
                error={submitted && hasValidationError}
                helperText={submitted ? errorMessage() : ''}
                name={field ?? 'textField'}
                onFocus={event => {
                    if (selectTextOnFocus ===
                               true) { event.target.select() }
                }}

                inputProps={{
                    maxLength: maxLength ?? 50,
                    ...inputProps
                }}
                // InputLabelProps={{
                //     shrink: true,
                // }}
                size={'small'}
                type={type}
            />
        </>
    )
}

FieldTextBox.propTypes = {
    InputProps: PropTypes.any,
    disabled: PropTypes.bool,
    field: PropTypes.string,
    inputProps: PropTypes.any,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    onValidate: PropTypes.any,
    required: PropTypes.any,
    selectTextOnFocus: PropTypes.bool,
    submitted: PropTypes.any,
    validators: PropTypes.any,
    value: PropTypes.string,
    width: PropTypes.string
}

export default FieldTextBox
