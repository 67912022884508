import {useCallback, useState} from "react";
import {v4 as uuidv4} from "uuid";

const useExGrid = ({field, value, onNewRow, onChange, getEntityId, onValidate}) => {

    const [validations, setValidations] = useState(new Set())

    const getTemplateColumns = useCallback((columns) => {
        const template = columns.map(e => e.width ?? '1fr').join(' ')
        return template
    }, [])

    const onAdd = useCallback(() => {
        //si trae función para crear un nuevo registro lo manda a llamar si no, crea un nuevo registro vacío
        const newRow = onNewRow == null
            ? {_uniqueUid: uuidv4()}
            : {...onNewRow(), _uniqueUid: uuidv4()}

        onChange([...value, newRow], field)
    }, [onChange, value, onNewRow, field])

    const handleRowChanged = useCallback(async (row, index) => {
        const newData = value.map((e, rowIndex) => {
            return rowIndex === index ? row : e
        })
        if (onChange != null)
            onChange(newData, field)
    }, [field, onChange, value])

    /**
     * Obtiene un rowid único del row recibido primero intentará por su id y si no por su _uniqueUid
     * @param {object} row - el row del que obtendremos el id.
     * @param {function} getEntityId - La función(row) personalizada para obtener el id del row
     */
    const getRowId = useCallback((row) => {
        if (getEntityId == null) {
            return row.id ?? row._uniqueUid ?? uuidv4()
        }
        return getEntityId(row)
    }, [getEntityId])

    /**
     * Elimina un row del grid
     */
    const handleDelete = useCallback((row) => {
        const deletedId = getRowId(row)
        const newData = value.filter((e) => getRowId(e) !== deletedId)
        validations.delete(deletedId)
        setValidations(new Set(validations))
        onChange(newData, field)
        if (onValidate != null)
            onValidate(validations.size > 0, field)

    }, [field, getRowId, onChange, onValidate, validations, value])

    /**
     * Recibe la validación de un row, si alguno delos campos del row es inválido el row es marcado como inválido
     */
    const handleValidateRow = useCallback((error, rowIndex) => {
        const rowId = getRowId(value[rowIndex])
        const newErrors = validations
        if (error) {
            newErrors.add(rowId)
        } else if (newErrors.has(rowId)) {
            newErrors.delete(rowId)
        }
        setValidations(newErrors)
        if (onValidate != null)
            onValidate(newErrors.size > 0, field)
    }, [field, getRowId, onValidate, validations, value])

    return {
        handleValidateRow,
        handleDelete,
        getRowId,
        handleRowChanged,
        onAdd,
        getTemplateColumns
    }
}

export default useExGrid