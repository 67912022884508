import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import ExColumn from "./ExColumn";
import ExRow from "./ExRow";
import useExGrid from "./useExGrid";
import {getChildrenAsArray} from "../../utility/formUtility";
import PropTypes from "prop-types";
import FieldTextBox from "../FieldTextBox";

const ExGrid = ({
                    value,
                    parentValue,
                    onChange,
                    onChangeCell,
                    field,
                    onValidate,
                    disabled,
                    submitted,
                    visibleHeader,
                    columns,
                    onNewRow,
                    getEntityId,
                    children
                }) => {

    visibleHeader = visibleHeader ?? true
    value = value ?? []

    const [gridColumns, setGridColumns] = useState([])

        useEffect(()=>{
        if (columns == null && children == null)
            return

        const gridColumns = columns?.length > 0 ? columns : getChildrenAsArray(children).map(e=> {return{...e.props}} )

        setGridColumns(gridColumns)
    },[children, columns])

    const {getRowId,getTemplateColumns,onAdd,handleDelete,handleRowChanged,handleValidateRow} =
        useExGrid({field, value, columns, onNewRow, onChange, getEntityId, onValidate})

    return (
        <Box display={"grid"} gridTemplateColumns={getTemplateColumns(gridColumns)} overflow={'auto'} columnGap={'10px'}>
            {/* HEADER */}
            {visibleHeader && gridColumns.map((col) =>
                <ExColumn
                    key={col.field}
                    value={value}
                    submitted={submitted}
                    disabled={disabled}
                    onAdd={onAdd}
                    {...col}
                />
            )
            }
            {/*ROWS*/}
            {value.map((row, rowIndex) => (
                <ExRow key={getRowId(row)}
                       columns={gridColumns}
                       rowValue={row}
                       disabled={disabled}
                       onRowChanged={handleRowChanged}
                       onDelete={handleDelete}
                       onValidateRow={handleValidateRow}
                       submitted={submitted}
                       parentValue={parentValue}
                       rowIndex={rowIndex}
                       onChangeCell={onChangeCell}
                />
            ))}
        </Box>
    )
}

FieldTextBox.propTypes = {
    value: PropTypes.object,
    parentValue: PropTypes.object,
    onChange: PropTypes.func,
    field: PropTypes.string.isRequired,
    onValidate: PropTypes.func,
    disabled: PropTypes.any,
    submitted: PropTypes.any,
    visibleHeader: PropTypes.bool,
    columns: PropTypes.array,
    onNewRow: PropTypes.func,
    getEntityId: PropTypes.func
}

export default ExGrid;