import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useApi from '../../../api/useApi';
import useNotification from '../../../common/components/hooks/useNotification';
import { emailValidator } from '../../../common/components/forms/validations/validators';
import { useAuth } from '../../../common/security/AuthProvider';

const ProveedorInvitarUsuario = ({ open, onClose, onInvite }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const api = useApi('catalogos/proveedores');
    const notification = useNotification();
    const { user } = useAuth();
   
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const handleInvite = async () => {
        const validationResult = emailValidator.validate(email);
        if (validationResult.error) {
            setEmailError(t(validationResult.message));
            return;
        }

        const tokenPayload = JSON.parse(atob(user.token.split('.')[1]));
        const proveedorId = tokenPayload.proveedorId;
        const empresaId = user.empresaId;

        const payload = { 
            email,
            proveedorId: parseInt(proveedorId, 10),
            empresaId
        };

        try {
            await api.post('invitarUsuario', payload);
            onInvite(email);
        } catch (error) {
            console.error('Error details:', error.response?.data);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                notification.error(t(error.response.data.errorMessage));
            } else {
                notification.error(t('Error al enviar la invitación'));
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('Invitar Usuario')}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label={t('Email')}
                    type="email"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    error={!!emailError}
                    helperText={emailError}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('Cancelar')}
                </Button>
                <Button onClick={handleInvite} color="primary">
                    {t('Invitar')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProveedorInvitarUsuario;