import {useTranslation} from "react-i18next";
import {Box, IconButton} from "@mui/material";
import {AddCircle} from "@mui/icons-material";

const GridAddButtonComponent = ({editable, context }) => {
    const { t } = useTranslation()

    return (
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
            <IconButton title={t('Agregar')} onClick={() => context.onAdd()} variant={'grid'} color={'success'}>
                <AddCircle/>
            </IconButton>
        </Box>
    )
}

export default GridAddButtonComponent;