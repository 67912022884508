import React from 'react';
import FormEditDialog, { withEditDialogContext } from "../../../common/components/forms/formEditDialog/FormEditDialog";
import Field from "../../../common/components/forms/Field";
import FieldTextBox from "../../../common/components/forms/fields/FieldTextBox";
import FieldDropDown from "../../../common/components/forms/fields/FieldDropDown";
import { emailValidator } from "../../../common/components/forms/validations/validators";
import useApi from "../../../api/useApi";
import FieldCheckBox from "../../../common/components/forms/fields/FieldCheckBox";
import FieldMultiSelect from "../../../common/components/forms/fields/multiselect/FieldMultiSelect";

const UsuarioDetalle = ({ editForm, disabled, open, onClose, esProveedor }) => {
    const id = editForm.id;
    const api = useApi('configuracion/usuarios');

    return (
        <FormEditDialog 
            api={api} 
            title={id === 0 ? 'Agregar' : 'Editar'} 
            {...editForm} 
            disabled={disabled || esProveedor} 
            hideDelete={id === 0}
            open={open}
            onClose={onClose}
        >
            <Field field="userName" label={'Usuario'} render={FieldTextBox} required={true} maxLength={50} disabled={id > 0 || esProveedor} />
            <Field field="email" label={'Email'} render={FieldTextBox} maxLength={255} required={true} componentProps={{ validators: [emailValidator] }} disabled={esProveedor} />
            <Field field="name" label={'Nombre'} render={FieldTextBox} required={true} maxLength={255} disabled={esProveedor} />
            <Field field="lastName" label={'Apellido'} render={FieldTextBox} required={true} maxLength={255} disabled={esProveedor} />
            <Field field="profileId" label={'Perfil'} source={'profiles'} render={FieldDropDown} required={true} disabled={esProveedor} />
            <Field field="empresasIds" label={'Empresa'} source={'empresasAll'} render={FieldMultiSelect} required={true} disabled={esProveedor} />
            {!esProveedor && id === 0 && (
                <Field field="password" label={'Password'} render={FieldTextBox} required={true} maxLength={255} />
            )}
            <Field field="enabled" label={'Activo'} render={FieldCheckBox} disabled={esProveedor} />
        </FormEditDialog>
    );
};

export default withEditDialogContext(UsuarioDetalle);
