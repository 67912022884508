import {Box, Breadcrumbs, IconButton, Toolbar, Typography} from "@mui/material";
import {Logout, Menu} from "@mui/icons-material";
import {useMenu} from "../MenuProvider";
import {AppBar} from "./AppBar";
import Empresas from "./Empresas";
import {useAuth} from "../../security/AuthProvider";

import UserNotifications from "../../components/Shared/UserNotification";
import LogoComponent from "../../components/Shared/LogoComponent";


import {createSignalRContext} from "react-signalr/signalr";
import {HttpTransportType} from "@microsoft/signalr";
const hubUrl = process.env.REACT_APP_HUB_URI;
export const SignalRContext = createSignalRContext();
const Header = () => {
    const {menuOpen, handleDrawerOpen, breadCrumbs} = useMenu()
    const {logout, user} = useAuth()
    const subscribeSingalr = () => {
        SignalRContext.invoke("Join", user.userName).then(e => console.log(e)).catch(e => console.log(e))
    }

    const onOpen = ()=>{
        console.log('open')
        subscribeSingalr()
    }

    const onReconnect = ()=>{
        console.log('open')
        subscribeSingalr()
    }

    const onClosed = ()=>{
        console.log('closed')
    }

    const onError = ()=>{
        console.log('error')
    }

    return (
        <AppBar position="fixed" open={menuOpen}>
            <Toolbar sx={{gap:'10px'}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(menuOpen && {display: 'none'}),
                    }}
                >
                    <Menu/>
                </IconButton>
                <LogoComponent value={"/LogoDX2023.png"} tieneUrl={true} valueWidth={30} valueHeight={30}></LogoComponent>
                <Breadcrumbs aria-label="breadcrumb">
                    {breadCrumbs && breadCrumbs.map((label, index) =>
                        <Typography color={"primary.contrastText"} key={index} >
                            {label}
                        </Typography>
                    )}
                </Breadcrumbs>

                <Box display={'flex'} flexGrow={1}/>
                <Empresas/>

                <label>
                    {user.fullName}
                </label>
                <SignalRContext.Provider
                    url={hubUrl}
                    onOpen={onOpen}
                    onReconnect={onReconnect}
                    onClosed={onClosed}
                    onError={onError}
                    automaticReconnect={true}
                    skipNegotiation={true}
                    transport={HttpTransportType.WebSockets}
                >
                    <UserNotifications user={user}/>
                </SignalRContext.Provider>

                <IconButton sx={{color:'white'}} onClick={logout}>
                    <Logout/>
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}
export default Header;
