import PropTypes from "prop-types";
const LogoComponent = ({
                                value, tieneUrl,valueWidth,valueHeight
                            }) => {
    tieneUrl = false;
    if (value){
        tieneUrl = value.trim() ?? true;
    }


    return(
        <>
            {tieneUrl &&
                <img src={value} width={valueWidth} height={valueHeight} alt={'logo empresa'} />
            }
        </>
    )
}

LogoComponent.propTypes = {
    value: PropTypes.string,
    tieneUrl: PropTypes.bool,
    valueWidth:PropTypes.number,
    valueHeight:PropTypes.number
}

export default LogoComponent;