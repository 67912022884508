import Header from "./header/Header";
import MainMenu from "./menus/MainMenu";
import {Box, CssBaseline} from "@mui/material";
import {DrawerHeader} from "./Drawers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {useLocalization} from "../internalization/LocalizationHandlerProvider";
import {MenuProvider} from "./MenuProvider";
import {useAuth} from "../security/AuthProvider";
import AppRoutes from "./AppRoutes";
import UnauthenticatedRoutes from "./UnauthenticatedRoutes";
import ApplicationProvider  from "../../components/home/ApplicationProvider";

const Main = () => {

    const {isAuthenticated} = useAuth()
    const {country} = useLocalization()
    const {refreshToken} = useAuth();

    return (
        <>
            {
                isAuthenticated() ?
                    <ApplicationProvider>
                        <MenuProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}
                                                  adapterLocale={country.dateLocale ?? country.language}
                            >
                                <Box sx={{display: 'flex', height: '100vh'}} onMouseMove={refreshToken}
                                     onMouseDown={refreshToken} onKeyDown={refreshToken}>
                                    <CssBaseline/>
                                    <Header/>
                                    <MainMenu/>
                                    <Box component="main" bgcolor={'#F8F9FE'}
                                         sx={{
                                             flexGrow: 1,
                                             p: 2,
                                             overflow: 'auto',
                                             display: 'flex',
                                             flexDirection: 'column'
                                         }}>
                                        <DrawerHeader/>
                                        <AppRoutes/>

                                    </Box>
                                </Box>
                            </LocalizationProvider>
                        </MenuProvider>
                    </ApplicationProvider>
                    :
                    <UnauthenticatedRoutes/>
            }
        </>
    )
}
export default Main;