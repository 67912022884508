import {Box} from "@mui/material";
import PropTypes from "prop-types";
import useFormValidation from "../../../hooks/useFormValidation";
import useChangeFieldHandler from "../../../hooks/useChangeFieldHandler";
import * as React from "react";
import {useCallback} from "react";
import FormFields from "../../FormFields";

const FormTabPanel = ({
                          field,
                          fields,
                          value,
                          parentValue,
                          onChange,
                          onValidate,
                          submitted,
                          tabIndex,
                          index,
                          children
                      }) => {


    const {onValidateField} = useFormValidation(onValidate, field)

    const {onChangeField} = useChangeFieldHandler(value, onChange, field)

    //todo temporal por debug, quitarlo una vez que esté mas estable
    const handleChange = useCallback((value, field) => {
        onChangeField(value, field)
    }, [onChangeField])


    return (
        <div
            role="tabpanel"
            hidden={tabIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            <Box sx={{p: 1}} display={tabIndex === index ? '' : 'none'}>
                <FormFields
                    value={value}
                    onChange={handleChange}
                    onValidate={onValidateField}
                    submitted={submitted}
                    parentValue={parentValue}
                    fields={fields}
                    children={children}
                />
            </Box>
        </div>
    );
}

FormTabPanel.propTypes = {
    index: PropTypes.number,
    tabIndex: PropTypes.number,
};

export default FormTabPanel;
