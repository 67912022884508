import {useCallback} from "react";
import useChangeFieldHandler from "./useChangeFieldHandler";

const useFieldsContainer = (parentValue,onChange,onValidate) => {

    const {onChangeField} = useChangeFieldHandler(parentValue,onChange)

    const propagateValidation = useCallback ((hasError,fieldName) => {
        onValidate(hasError, fieldName)
    },[onValidate])

    return {
        onChangeField,
        propagateValidation
    }
}

export default useFieldsContainer