import useFormFields from "../../hooks/useFormFields";
import useFormValidation from "../../hooks/useFormValidation";
import Field from "../Field";
import {FormContext} from '../FormContext';
import Grid2 from "@mui/material/Unstable_Grid2";
import useChangeFieldHandler from "../../hooks/useChangeFieldHandler";

const FormGrid = ({value, onChange, submitted, onValidate, fields, children, spacing, disabled, onSubmit, ...props}) => {
    const {formFields} = useFormFields(children, fields);
    const {onValidateField} = useFormValidation(onValidate, null)

    const {onChangeField} = useChangeFieldHandler(value, onChange)

    const onKeyDown = (e)=>{
        if (e.code === 'Enter')
            onSubmit()
    }


    return (

        <FormContext.Provider
            value={{
                value,
                onChange:onChangeField,
                onValidate:onValidateField,
                submitted,
                parentValue: value
            }}
        >

            <Grid2 container spacing={spacing ?? 2} {...props} onKeyDown={onKeyDown}>
                {formFields && formFields.map((item, index) =>
                    <Grid2 key={item.field ?? index}
                           xs={item.xs ?? 12}
                           sm={item.sm}
                           md={item.md}
                           lg={item.lg}
                           xl={item.xl}
                    >
                        {item.render != null ?
                            <Field
                                key={item.field}
                                render={item.render}
                                disabled={disabled}
                                {...item}
                            /> :
                            item}
                    </Grid2>
                )}
            </Grid2>
        </FormContext.Provider>
    )
}
export default FormGrid;