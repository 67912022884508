import PropTypes from 'prop-types'
import { CheckCircleOutlined} from '@mui/icons-material'
import {Box, IconButton} from '@mui/material'
import { useTranslation } from 'react-i18next'
import useApi from "../../../api/useApi";
import {estatusOrdenCompra} from "../../../components/Constantes";
import {useAuth} from "../../security/AuthProvider";
import useNotification from "../hooks/useNotification";

const CheckGridButtonComponent = ({ data, context}) => {
    const { t } = useTranslation();
    const api = useApi('configuracion/ordenesCompra');
    const {esProveedor} = useAuth();
    const notification = useNotification()

    const confirm = async () => {

        if (data.id === 0 || data.id == null) {
            return
        }

        if(await notification.confirm(t('Confirmar la Orden de Compra'))){
            await api.put('confirmar', data.id);
            await context.refresh()
        }

    }

    return (
        <Box display={'flex'} justifyContent={'center'} width={'100%'} gap={'10px'}>
            {esProveedor === true && data.estatusId === estatusOrdenCompra.PendienteConfirmar &&
            <IconButton title={t('Confirmar')} onClick={() => confirm()} variant={'grid'}>
                <CheckCircleOutlined color={'success'}/>
            </IconButton>}
        </Box>
    )
}

CheckGridButtonComponent.propTypes = {
    context: PropTypes.any,
    column: PropTypes.any
}

export default CheckGridButtonComponent