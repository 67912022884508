import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip} from '@mui/material'
import Icon from '@mui/material/Icon'
import {ExpandLess, ExpandMore} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'
import {useMenu} from "../MenuProvider";
import {useState} from "react";
import SubmenuPopup from "./SubmenuPopup";

//inta un menú dado un item recibido, un menu debe tener la forma {id:1,name:'nombre',url:'ruta',icon:'nombre_icono', childre:[submenus]}
//el icon debe ser texto con formato snake, ejemplos tools,  ac_unit
const MainMenuItem = ({item, level, path}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {menuOpen} = useMenu()
    const children = item.children ?? []
    const [expanded, setExpanded] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const selected = (path??'').startsWith(item.url)

    const onClick = (e) => {
        if (children.length > 0) {
            if (menuOpen){
                setExpanded(!expanded)
            }
            else {
                setAnchorEl(e.currentTarget)
            }

        } else {
            navigate(item.url)
        }
    }

    return (
        <>
            <ListItem disablePadding sx={{display: 'block'}} >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: menuOpen ? 'initial' : 'center',
                        px: 2.5 + level,
                    }}
                    selected={selected}
                    onClick={onClick}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: menuOpen ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <Tooltip title={menuOpen ? '' : t(item.name)} followCursor>
                            <Icon>{item.icon}</Icon>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={level === 0 ? item.name:''} sx={{opacity: menuOpen ? 1 : 0}} secondary={level > 0 ? item.name : ''}/>
                    {children.length > 0 && menuOpen &&
                        <>
                            {expanded ? <ExpandLess/> : <ExpandMore/>}
                        </>
                    }
                </ListItemButton>
            </ListItem>
            { menuOpen && expanded && children.map((item, index) =>
                    <MainMenuItem item={item} index={index} level={level + 1} handleClick={()=>{}} path={path}/>
                )}

            <SubmenuPopup
                open={open}
                anchorEl={anchorEl}
                menuItem={item}
                handleClose={()=>setAnchorEl(null)}
            >
            </SubmenuPopup>
        </>
    )
}

MainMenuItem.propTypes = {
    level: PropTypes.number.isRequired,
    item: PropTypes.shape({
        children: PropTypes.array,
        expanded: PropTypes.any,
        icon: PropTypes.any,
        name: PropTypes.any,
        selected: PropTypes.any,
        url: PropTypes.any
    }).isRequired,
    path: PropTypes.string.isRequired
}

export default MainMenuItem
