/* eslint-disable react-hooks/exhaustive-deps */
import {useTranslation} from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Box, Button, IconButton, Step, StepLabel, Stepper} from "@mui/material";
import {Close, HistoryOutlined} from "@mui/icons-material";
import ExGrid from "../../../common/components/forms/fields/grid/ExGrid";
import {useEffect, useState} from "react";
import ExColumn from "../../../common/components/forms/fields/grid/ExColumn";
import {currencyFormatter, dateFormatter} from "../../../common/formatters/useLocaleFormatters";
import {rightAligned} from "../../../common/components/agGrid/agGridUtility";
import {currencyFormat, dateFormat} from "../../../common/formatters/format";
import Generales from "../../../common/components/Shared/Generales";
import DialogContentComponent from "../../../common/components/Shared/DialogContentComponent";
import useApi from "../../../api/useApi";
import DownloadOrUploadButtonIcon from "../../../common/components/Shared/DownloadOrUploadButtonIcon";
import useApiSource from "../../../api/useApiSource";
import DialogActions from "@mui/material/DialogActions";
import LogOrdenCompra from "./LogOrdenCompra";
import EstatusOrdenCompraDetalleLayout from "./EsatusLayoutExGrid";
import {estatusEnOrdenOrdenCompra, procesoLog} from "../../Constantes";


const OrdenCompraDetalle = ({editForm, disabled}) => {
    const {t} = useTranslation();
    const open = editForm.id != null;
    const api = useApi('configuracion/ordenesCompra');
    const apiRecepciones = useApi('configuracion/recepciones');
    const [data, setData] = useState([]);
    const apiSource = useApiSource();
    const [logDialogOpen, setLogDialogOpen] = useState(false);

    useEffect(() => {
        if(open){
        load(editForm.id).then()}
        // eslint-disable-next-line
    }, [editForm.id, open]);

    const load = async (id) => {

        if (id === 0 || id == null) {
            return
        }

        const response = await api.get(id)
        response["estados"] = await apiSource.get('estatusOrdenCompra');
        setData(response);
    }

    const closeLogModal = () => {
        setLogDialogOpen(false);
    }

    const onChangeCell = async (value, field, row, rowIndex)=>{
        const formData = new FormData();
        formData.append('factura', value);
        await apiRecepciones.post(`${row.recepcionId}/factura`, formData);
        load(editForm.id).then()
    }

    return (
        <>
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'xl'}>
            <DialogTitle marginInline={4} marginTop={2} fontWeight={'bold'}>
                {t(`OC `)}{data?.generales?.folioOrdenCompra}
                <IconButton sx={{position: 'absolute', right: 8, top: 8}} onClick={editForm.onCancel}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{marginInline: 4}}>
                <Box sx={{width: "100%"}} marginBottom={3}>
                    <Stepper activeStep={data?.estatusId >= 0 ? estatusEnOrdenOrdenCompra[data?.estatusId] : data?.estatusId} alternativeLabel>
                        {data?.estados?.sort((a, b) => a.orden - b.orden).map((label) => (
                            <Step key={label.name}>
                                <StepLabel>{label.name}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <DialogContentComponent>
                    <Box className={'componentHeader'}>
                        {t('Generales')}
                    </Box>
                    <Generales sx={{gridTemplateColumns: "1fr 2fr 180px 1fr 50px 100px"}}>
                        <label className={'title'}>{t('Proveedor')}</label>
                        <label className={'label'}>{data?.generales?.razonSocial}</label>
                        <label className={'title'}>{t('Fecha Solicitado')}</label>
                        <label className={'label'}>{dateFormat(data?.generales?.fechaSolicitado)}</label>
                        <label className={'title'}>{t('OC')}</label>
                        <Box>
                            <DownloadOrUploadButtonIcon value={''} soloDescarga={true}/>
                        </Box>
                        <label className={'title'}>{t('Días de Credito')}</label>
                        <label className={'label'}>{data?.generales?.diasCredito}</label>
                        <label className={'title'}>{t('Fecha Recepción')}</label>
                        <label className={'label'}>{dateFormat(data?.generales?.fechaRecepcion)}</label>
                    </Generales>
                </DialogContentComponent>

                <DialogContentComponent>
                    <Box className={'componentHeader'}>
                        {t('Desglose Orden')}
                    </Box>
                    <Box borderBottom={2} borderColor={"darkgrey"}>
                        <ExGrid value={data?.desglose}>
                            <ExColumn field={'codigoArticulo'} title={t('Código Articulo')}/>
                            <ExColumn field={'descripcion'} title={t('Descripcion del Articulo')} width={'350px'}/>
                            <ExColumn field={'fechaEntrega'} title={t('Fecha Entrega')} valueFormatter={dateFormatter}/>
                            <ExColumn field={'cantidad'} title={t('Cant.')}/>
                            <ExColumn field={'unidadMedida'} title={t('Unidad')}/>
                            <ExColumn field={'precioUnitario'} title={t('Precio')} valueFormatter={currencyFormatter} {...rightAligned}/>
                            <ExColumn field={'total'} title={t('Total')} valueFormatter={currencyFormatter} {...rightAligned}/>
                        </ExGrid>
                    </Box>
                    <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%"}}>
                        <Box color={'darkgray'} sx={{gridRow: "1", gridColumn: "4/5"}}>{t('Subtotal')}</Box>
                        <Box textAlign={'right'} color={'primary.main'} fontWeight={'bold'}
                             sx={{gridRow: "1", gridColumn: "5/6"}}>{currencyFormat(data?.generales?.sumaSubtotal)}</Box>
                        <Box color={'darkgray'} sx={{gridRow: "2", gridColumn: "4/5"}}>{t('IVA')}</Box>
                        <Box textAlign={'right'} color={'primary.main'} fontWeight={'bold'}
                             sx={{gridRow: "2", gridColumn: "5/6"}}>{currencyFormat(data?.generales?.sumaIva)}</Box>
                        <Box color={'darkgray'} fontWeight={'bold'}
                             sx={{gridRow: "3", gridColumn: "4/5"}}>{t('TOTAL')}</Box>
                        <Box textAlign={'right'} color={'primary.main'} fontWeight={'bold'}
                             sx={{gridRow: "3", gridColumn: "5/6"}}>{currencyFormat(data?.generales?.sumaTotal)}</Box>
                    </Box>
                </DialogContentComponent>

                <DialogContentComponent>
                    <Box className={'componentHeader'}>
                        {t('Facturación')}
                    </Box>
                    <Box marginBottom={3}>
                        <ExGrid value={data?.facturas} onChangeCell={onChangeCell}>
                            <ExColumn field={'folioFactura'} title={t('Folio Factura')} width={'120px'}/>
                            <ExColumn field={'rutaXml'} title={t('Factura')} width={'50px'} render ={DownloadOrUploadButtonIcon} cellParams={{soloDescarga: false, valuePdf: data?.facturas?.[0]?.rutaPdf}} value={'rutaXml'}/>
                            <ExColumn field={'monto'} title={t('Monto')} valueFormatter={currencyFormatter} {...rightAligned} width={'150px'}/>
                            <ExColumn field={'fechaProgramadaPago'} title={t('Fecha Prog. Pago')} valueFormatter={dateFormatter}/>
                            <ExColumn field={'referenciaPago'} title={t('Comprobante Pago')} />
                            <ExColumn field={'rutaComplementoPago'} title={t('Complemento Pago')} render ={DownloadOrUploadButtonIcon} cellParams={{soloDescarga: true}} value={'rutaComplementoPago'}/>
                            <ExColumn field={'estatusId'} title={t('Estatus')} render={EstatusOrdenCompraDetalleLayout} cellParams={{enumerador: 'estatusFacturasComplemento'}}/>
                        </ExGrid>
                    </Box>

                </DialogContentComponent>
                <DialogActions>
                    <Button sx={{color: 'text.primary'}} onClick={() => setLogDialogOpen(true)}>
                        <HistoryOutlined /> <label style={{fontWeight:'bold'}}> LOG </label>
                    </Button>
                    <Box sx={{display: "grid", gridAutoColumns: "1fr", width: "100%"}}>
                        <Box color={'black'} fontWeight={'bold'} sx={{gridRow: "1", gridColumn: "8/9"}}>{t('Saldo')}</Box>
                        <Box textAlign={'right'} color={'black'} fontWeight={'bold'}
                             sx={{gridRow: "1", gridColumn: "9/10"}}>{currencyFormat(data?.generales?.saldo)}</Box>
                    </Box>
                </DialogActions>
            </DialogContent>
        </Dialog>
        <LogOrdenCompra open={logDialogOpen} id={editForm.id} procesoId={procesoLog.OrdenCompra} onClose={() => closeLogModal()}/>
        </>
    )
}

export default OrdenCompraDetalle;
