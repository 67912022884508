import {
    estatusProcesoLayoutRender, estatusActivoLayoutRender, estatusBloqueadoLayoutRender, estatusInactivoLayoutRender
} from "../../Constantes";
import {enumFormatter} from "../../../common/formatters/useLocaleFormatters";
import PropTypes from "prop-types";
import EstatusContenedor from "../../../common/components/Shared/EstatusContenedor";

const EstatusProveedorLayoutRender = ({ data, enumerador, tipo}) => {

    if(estatusProcesoLayoutRender.includes(data.estatus))
    {
        tipo = 'info'
    }
    else if(estatusActivoLayoutRender.includes(data.estatus)){
        tipo = 'success'
    }
    else if(estatusInactivoLayoutRender.includes(data.estatus)){
        tipo = 'warning'
    }
    else if (estatusBloqueadoLayoutRender.includes(data.estatus)){
        tipo = 'alert'
    }
    return (
        <EstatusContenedor>
            <label className={tipo}>
                {enumFormatter({'value':data.estatus, 'colDef':{'enum':enumerador}})}
            </label>
        </EstatusContenedor>
    )
}

EstatusProveedorLayoutRender.propTypes = {
    enumerador : PropTypes.any,
    tipo : PropTypes.string
}

export default EstatusProveedorLayoutRender